import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import apiUtil from '../../api/apiUtil.jsx';
import api from "../../api/api.jsx";

import { 
  Button,
  Menu,
  MenuItem,
  Box,
  Divider
} from '@material-ui/core';

import CustomDialog from '../../components/Dialog/CustomDialog.jsx';

import { ReactComponent as ArrowDownIcon } from '../../assets/img/left_menu/arrow-down.svg';
import defaultImg from '../../assets/img/default_image.jpg';
import {RoleAccessService} from '../../data/role-access'; 

class ProfileDropdown extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);

    this.state = {
      profileMenu: null,
      openDialog: false,
      isLoading: false,
    };
  }


  componentWillReceiveProps(nextProps) {
    if (nextProps.profileMenu !== this.state.profileMenu) {
        this.setState({
            profileMenu: nextProps.profileMenu
        });
    }
  }


  getUserinfo = () => {
    // const { user } = this.context;
    // let userInfo = apiUtil.getObject(user);
    // return (userInfo && userInfo.owner_info) ? userInfo.owner_info : null;
  }

  getUserLogo = () => {
    const { user } = this.context;
    return apiUtil.getUserLogo(user, defaultImg);
  }
  getUserName = () => {
    const { user } = this.context;
    return apiUtil.getUserName(user, defaultImg);
  }
  getUserEmail = () => {
    let userInfo = this.getUserinfo();

    return (userInfo && userInfo.email) ? userInfo.email : '';
  }


  /* API */
  callUpdateAdminProfileApi = (callback = null) => {
    const { user, accessToken, setOnBoardingCustomer, setOnBoardingVehicle, setOnBoardingDriver, setOnBoardingJob } = this.context;
    
    this.setState({
      isLoading: true
    }, () => {
      let firstName = apiUtil.getFirstName(user);
      let lastName = apiUtil.getLastName(user);
  
      let params = {
        "first_name": firstName,
			  "last_name": lastName,
			  "is_onboarding": true
      };

      api.updateAdminProfile('profile/admins', params, accessToken).then((result) => {
        apiUtil.parseResult(result, (data) => {
          this.setState({
            isLoading: false,
          }, () => {
            setOnBoardingCustomer(null);
            setOnBoardingVehicle(null);
            setOnBoardingDriver(null);
            setOnBoardingJob(null);

            if(callback){
              callback(data.result);
            }
          });
        }, (error, type) => {
          this.setState({
            isLoading: false,
          }, () => {
            if(callback){
              callback(null);
            }
          });
        });
      });
    });
  }
  /* END API */


  /* DIALOG */
  customDialog = () => {
    return <CustomDialog 
      open={this.state.openDialog}
      title={'Onboarding'}
      btnCloseText={'No'}
      onClose={() => {
        this.setState({
          openDialog: false,
        });
      }}
      onOk={() => {
        this.callUpdateAdminProfileApi((data) => {
          this.setState({
            openDialog: false,
          }, () => {
            apiUtil.toastOnBoarding('Done', 'check_circle');
            this.props.history.push('/');
          });
        });
      }}
    >
      <Box>Do you want to re-run onboarding process?</Box>
    </CustomDialog>
  }
  /* END DIALOG */


  render() {
    // const { isCustomerRequired, currentRole } = this.context;
    // let grantedMenu = new RoleAccessService();
    return <Box textAlign="right" height="100%" ml={3} className="header-profile">
        <Button 
            aria-label="profile-menu" 
            aria-haspopup="true"
            onClick={(event) => {
                this.setState({
                    profileMenu: event.currentTarget
                });
            }}
        >
            <img className="profile-pic-logo" src={this.getUserLogo()} alt="User" width="35" height="35" />
            <span>{this.getUserName()}</span>
            <ArrowDownIcon />
        </Button>
        <Menu
            id="profile-menu"
            anchorEl={this.state.profileMenu}
            keepMounted
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
            open={Boolean(this.state.profileMenu)}
            onClose={() => {
                this.setState({
                    profileMenu: null
                });
            }}
        >   
            <div className="user-info">
              <img src={this.getUserLogo()} alt="User" 
                className="profile-pic-logo"
                width="35" height="35"/>
              <div className="user-info-right">
                <div className="user-info-name">{this.getUserName()}</div>
                <div className="user-info-email">{this.getUserEmail()}</div>
              </div>
              
            </div>
            <Divider className="user-info-divider"/>
            <Divider className="user-info-divider"/>
            <MenuItem 
                onClick={() => {
                    if(this.props.onLogout)
                        this.props.onLogout();
                }}
            >
                Sign Out
            </MenuItem>
        </Menu>

      {this.customDialog()}
    </Box>
  }
}

export default withRouter(ProfileDropdown);
