import React, { Component } from 'react';
import BreadCrumb from "../../components/Navs/Breadcrumb"
import {
  Box,
  Grid,
  Table,
  InputLabel,
  Select,
  MenuItem,
  Button,
  CircularProgress,
} from '@material-ui/core';
import Card from '../../components/Card/Card.jsx';
import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import axios from 'axios';
import HtmlParser from 'react-html-parser';
import Icon from '@material-ui/core/Icon';
import CustomButton from '../../components/CustomFields/CustomButton.jsx';
import JSONTree from 'react-json-tree'
import { Map } from 'immutable'
import apiUtil from "../../api/apiUtil.jsx";
import authApi from "../../api/api.jsx";
import CustomDialog from '../../components/Dialog/CustomDialog.jsx';

const {
  REACT_APP_AUTH_API_URL
} = process.env;

class CompanyDetail extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    this.refTable = React.createRef();
    this.refTableSearch = React.createRef();

    let hiddenColumns = [];
    let columnHiddenSettingsId = 0;
    let columnHiddenSettings = JSON.parse(localStorage.getItem('job_column_settings'));

    if (columnHiddenSettings !== null) {
      hiddenColumns = columnHiddenSettings.json_data.replace(/[^0-9a-zA-Z-_.,]/g, "").split(",");
      columnHiddenSettingsId = columnHiddenSettings.id ? columnHiddenSettings.id : 0;
    }
    let id = (this.props.match && this.props.match.params && this.props.match.params.id && this.props.match.params.id > 0) ? this.props.match.params.id : 0;
    
    this.state = {
      data: [],
      test: null,
      data_admins: [],
      data_billing: [],
      data_free_account: [],
      access_free_account_data: [],
      data_promotion: [],
      access_promotion_data:[],
      isLoadingStatus: false,
      status: [],
      page: 1,
      errorStatus:'',
      total: 20,
      invoice: true,
      invoice_detail: false,
      limit: 20,
      isLoading: false,
      company_id: '',
      action: '',
      pageSize: 20,      // status: [],
      hiddenColumnSettingId: columnHiddenSettingsId,
      hiddenColumns: hiddenColumns,
      id: id,
      billing_data_list: [],
      openDialog: false,
      openDialogItem: null

    }
  }

  componentDidMount() {
    this.callReadApi();
    this.callFreeAccountReadApi();
    this.callPromotionReadApi();
    this.callAccessFreeReadApi();
    this.callAccessPromotionApi();
    this.callBillingListApi();
  }

  callReadApi() {

    let errorMessage = "";
    let status = 0;
    
    let dataid = this.state.id;
    const PATH = 'admin/api/';
    const api = `application_companies/${dataid}`;
    const URL = REACT_APP_AUTH_API_URL + PATH + api;
    let token = window.localStorage.getItem('access_token');

    let param = {
      application_company_id: dataid,
      // take: this.state.limit,
      // search: "",
      // start_date: startDate,
      // end_date: endDate,
      // sorting_array: ['created_at DESC'],
    };
    axios.get(
      // "https://auth-api.worknode.ai/admin/api/application_companies?search=&page=1&take=10&sorting_array[]=created_at%20DESC",
      URL,
      {
        params: param,
        // data: param,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      })
      .catch(error => {
        try {
          status = error.response.status;
          this.setState({
            errorStatus : status
          })
          if(this.state.errorStatus == 401) {
            const { clearStorage } = this.context;
            this.setState({
              profileMenu: null,
              isDrawerOpen: false
            });
            clearStorage();
            window.location = "/";
          } else {
            if (error.response) {
              // Request made and server responded
              errorMessage = error.response.data;
            } else if (error.request) {
              // The request was made but no response was received
              errorMessage = error.request;
            } else {
              // Something happened in setting up the request that triggered an Error
              errorMessage = error.message;
            }
          }
        } catch (err) {
          errorMessage = 'Service Unavailable!'
        }
      }).then((response) => {
        if (response != null) {

          var json_data = response.data.result;
          var result = [];
          for (var i in json_data) {
            result[i] = json_data[i];
          }

          var json_data_admins = response.data.result.admins;
          var result_admins = [];
          for (var i in json_data_admins) {
            result_admins[i] = json_data_admins[i];
          }

          // var json_data_billing = response.data.result.application_subscription_billings;
          // var result_billing = [];
          // for (var i in json_data_billing) {
          //   result_billing[i] = json_data_billing[i];
          // }

          this.setState({
            // data: response.data.result,
            total: response.data.total,
            isLoading: false,
            data_admins: result_admins,
            data: result,
            // data_billing: result_billing,
            test: response.data.result
          });

        }

      });
  }

  callFreeAccountReadApi() {

    let errorMessage = "";
    let status = 0;
    const {
      REACT_APP_AUTH_API_URL
    } = process.env;
    const PATH = 'admin/api/';
    const api = `free_accounts`;
    const URL = REACT_APP_AUTH_API_URL + PATH + api;
    let token = window.localStorage.getItem('access_token');

    let param = {
      is_enabled: true,
    };
    axios.get(
      // "https://auth-api.worknode.ai/admin/api/application_companies?search=&page=1&take=10&sorting_array[]=created_at%20DESC",
      URL,
      {
        params: param,
        // data: param,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      })
      .catch(error => {
        try {
          status = error.response.status;

          if (error.response) {
            // Request made and server responded
            errorMessage = error.response.data;
          } else if (error.request) {
            // The request was made but no response was received
            errorMessage = error.request;
          } else {
            // Something happened in setting up the request that triggered an Error
            errorMessage = error.message;
          }
        } catch (err) {
          errorMessage = 'Service Unavailable!'
        }
      }).then((response) => {
        if (response != null) {

          this.setState({
            data_free_account: response.data.result,
            isLoading: false,

          });
          console.log("qqq ss", this.state.data_free_account);

        }

      });
  }

  callPromotionReadApi() {

    let errorMessage = "";
    let status = 0;
    const {
      REACT_APP_AUTH_API_URL
    } = process.env;
    let dataid = this.state.id;
    const PATH = 'admin/api/';
    const api = `promotions`;
    const URL = REACT_APP_AUTH_API_URL + PATH + api;
    let token = window.localStorage.getItem('access_token');

    let param = {
      // is_enable: '',
      is_enabled: true,
      is_for_companies: true
    };
    axios.get(
      // "https://auth-api.worknode.ai/admin/api/application_companies?search=&page=1&take=10&sorting_array[]=created_at%20DESC",
      URL,
      {
        params: param,
        // data: param,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      })
      .catch(error => {
        try {
          status = error.response.status;

          if (error.response) {
            // Request made and server responded
            errorMessage = error.response.data;
          } else if (error.request) {
            // The request was made but no response was received
            errorMessage = error.request;
          } else {
            // Something happened in setting up the request that triggered an Error
            errorMessage = error.message;
          }
        } catch (err) {
          errorMessage = 'Service Unavailable!'
        }
      }).then((response) => {
        if (response != null) {



          this.setState({
            isLoading: false,
            data_promotion: response.data.result,
          });

        }

      });
  }

  callfreeAccountputApi = (id, action , callback = null) => {

    let errorMessage = "";
    let status = 0;
    const {
      REACT_APP_AUTH_API_URL
    } = process.env;
    const PATH = 'admin/api/';
    const api = `free_accounts/${id}`;
    const param = {
      application_company_id: this.state.id,
      company_action: action,
    };

    const URL = REACT_APP_AUTH_API_URL + PATH + api;
    let token = window.localStorage.getItem('access_token');
    this.setState({
      isFreeAccLoading: true
    }, ()=>{
      axios.put(
      // "https://auth-api.worknode.ai/admin/api/application_companies?search=&page=1&take=10&sorting_array[]=created_at%20DESC",
      URL,
      param,
      {
        
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      })
      .catch(error => {
        try {
          status = error.response.status;

          if (error.response) {
            // Request made and server responded
            errorMessage = error.response.data;
          } else if (error.request) {
            // The request was made but no response was received
            errorMessage = error.request;
          } else {
            // Something happened in setting up the request that triggered an Error
            errorMessage = error.message;
          }
        } catch (err) {
          errorMessage = 'Service Unavailable!'
        }
      }).then((response) => {
        if(callback) {
          callback()
        }
        this.callAccessFreeReadApi();
        this.setState({
          isFreeAccLoading:false
        })
        
      });
    })
    
  }

  callPromotionPutApi = (id, action, callback = null) => {
    
    let errorMessage = "";
    let status = 0;
    const {
      REACT_APP_AUTH_API_URL
    } = process.env;
    const PATH = 'admin/api/';
    const api = `promotions/${id}`;
    const param = {
      application_company_id: this.state.id,
      company_action: action,
    };

    const URL = REACT_APP_AUTH_API_URL + PATH + api;
    let token = window.localStorage.getItem('access_token');

    this.setState({
      isPromotionLoading:true
    },()=> {
      axios.put(
      // "https://auth-api.worknode.ai/admin/api/application_companies?search=&page=1&take=10&sorting_array[]=created_at%20DESC",
      URL,
      param,
      {
        
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      })
      .catch(error => {
        try {
          status = error.response.status;

          if (error.response) {
            // Request made and server responded
            errorMessage = error.response.data;
          } else if (error.request) {
            // The request was made but no response was received
            errorMessage = error.request;
          } else {
            // Something happened in setting up the request that triggered an Error
            errorMessage = error.message;
          }
        } catch (err) {
          errorMessage = 'Service Unavailable!'
        }
      }).then((response) => {
        if(callback) {
          callback()
        }
        this.callAccessPromotionApi();
        this.setState({
          isPromotionLoading: false
        })
      });
    })

    
  }

  callAccessFreeReadApi() {

    let errorMessage = "";
    let status = 0;
    const {
      REACT_APP_AUTH_API_URL
    } = process.env;
    let dataid = this.state.id;
    const PATH = 'admin/api/';
    const api = `free_accounts`;
    const URL = REACT_APP_AUTH_API_URL + PATH + api;
    let token = window.localStorage.getItem('access_token');

    let param = {
      application_company_id: dataid,
    };
    axios.get(
      // "https://auth-api.worknode.ai/admin/api/application_companies?search=&page=1&take=10&sorting_array[]=created_at%20DESC",
      URL,
      {
        params: param,
        // data: param,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      })
      .catch(error => {
        try {
          status = error.response.status;

          if (error.response) {
            // Request made and server responded
            errorMessage = error.response.data;
          } else if (error.request) {
            // The request was made but no response was received
            errorMessage = error.request;
          } else {
            // Something happened in setting up the request that triggered an Error
            errorMessage = error.message;
          }
        } catch (err) {
          errorMessage = 'Service Unavailable!'
        }
      }).then((response) => {
        if (response != null) {

          this.setState({
            access_free_account_data: response.data.result,
            isLoading: false,
          });
          
        }

      });
  }

  callAccessPromotionApi() {

    let errorMessage = "";
    let status = 0;
    const {
      REACT_APP_AUTH_API_URL
    } = process.env;
    let dataid = this.state.id;
    const PATH = 'admin/api/';
    const api = `promotions`;
    const URL = REACT_APP_AUTH_API_URL + PATH + api;
    let token = window.localStorage.getItem('access_token');

    let param = {
      application_company_id: dataid,
    };
    axios.get(
      // "https://auth-api.worknode.ai/admin/api/application_companies?search=&page=1&take=10&sorting_array[]=created_at%20DESC",
      URL,
      {
        params: param,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      })
      .catch(error => {
        try {
          status = error.response.status;

          if (error.response) {
            // Request made and server responded
            errorMessage = error.response.data;
          } else if (error.request) {
            // The request was made but no response was received
            errorMessage = error.request;
          } else {
            // Something happened in setting up the request that triggered an Error
            errorMessage = error.message;
          }
        } catch (err) {
          errorMessage = 'Service Unavailable!'
        }
      }).then((response) => {
        if (response != null) {

          this.setState({
            access_promotion_data: response.data.result,
            isLoading: false,
          });
        }

      });
  }
  

  callBillingListApi = (callback=null) => {
    const accessToken  = window.localStorage.getItem('access_token');

    let params = {
      application_company_id:this.state.id
    }

    this.setState({
      isLoading: true
    }, () => {
      authApi.read('application_subscription_billing/', params, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            // console.log(data,"billing data")
            this.setState({
              data_billing :data.result,
              isLoading: false,

            }, () => {
              if(callback){
                callback((data && data.result) ? data.result : null);
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(null, []);
              }
            });
          });
      });
    });
  }

  /* DIALOG */
  customDialog = () => {
    return <CustomDialog 
      open={this.state.openDialog}
      title={'Remove'}
      onClose={() => {
        this.setState({
          openDialog: false,
          openDialogItem: null,
        });
      }}
      onOk={() => {
        let data = this.state.openDialogItem;
        if(data && data.type == 'freeAccount') {
          // this.callfreeAccountputApi(data.id, 'remove')
          this.callfreeAccountputApi(data.id, 'remove', () => {
            this.setState({
              openDialog: false,
              openDialogItem: null,
            }, () => {
              // this.callReadApi(this.refTableSearch.current.state.value);
              apiUtil.toast('Successfully remove', 'check_circle');
            });
          });
        } else if(data && data.type == 'promotion') {
          // this.callPromotionPutApi(data.id, 'remove')
          this.callPromotionPutApi(data.id, 'remove', () => {
            this.setState({
              openDialog: false,
              openDialogItem: null,
            }, () => {
              // this.callReadApi(this.refTableSearch.current.state.value);
              apiUtil.toast('Successfully remove', 'check_circle');
            });
          });
        }
        
      }}
    >
      <Box>Are you sure you want to remove?</Box>
    </CustomDialog>
  }

  customStickyPageHeader = () => {
    return <Box clone pl={4} pr={4} height={'100%'} alignItems={'center'}>
      <Grid container>
        <BreadCrumb />
      </Grid>
    </Box>
  }

  customTable() {


    return <>

      <h1>Company Details</h1>

      <Table className="style-tb">

        <tr>
          <td width="50%" >application_company id</td><td align="left">{this.state.data.id}</td>
        </tr>
        <tr>
          <td> company_registration_no </td> <td > {this.state.data.company_registration_no} </td>
        </tr>
        <tr>
          <td> company_name </td> <td > {this.state.data.company_name} </td>
        </tr>
        <tr>
          <td> company_logo </td> <td > {this.state.data.company_logo} </td>
        </tr>
        <tr>
          <td> email </td> <td > {this.state.data.email} </td>
        </tr>
        <tr>
          <td> contact_no </td> <td > {this.state.data.contact_no} </td>
        </tr>
        <tr>
          <td> created_at </td> <td > {this.state.data.created_at} </td>
        </tr>
        <tr>
          <td> updated_at </td> <td > {this.state.data.updated_at} </td>
        </tr>
        <tr>
          <td> company_gst_registration_no </td> <td > {this.state.data.company_gst_registration_no} </td></tr>
        <tr>
          <td> fax_no </td> <td > {this.state.data.fax_no} </td>
        </tr>
        <tr>
          <td> unit_no </td> <td > {this.state.data.unit_no} </td>
        </tr>
        <tr>
          <td> building_no </td> <td > {this.state.data.building_no} </td>
        </tr>
        <tr>
          <td> address </td> <td > {this.state.data.address} </td>
        </tr>
        <tr>
          <td> postal_code </td> <td > {this.state.data.postal_code} </td>
        </tr>
        <tr>
          <td> application_company_status_id </td>
          <td>
            {(() => {
              if (this.state.data.application_company_status_id == null) {
                return <span>Trial</span>

              } else if (this.state.data.application_company_status_id == 1) {
                return <><p>{this.state.data.application_company_status_id}</p><p>Blacklist</p></>
              } else if (this.state.data.application_company_status_id == 2) {
                return <span>Subscribed</span>
              } else {
                return <span></span>
              }
            })()}
          </td>
        </tr>
        <tr>
          <td> is_welcome_email_sent </td> <td > {this.state.data.is_welcome_email_sent ? 'true' : 'false'}</td>
        </tr>
      </Table>
    </>

  }


  freeServiceList = () => {

    let free_accounts = this.state.data_free_account ? this.state.data_free_account : [];
    let access_free_account_data = this.state.access_free_account_data ? this.state.access_free_account_data : [];
    let access_free_accId = []
    if(access_free_account_data.length > 0) {

      access_free_account_data.map(data => {
        access_free_accId.push(data.id)
      })
    }
    free_accounts = free_accounts.filter((acc) => {
      return !access_free_accId.includes(acc.id)
    })
    if ( free_accounts.length > 0 ) {
      return <>
      <Button style={{ backgroundColor: '#2e86aa' }}
        >
          <InputLabel id="SELECT" style={{ color: '#FFFFFF' }}>Select
          <Select
              label="SELECT"
              style={{ color: '#FFFFFF' }}
            >
              <MenuItem selected="true">Select</MenuItem>
              {
                free_accounts.map((account) =>
                <MenuItem
                onClick={() => {
                  this.callfreeAccountputApi(account.id, 'add');
                }}
              >
                ID: {account.id}
                <code>(</code>
                {account.start_date} --&gt; {account.end_date}
                <code>)</code>
                Name: {account.name}
              </MenuItem>
                )
              }
            </Select>
          </InputLabel>
        </Button>
      
      </>
    }
    return <Button style={{ backgroundColor: '#2e86aa' }}
    >
      <InputLabel id="SELECT" style={{ color: '#FFFFFF' }}>Select
      <Select
          label="SELECT"
          style={{ color: '#FFFFFF' }}
          disabled={true}
        >
        </Select>
      </InputLabel>
    </Button>
    
  }

  promotionMenuList = () => {

    let promotions = this.state.data_promotion ? this.state.data_promotion : [];
    let access_promotion_data = this.state.access_promotion_data ? this.state.access_promotion_data : [];
    let access_promotionId = []
    if(access_promotion_data.length > 0) {

      access_promotion_data.map(data => {
        access_promotionId.push(data.id)
      })
    }
    
    promotions = promotions.filter((acc) => {
      return !access_promotionId.includes(acc.id)
    })

    if ( promotions.length > 0) {
      return <>
      <Button style={{ backgroundColor: '#2e86aa' }}
        // disabled={true}
      >
        <InputLabel id="SELECT" style={{ color: '#FFFFFF' }}>SELECT
          <Select
            label="SELECT"
            style={{ color: '#FFFFFF' }}
          >
            <MenuItem selected="true">Select</MenuItem>
            {
              promotions.map((promotion) =>
              <MenuItem
              onClick={() => {
                
                this.callPromotionPutApi(promotion.id, 'add');
              }}
            >
              ID: {promotion.id}
              <code>(</code>
              {promotion.start_date} --&gt; {promotion.end_date}
              <code>)</code>
              Name: {promotion.name}
            </MenuItem>
              )
      }
          </Select>
        </InputLabel>
      </Button>
      
      </>
    }
    return <Button style={{ backgroundColor: '#2e86aa' }} >
     <InputLabel id="SELECT" style={{ color: '#FFFFFF' }}>SELECT
            <Select
              label="SELECT"
              style={{ color: '#FFFFFF' }}
              disabled={true}
            >
            </Select>
          </InputLabel>
   </Button>
    
  }

  freeService() {

    return <Box>
      <h1>List of Free services (enable only)</h1>
      {this.state.isFreeAccLoading ? 
      <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'5px'}>
      Loading....
      </Box>
      :
      <div>
        {this.freeServiceList()}
        {/* <Button style={{ backgroundColor: '#2e86aa' }}
        >
          <InputLabel id="SELECT" style={{ color: '#FFFFFF' }}>Select
          <Select
              label="SELECT"
              style={{ color: '#FFFFFF' }}
            >
              <MenuItem selected="true">Select</MenuItem>
              {this.freeServiceList()}
            </Select>
          </InputLabel>
        </Button> */}
        <br />
        <br />
        {this.state.access_free_account_data.length > 0 ?
          <Table >
            <tr style={{ backgroundColor: "#565553", height: "50px" }}>
              <th style={{ color: "#FFFFFF" }}>ID</th>
              <th style={{ color: "#FFFFFF" }}>Name Free Service</th>
              <th style={{ color: "#FFFFFF" }}>Start Date</th>
              <th style={{ color: "#FFFFFF" }}>End Date</th>
              <th style={{ color: "#FFFFFF" }}>Actions</th>
            </tr>
            {this.state.access_free_account_data.map((data) =>
              <tr style={{ textAlign: "center", height: "50px" }}>
                <td>{data.id}</td>
                <td>{data.name}</td>
                <td>{data.start_date}</td>
                <td>{data.end_date}</td>
                <td> <Button style={{ backgroundColor: '#FF4500' }}
                  onClick={() => {
                    this.setState({
                      openDialog: true,
                      openDialogItem: {
                        id:data.id,
                        type:'freeAccount'
                      }
                    })
                    // this.callfreeAccountputApi(data.id, 'remove')
                    
                  }}
                >Remove</Button>
                </td>
              </tr>
            )}
          </Table> : ""
        }
      </div> }
        
    </Box>
  }

  promotionService() {
    return <Box>
      <h1>List of Promotion services (enable only)</h1>
      {this.state.isPromotionLoading ? 
      <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'5px'}>
          Loading....
      </Box>
       : <div>
         {this.promotionMenuList()}
        {/* <Button style={{ backgroundColor: '#2e86aa' }}
         disabled={this.state}
        >
          <InputLabel id="SELECT" style={{ color: '#FFFFFF' }}>SELECT
            <Select
              label="SELECT"
              style={{ color: '#FFFFFF' }}
            >
              <MenuItem selected="true">Select</MenuItem>
              {this.promotionMenuList()}
            </Select>
          </InputLabel>
        </Button> */}
        <br />
        <br />
        {this.state.access_promotion_data.length > 0 ?
          <Table >
            <tr style={{ backgroundColor: "#565553", height: "50px" }}>
              <th style={{ color: "#FFFFFF" }}>ID</th>
              <th style={{ color: "#FFFFFF" }}>Name Free Service</th>
              <th style={{ color: "#FFFFFF" }}>Start Date</th>
              <th style={{ color: "#FFFFFF" }}>End Date</th>
              <th style={{ color: "#FFFFFF" }}>Actions</th>
            </tr>
            {this.state.access_promotion_data.map((data) =>
              <tr style={{ textAlign: "center", height: "50px" }}>
                <td>{data.id}</td>
                <td>{data.name}</td>
                <td>{data.start_date}</td>
                <td>{data.end_date}</td>
                <td> <Button style={{ backgroundColor: '#FF4500' }}
                  onClick={() => {
                    
                    this.setState({
                      openDialog:true,
                      openDialogItem: {
                        id:data.id,
                        type:'promotion'
                      }
                    })
                    // this.callPromotionPutApi(data.id, 'remove')
                    
                  }}
                >Remove</Button>
                </td>
              </tr>
            )}
          </Table> : ""
        }
      </div>}
     
    </Box>
  }
  ready_to_pay = (billing_date) =>{
    var today = new Date();
    var bill_endDate = new Date(billing_date);
    if(today > bill_endDate){
      return true
    }else{
      return false
    }
  }

  getDueDate  = (billing_date) =>{
    var someDate = new Date(billing_date);
    someDate.setDate(someDate.getDate() + 14); 
    return someDate.toDateString();
  }

  make_payment = (id) => {
    const accessToken = window.localStorage.getItem('access_token');
    let data = {
      app_id : this.state.id
    }
    this.setState({
      isLoading: true
    }, () => {
      authApi.create('payment/by_pass_payment', data, accessToken)
          .then((result) => {
            apiUtil.parseResult(result, (data) => {
              this.setState({
                isLoading: false,
              }, () => {
                
                // this.props.history.push('/promotion');
                this.callBillingListApi()
                apiUtil.toast('Successfully paid pass', 'check_circle');
              });
            }, (error, type) => {
              
              apiUtil.toast(error, 'check_circle', 'error');
              this.setState({
                isLoading: false,
              }, () => {
                // if(callback){
                //   callback();
                // }
              });
            });
        });
    })
  }

  make_payment_blacklist = (id) => {
    const accessToken = window.localStorage.getItem('access_token');
    let data = {
      app_id : this.state.id,
      "application_subscription_billing_id": id
    }
    this.setState({
      isLoading: true
    }, () => {
      authApi.create('payment/by_pass_payment', data, accessToken)
          .then((result) => {
            apiUtil.parseResult(result, (data) => {
              this.setState({
                isLoading: false,
              }, () => {
                
                // this.props.history.push('/promotion');
                this.callBillingListApi()
                apiUtil.toast('Successfully paid pass', 'check_circle');
              });
            }, (error, type) => {
              this.setState({
                isLoading: false,
              }, () => {
                // if(callback){
                //   callback();
                // }
              });
            });
        });
    })
  }

  invoiceList() {
    let billobj_val = (this.state.data_billing[0] && this.state.data_billing[0].id) ? this.state.data_billing[0].id : "";
    // console.log(billobj_val, "lslfdjaslf")
    return <>
      {this.state.invoice ? <>
        <h1>List of Invoices</h1>
       
        {this.state.data_billing.length > 0 ?
            <Table >
                <tr style={{textAlign:'left', backgroundColor: "#565553", height: "50px",  }}>
                <th style={{ color: "#FFFFFF" , paddingLeft:"10px"}}>Invoice No</th>
                <th style={{ color: "#FFFFFF" }}>Date</th>
                <th style={{ color: "#FFFFFF" }}>Payment Status</th>
                <th style={{ color: "#FFFFFF" }}>Actions</th>
                </tr>
                {this.state.data_billing.map((data) =>
                <tr style={{  height: "50px", borderBottom: "1px solid #f2eded" }}>
                    <td>{data.invoice_number? <span style={{ color: "#51adcf", cursor: "pointer" }}
                      onClick={() => {
                        let data_billing = this.state.data_billing
                        let billing_data_list = data_billing.filter((d) => {
                          if(d.id == data.id) {
                            return data;
                          }
                          
                        })
                        this.setState({
                          billing_data_list: billing_data_list,
                          invoice: false,
                          invoice_detail: true,
                        });
                      }}
                    >
                      {HtmlParser(data.invoice_number)}
                    </span>: ''}</td>
                    <td>{data.billing_date? new Date(data.billing_date).toDateString() : '' }</td>
                    <td> <Box>
                    {!data.is_paid && this.ready_to_pay(data.billing_date) && this.state.data.application_company_status_id === 1 ? <span>Blacklist<br/></span>:""}
                    {data.is_paid ? <span style={{color:'green'}}>PAID<br/></span>: ''}
                    {!data.is_paid ? <span style={{color:'red'}}>UNPAID<br/></span>: ''}
                    {data.total_price == 0 ? <span>(0 charged)<br/></span>: ''}
                    {data.is_paid && data.application_payment_record && data.application_payment_record.payment_method == 'free_of_charges' ? <span>ADMIN BY PASS<br/></span>: ''}
                    {!data.is_paid ? <span>Please pay before <b>{this.getDueDate(data.billing_date)}</b> to avoid service suspension.</span>: ''}
                    
                  </Box>
                   </td>
                    <td><Grid container>
                  {data.is_paid && data.application_payment_record && data.application_payment_record.payment_method !== 'free_of_charges' ? <Grid item xl={4}><CustomButton 
                      className={'create-role-step'}
                      type={'button'}
                      color={'secd'}
                      isLoading={this.state.isLoading}
                      onClick={() => {
                        const URL = REACT_APP_AUTH_API_URL+'application_payment_records_pdf/'+data.id+'.pdf';
                        window.open(URL, '_blank');
                      }}
                    >
                    <Box>Receipt</Box> 
                      
                    </CustomButton> </Grid>: ''
                    }
                    {!data.is_paid && this.ready_to_pay(data.billing_date) && this.state.data.application_company_status_id === 2 ? <Grid item xl={4}><CustomButton 
                      className={'create-role-step'}
                      type={'button'}
                      color={'primary'}
                      isLoading={this.state.isLoading}
                      onClick={() => {
                        this.make_payment(data.id)
                      }}
                    >
                    <Box>By Pass</Box> 
                      
                    </CustomButton> </Grid>: ''
                    }
                    {!data.is_paid && this.ready_to_pay(data.billing_date) && this.state.data.application_company_status_id === 1 ? <Grid item xl={4}><CustomButton 
                      className={'create-role-step'}
                      type={'button'}
                      color={'primary'}
                      isLoading={this.state.isLoading}
                      onClick={() => {
                        this.make_payment_blacklist(data.id)
                      }}
                    >
                    <Box>By Pass</Box> 
                      
                    </CustomButton> </Grid>: ''
                    }
                  <CustomButton color={'secd'}
                    onClick={() => {
                        let data_billing = this.state.data_billing
                        let billing_data_list = data_billing.filter((d) => {
                          if(d.id == data.id) {
                            return data;
                          }
                          
                        })
                        this.setState({
                          billing_data_list: billing_data_list,
                          invoice: false,
                          invoice_detail: true,
                        });
                      
                    }}
                  >
                    <Box display={'inline-block'}>Details</Box>
                  </CustomButton>
                  </Grid></td>
                </tr>
                )}
            </Table> : " no invoice list"
        }
      </> : ''
      }
      {this.state.invoice_detail ? <>
        <Box style={{ paddingBottom: "20px" }}>
          {/* <CustomButton color={'primary'}
            onClick={() => {
              this.setState({
                invoice: true,
                invoice_detail: false,
              });
            }}

          >
            <Box display={'inline-block'}>&lt; &lt; Back</Box>
          </CustomButton> */}
          {this.topBottomList()}
        </Box>
        <Table style={{ border: "0px solid #c1c1c1" }}>
          <tr style={{ borderBottom: "1px solid #eaf2fb" }}>
            <td width="50%" height="50px" style={{ paddingTop: "25px !important" }}><strong>
              {(this.state.billing_data_list[0] && this.state.billing_data_list[0].invoice_number) ? this.state.billing_data_list[0].invoice_number : ""}
            </strong></td>
            <td></td>
          </tr>
          <tr style={{ borderBottom: "1px solid #eaf2fb" }}>
            <td><strong>Invoice Form</strong></td>
            <td height="50px">LogisFleet Pte. Ltd <br />
              01, Bukit Batok Crescent, #09-51, Wcega Plaza, Singapore 658064</td>
          </tr>
          <tr style={{ borderBottom: "1px solid #eaf2fb" }}>
            <td height="50px"><strong> Billed To : </strong></td>
            <td > {(this.state.data && this.state.data.company_name) ? this.state.data.company_name : ""}
              {(this.state.data && this.state.data.address) ? this.state.data.address : ""}</td>
          </tr>
          <tr style={{ borderBottom: "1px solid #eaf2fb" }}>
            <td height="50px"><strong> Billing Date : </strong></td>
            <td > {(this.state.data_billing[0] && this.state.data_billing[0].billing_date) ? this.state.data_billing[0].billing_date : ""} </td>
          </tr>
          <tr style={{ borderBottom: "1px solid #eaf2fb 0.4" }}>
            <td height="50px"><strong> Due Date : </strong></td>
            <td > {(this.state.data_billing[0] && this.state.data_billing[0].billing_date) ? this.state.data_billing[0].billing_date : ""} </td>
          </tr>
        </Table>
        {this.invoiceListTable()}
      </> : ''
      }
    </>

  }

  topBottomList = () => {
    return <Grid container>
      <Grid item xs={6} >
      <CustomButton 
          className={'create-role-step'}
          type={'button'}
          color={'secondary'}
          isLoading={this.state.isLoading}
          onClick={() => {
            this.setState({
              invoice: true,
              invoice_detail: false,
            });
          }}
        >
          <Box >&lt; &lt; Back</Box>
          
        </CustomButton>
      </Grid>
      <Grid item container xs={6} spacing={2} justify="flex-end">
        <Grid item >
        <CustomButton 
          className={'create-role-step'}
          type={'button'}
          color={'secondary'}
          isLoading={this.state.isLoading}
          onClick={() => {

            const URL = REACT_APP_AUTH_API_URL+'application_invoices_pdf/'+this.state.billing_data_list[0].id+'.pdf';
            axios.get(URL)
            .catch(error=>{
              if(error) {
                apiUtil.toast("Cannot print this invoice", 'check_circle', 'error')
              }
            })
            .then((result)=>{
              if(result) {
                window.open(URL, '_blank')
              }
            })
            // const URL = REACT_APP_AUTH_API_URL+'application_invoices_pdf/'+this.state.billing_data_list[0].id+'.pdf';
            // window.open(URL, '_blank');
          }}
        >
         <Box>Print Invoice</Box> 
          
        </CustomButton>
        </Grid>
        {this.state.billing_data_list.length > 0 && !this.state.billing_data_list[0].is_paid ?< Grid item >
        <CustomButton 
          className={'create-role-step'}
          type={'button'}
          color={'primary'}
          isLoading={this.state.isLoading}
          onClick={() => {
            this.make_payment(this.state.id)
            // this.props.history.push({
            //   pathname: '/payment',
            //   state: {
            //       id: this.state.id,
            //   }
            //   });
          }}
        >
          <Box>Pay Now</Box>
          
        </CustomButton>
        </Grid> : ''
        }
        
      </Grid>
    </Grid>
  }


  invoiceListTable = () => {
    return <Table>
    <tr className="table-head-style">
      <th className="table-head-style-th">Device ID</th>
      <th className="table-head-style-th">Usage Dates</th>
      <th className="table-head-style-th">Total Duration</th>
      <th className="table-head-style-th">Total</th>
    </tr> 
    {this.state.billing_data_list[0].billing_items.filter(function(data){
        if(data.device_management != null)
            return data;
    }).map((data) =>

      <tr className="even-style">
        <td className="table-head-style-td">{(data.device_management && data.device_management.device_id != '')? data.device_management.device_id : ''}</td>
        <td className="table-head-style-td">{(data.device_start_date && data.device_start_date != '')? data.device_start_date + ' to ' + data.device_end_date: ''}</td>
        <td className="table-head-style-td">{(data.number_of_days_used && data.number_of_days_used != '')? data.number_of_days_used + ' days' : ''}</td>
        <td className="table-head-style-td">{(data.charges && data.charges != '')? '$ ' + data.charges : ''}</td> 
      </tr>
    )}
    <tr className="even-style-plan">
        <td colspan="3" className="table-head-style-td"><span>Subscribed Plan: </span>
        {(this.state.billing_data_list[0] && this.state.billing_data_list[0].billing_items[0] && this.state.billing_data_list[0].billing_items[0].application_company_plan)? this.state.billing_data_list[0].billing_items[0].application_company_plan.plan_name : ''}
        </td>
        <td className="table-head-style-td">
        {(this.state.billing_data_list[0] && this.state.billing_data_list[0].billing_items[0] && this.state.billing_data_list[0].billing_items[0].application_company_plan)? '$ ' + this.state.billing_data_list[0].billing_items[0].application_company_plan.price : ''}
        </td>
    </tr>
    <tr className="even-style-plan">
        <td colspan="3" className="table-head-style-td">Subtotal</td>
        <td className="table-head-style-td">
        {
          // console.log("bblist",this.state.billing_data_list[0])
        (this.state.billing_data_list[0] && this.state.billing_data_list[0].total_price != '')? '$ ' + this.state.billing_data_list[0].total_price : ''}
        </td>
    </tr>
    <tr className="even-style-total">
        <td colspan="3" className="table-head-style-td">Total</td>
        <td className="table-head-style-td">
        {(this.state.billing_data_list[0] && this.state.billing_data_list[0].total_price != '')? '$ ' + this.state.billing_data_list[0].total_price : ''}
        </td>
    </tr>
  </Table>
  }

  availableUser() {
    return <>
      <h1>List of available users</h1>
      {/* <DataTable
        ref={this.refTable}
        className={'job-table-list'}
        isLoading={this.state.isLoading}
        grouping={this.state.grouping}
        limit={20}
        pageSize={20}
        // pageSizeOptions ={this.state.pageSizeOptions}
        page={1}
        total={20}
        data={this.state.data_admins}
        // defaultExpanded={true}
        // selection={true}
        onPageChange={(page, size) => {

          this.state.limit = size;
          this.setState({
            page: page,
          }, () => {
            this.callReadApi();
            // this.callReadApi(this.refTableSearch.current.state.value);
          });
        }}
        // Toolbar={this.customToolbar}
        // Row={this.customRow}
        columns={[
          {
            title: "Name",
            field: "name",
            cellStyle: {
              minWidth: 240,
              maxWidth: 240
            },
            render: (row, type) => {
              if (type === 'row') {
                return (row.first_name != ' ') ? HtmlParser(row.first_name) : '';
              } else {
                return row;
              }
            }
          },
          {
            title: "Email",
            field: "email",
            cellStyle: {
              minWidth: 150,
              maxWidth: 150
            },
            render: (row, type) => {
              if (type === 'row') {
                return (row.email != ' ') ? HtmlParser(row.email) : '';
              } else {
                return row;
              }
            }
          },
          {
            title: "Status",
            field: "status",
            cellStyle: {
              minWidth: 150,
              maxWidth: 150
            },

            render: (row, type) => {
              if (type === 'row') {
                if (!row.is_banned && !row.disabled) {
                  return <><Box>
                    <Icon style={{ color: "green", width: "10px", height: "10px" }}>lens</Icon>
                    <span> Active</span>
                  </Box>

                  </>
                } else if (row.is_banned && !row.disabled) {
                  return <><Box>
                    <Icon style={{ color: "grey", width: "10px", height: "10px" }}>lens</Icon>
                    <span> Disabled</span>
                  </Box>
                  </>
                } else if (!row.is_banned && row.disabled) {
                  return <><Box>
                    <Icon style={{ color: "red", width: "10px", height: "10px" }}>lens</Icon>
                    <span> Disabled</span>
                  </Box>
                  </>
                }
              } else {
                return row;
              }
            }
          },
          {
            title: "Last seen",
            field: "last-seen",
            cellStyle: {
              minWidth: 180,
              maxWidth: 180
            },
            render: (row, type) => {
              if (type === 'row') {
                if (row.last_seen != ' ') {
                  var date = new Date(row.last_seen);
                  return <>
                    {date.toLocaleTimeString()} -
                    {date.toLocaleDateString()}
                  </>
                }
              } else {
                return row;
              }
            }
          },
        ]}
      /> */}
      {this.state.data_admins.length > 0 ?
            <Table >
                <tr style={{ backgroundColor: "#565553", height: "50px", textAlign:"left" }}>
                <th style={{ color: "#FFFFFF", paddingLeft:"10px" }}>Name</th>
                <th style={{ color: "#FFFFFF" }}>Email</th>
                <th style={{ color: "#FFFFFF" }}>Status</th>
                <th style={{ color: "#FFFFFF" }}>Last Seen</th>
                {/* <th style={{ color: "#FFFFFF" }}>Actions</th> */}
                </tr>
                {this.state.data_admins.map((data) =>
                <tr style={{ height: "50px" , borderBottom: "1px solid #f2eded"}}>
                    <td>{data.first_name? data.first_name : '' } {data.last_name? data.first_name : ''}</td>
                    <td>{data.email? data.email : '' }</td>
                    <td>{!data.is_banned && !data.disabled ?<Box>
                      <Icon style={{ color: "green", width: "10px", height: "10px" }}>lens</Icon>
                        <span> Active</span>
                      </Box>: <Box>
                        <Icon style={{ color: "grey", width: "10px", height: "10px" }}>lens</Icon>
                        <span> Disabled</span>
                      </Box>}</td>
                    {/* <td>{data.end_date}</td> */}
                    <td> {data.last_seen ? new Date(data.last_seen).toLocaleDateString() : '-' }</td>
                </tr>
                )}
            </Table> : " no user list"
        }
    </>
  }

  jsonTreeView() {
    const theme = {
      scheme: 'monokai',
      author: 'wimer hazenberg (http://www.monokai.nl)',
      base00: '#ffffff',
      base01: '#383830',
      base02: '#49483e',
      base03: '#75715e',
      base04: '#a59f85',
      base05: '#f8f8f2',
      base06: '#f5f4f1',
      base07: '#f9f8f5',
      base08: '#f92672',
      base09: '#fd971f',
      base0A: '#f4bf75',
      base0B: '#a6e22e',
      base0C: '#a1efe4',
      base0D: '#66d9ef',
      base0E: '#ae81ff',
      base0F: '#cc6633',
    };

    if (this.state.test != null && this.state.data) {
      const json = this.state.test;
      return <JSONTree data={json} theme={theme} invertTheme={false} />;
    } else {
      return '';
    }




  }
  render() {
    return <Box className="dashboard-page" >
      <Card className={'job-listing-step'}>
        {this.customTable()}
      </Card>

      <Card className={'job-listing-step'}>
        {this.freeService()}
      </Card>

      <Card className={'job-listing-step'}>
        {this.promotionService()}
      </Card>

      <Card className={'job-listing-step'}>
        {this.invoiceList()}
      </Card>

      <Card className={'job-listing-step'}>
        {this.availableUser()}
      </Card>
      <Card className={'job-listing-step'}>
        <h3>JSON Data for Developer Option</h3>
        {this.jsonTreeView()}
      </Card>
      {this.customDialog()}
    </Box>;
  }
}

export default CompanyDetail;
