import React, { Component } from 'react';
import { AuthContext } from "../components/Auth/AuthDataProvider.jsx";

import { 
  Grid,
  IconButton,
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';

import MenuIcon from '@material-ui/icons/Menu';

import ProfileDropdown from "../components/Header/ProfileDropdown.jsx";

const useStyles = (theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
    // [theme.breakpoints.up('sm')]: {
    //   display: 'none',
    // },
  },
});

class Header extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);

    let showHeader = ((props.showHeader === true || props.showHeader === false) ? props.showHeader : true);
    let showMenuIcon = ((props.showMenuIcon === true || props.showMenuIcon === false) ? props.showMenuIcon : true);

    this.state = {
      showHeader: showHeader,
      showMenuIcon: showMenuIcon,
      isLogedUser: false,
      isDrawerOpen: false,
      profileMenu: null,
    };
  }


  componentDidMount() {
    this.checkIsUserLogedIn();
  }


  checkIsUserLogedIn = () => {
    // const { authenticated } = this.context;
    this.setState({ 
      // isLogedUser: authenticated
    });
  }

  logout = ()  => {
    const { clearStorage } = this.context;

    this.setState({
      profileMenu: null,
      isDrawerOpen: false
    });

    clearStorage();

    window.location = "/";
  }


  getHeader = () => {
    return <Grid container alignItems={'center'}>
      <Grid item xs={'auto'}>
        {this.state.showMenuIcon && <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={() => {
            if(this.props.onDrawerToggle){
              this.props.onDrawerToggle();
            }
          }}
          className={this.props.classes.menuButton}
        >
          <MenuIcon style={{
            color: 'white'
          }} />
        </IconButton>}
      </Grid>
      <Grid item xs={true}>
      {window.location.pathname == "/promotion" ? <span style={{color:"#fff", fontSize: '22px', fontWeight:"400"}}>Promotion</span> : ''}
      {window.location.pathname == "/dashboard" ? <span style={{color:"#FFF", fontSize: '22px', fontWeight:"400"}}>Company</span> : ''}
      {window.location.pathname == "/free-account-detail" ? <span style={{color:"#FFF", fontSize: '22px', fontWeight:"400"}}>Edit Free Account </span> : ''}
      {window.location.pathname == "/edit-sms-credential" ? <span style={{color:"#FFF", fontSize: '22px', fontWeight:"400"}}>Edit SMS Credential </span> : ''}
      {window.location.pathname == "/create-free-account" ? <span style={{color:"#FFF", fontSize: '22px', fontWeight:"400"}}>Create Free Account </span> : ''}
      {window.location.pathname == "/promotion-detail" ? <span style={{color:"#FFF", fontSize: '22px', fontWeight:"400"}}>Edit Promotion</span> : ''}
      {window.location.pathname == "/create-promotion" ? <span style={{color:"#FFF", fontSize: '22px', fontWeight:"400"}}>Create Promotion</span> : ''}
      {window.location.pathname == "/company-detail" ? <span style={{color:"#FFF", fontSize: '22px', fontWeight:"400"}}>Company Detail</span> : ''}
      {window.location.pathname == "/free-account" ? <span style={{color:"#FFF", fontSize: '22px', fontWeight:"400"}}>Free Account Listing</span> : ''}
      {window.location.pathname.split("/")[1] == 'company-detail' ? <span style={{color:"#FFF", fontSize: '22px', fontWeight:"400"}}>Company Detail</span> : ''}
      </Grid>
      <Grid item xs={'auto'}>
        <ProfileDropdown 
          profileMenu={this.state.profileMenu}
          onLogout={this.logout}
        />
      </Grid>
    </Grid>
  }

  
  render() {
    if(this.state.showHeader){
      return this.getHeader()
    }
  }
}

export default withStyles(useStyles)(Header);
