import React, { Component } from 'react';
import BreadCrumb from "../../components/Navs/Breadcrumb"
import { 
  Box,
  Grid,
} from '@material-ui/core';
import StickyPageHeader from '../../components/PageHeader/StickyPageHeader.jsx';
import Card from '../../components/Card/Card.jsx';
import { NavLink } from 'react-router-dom';
import Table from '../../components/Table/Table.jsx';
import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import CustomButton from '../../components/CustomFields/CustomButton.jsx';
import { Container, Row, Col, Button } from 'react-bootstrap';
import axios from 'axios';
import HtmlParser from 'react-html-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faEdit, faEye } from '@fortawesome/free-solid-svg-icons';
import CustomDialog from '../../components/Dialog/CustomDialog.jsx';
import apiUtil from "../../api/apiUtil.jsx";
import CustomSwitch from "../../components/CustomFields/CustomSwitch"
class Provider extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);

    this.refTable = React.createRef();
    this.refTableSearch = React.createRef();

    let hiddenColumns = [];
    let columnHiddenSettingsId = 0;
    let columnHiddenSettings = JSON.parse(localStorage.getItem('job_column_settings'));

    if (columnHiddenSettings !== null) {
      hiddenColumns = columnHiddenSettings.json_data.replace(/[^0-9a-zA-Z-_.,]/g, "").split(",");
      columnHiddenSettingsId = columnHiddenSettings.id ? columnHiddenSettings.id : 0;
    }
    

    this.state = {
      data :  [],
      page : 1,
      total : '',
      limit : 20,
      errorStatus:'',
      isLoadingStatus: false,
      isLoading: false,
      pageSize : 22,   
      delete_id: null,
      edit: null,
      edit_data: null,
      detail: null,
      hiddenColumnSettingId: columnHiddenSettingsId,
      hiddenColumns: hiddenColumns,
      titleValidate : false,
      isLoadingExcel : false,
      addressValidate : false,
      latitudeValidate : false,
      longitudeValidate : false,
      postal_codeValidate : false,
      openCreateDialog : false,
      title: null,
      logo: null,
      is_partner: false,
    }
  }
  componentDidMount() {
    this.callReadApi();
  }

    callReadApi(){
        let errorMessage = "";
        let status = 0;
        const { 
        REACT_APP_AUTH_API_URL
        } = process.env;
        const PATH = 'api/';
        const api = "providers";
        const URL = REACT_APP_AUTH_API_URL + PATH + api;
        let token = window.localStorage.getItem('access_token');
    
        let param = {
        page: this.state.page,
        take: this.state.limit,
        search: "",
        sorting_array: ['created_at DESC'],
        };
        this.setState({
        isLoading:true
        }, () => {
        axios.get(
                URL,
                { 
                    params: param,
                    data: param,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    }
                })
                .catch( error => {
                    try {
                        status = error.response.status;
                        this.setState({
                        errorStatus : status
                        })
                        if(this.state.errorStatus == 401) {
                        const { clearStorage } = this.context;
                        this.setState({
                            profileMenu: null,
                            isDrawerOpen: false
                        });
                        clearStorage();
                        window.location = "/";
                        } else {
                        if (error.response) {
                            // Request made and server responded
                            errorMessage = error.response.data;
                        } else if (error.request) {
                            // The request was made but no response was received
                            errorMessage = error.request;
                        } else {
                            // Something happened in setting up the request that triggered an Error
                            errorMessage = error.message;
                        }
                        }
                    } catch(err) {
                        errorMessage = 'Service Unavailable!'
                    }
                    this.setState({
                    isLoading:false
                    })
                }).then((response) => {
                // console.log(response);
                if(response != null){
                this.setState({
                    data: response.data.result,
                    total: response.data.total,
                    isLoading: false,
                });
                }
                });
        })
        
    }


    callDeleteApi(id) {
        this.setState({
            isLoading: true
        })
        let errorMessage = "";
        let status = 0;
        const {
            REACT_APP_AUTH_API_URL
        } = process.env;
        // let dataid = this.state.id;
        const PATH = 'api/';
        const api = `providers/${id}`;
        const URL = REACT_APP_AUTH_API_URL + PATH + api;
        let token = window.localStorage.getItem('access_token');

        axios.delete(
            // "https://auth-api.worknode.ai/admin/api/application_companies?search=&page=1&take=10&sorting_array[]=created_at%20DESC",
            URL,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .catch(error => {
                try {
                    status = error.response.status;

                    if (error.response) {
                        // Request made and server responded
                        errorMessage = error.response.data;
                    } else if (error.request) {
                        // The request was made but no response was received
                        errorMessage = error.request;
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        errorMessage = error.message;
                    }
                } catch (err) {
                    errorMessage = 'Service Unavailable!'
                }
            }).then((response) => {
                apiUtil.toast('Successfully deleted', 'check_circle');
               this.callReadApi();
               this.setState({
                openDeleteDialog: false,
                isLoading: false,
               })
            });
    }

    callUpdateApi(edit_data) {
        this.setState({
            isLoading: true
        })
        let errorMessage = "";
        let status = 0;
        const {
            REACT_APP_AUTH_API_URL
        } = process.env;
        // let dataid = this.state.id;
        const PATH = 'api/';
        const api = `providers/${edit_data.id}`;
        const data = {
            title: this.state.edit_data.title,
            logo: this.state.edit_data.logo,
            is_partner: this.state.edit_data.is_partner
        };
        
        const URL = REACT_APP_AUTH_API_URL + PATH + api;
        let token = window.localStorage.getItem('access_token');

        let param = {
            
        };
        axios.put(
            URL,
            data,
            {
                params: param,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            }).then((response) => {
                apiUtil.toast('Successfully updated', 'check_circle');
                this.setState({
                    openEditDialog: false,
                    isLoading: false,
                    titleValidate : false,
                })
                this.callReadApi()
            })
            .catch(error => {
                try {
                    status = error.response.status;
                    if (status != 200) {
                        error.response && error.response.data?.error != null && error.response.data?.error != '' ?
                        apiUtil.toast(error.response.data.error, 'check_circle') 
                        :
                        apiUtil.toast("unsuccessful attempt, please try again!", 'check_circle') 

                        this.setState({
                            openEditDialog: false,
                            isLoading: false,
                            titleValidate : false,
                          });
                        return
                    }
                    if (error.response) {
                        // Request made and server responded
                        errorMessage = error.response.data;
                    } else if (error.request) {
                        // The request was made but no response was received
                        errorMessage = error.request;
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        errorMessage = error.message;
                    }
                } catch (err) {
                    errorMessage = 'Service Unavailable!'
                }
            });
    }

    callCreateApi() {
  
      if (this.state.title == null || this.state.title == "") {
          this.setState({
              titleValidate:true,
          });
          return
      }

      this.setState({
          isLoading: true
      })
      let errorMessage = "";
      let status = 0;
      const {
          REACT_APP_AUTH_API_URL
      } = process.env;
      // let dataid = this.state.id;
      const PATH = 'api/';
      const api = `providers`;
      const data = {
          title: this.state.title,
          logo: this.state.logo,
          is_partner: this.state.is_partner 
      };
      
      const URL = REACT_APP_AUTH_API_URL + PATH + api;
      let token = window.localStorage.getItem('access_token');

      let param = {
          
      };
      axios.post(
          URL,
          data,
          {
              params: param,
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + token
              }
          }).then((response) => {
              apiUtil.toast('Successfully updated', 'check_circle');
              this.setState({
                  openCreateDialog: false,
                  isLoading: false,
                  titleValidate : false,
                  title: null,
                  logo: null,
                  is_partner: false
              })
              this.callReadApi()
          })
          .catch(error => {
              try {
                  status = error.response.status;
                  if (status != 200) {
                      error.response && error.response.data?.error != null && error.response.data?.error != '' ?
                      apiUtil.toast(error.response.data.error, 'check_circle') 
                      :
                      apiUtil.toast("unsuccessful attempt, please try again!", 'check_circle') 

                      this.setState({
                          openCreateDialog: false,
                          isLoading: false,
                          titleValidate : false,
                          title: null,
                          logo: null,
                          is_partner: false
                        });
                      return
                  }
                  if (error.response) {
                      // Request made and server responded
                      errorMessage = error.response.data;
                  } else if (error.request) {
                      // The request was made but no response was received
                      errorMessage = error.request;
                  } else {
                      // Something happened in setting up the request that triggered an Error
                      errorMessage = error.message;
                  }
              } catch (err) {
                  errorMessage = 'Service Unavailable!'
              }
          });
  }


  customStickyPageHeader = () => {
    return <Box clone pl={4} pr={4} height={'100%'} alignItems={'center'}>
      <Grid container>
        <BreadCrumb />
      </Grid>
    </Box>
  }

  leftPad = (number, targetLength) => {
    var output = number + '';
    while (output.length < targetLength) {
        output = '0' + output;
    }
    return output;
}

  customTable = () => {

    return <Table 
    ref={this.refTable}
    className={'job-table-list' }  
    isLoading={this.state.isLoading}
    grouping={this.state.grouping}
    limit={this.state.limit}
    pageSize= {20}
    page={this.state.page}
    total={this.state.total}
    data={this.state.data}
    onPageChange={(page,size) => {
    
      this.setState({
        page: page,
        limit: size
      }, () => { 
        this.callReadApi();
      });
    }}
    // Toolbar={this.customToolbar}
    // Row={this.customRow}
    columns={[
        { 
          title: "Action",
          field: "action",
          cellStyle: {
            minWidth: 150,
            maxWidth: 150            
          },
          render: (row, type) => {
            if(type === 'row'){
              return  <div style={{display:'flex', justifyContent:'space-evenly', alignItems:'center'}}>
               <FontAwesomeIcon icon={faTrashAlt} style={{color: 'red'}}
               onClick={()=> {
                this.setState({
                    openDeleteDialog: true,
                    delete_id: row.id,
                })
               }}
               />
               <FontAwesomeIcon icon={faEdit} style={{color: '#007cde'}}
               onClick={()=> {
                this.setState({
                    openEditDialog: true,
                    edit_data: { ...row },
                })
               }}
               />
               {/* <FontAwesomeIcon icon={faEye} style={{color: 'green'}}
                onClick={()=> {
                    this.setState({
                        openDetailDialog: true,
                        detail: { ...row },
                    })
                   }}
               /> */}
               </div>;
            } else {
              return row;
            }
          }
        },
        { 
          title: "Title", 
          field: "title",
          cellStyle: {
            minWidth: 150,
            maxWidth: 150            
          },
          render: (row, type) => {
            if(type === 'row'){
              return <span style={{overflowWrap: 'break-word', wordBreak: 'break-all', display:'inline-block'}}>{row.title}</span>;
            } else {
              return row;
            }
          }
        },
        { 
          title: "Logo", 
          field: "logo",
          cellStyle: {
            minWidth: 150,
            maxWidth: 150            
          },

          render: (row, type) => {
            if(type === 'row'){
              return <span style={{overflowWrap: 'break-word', wordBreak: 'break-all', display:'inline-block'}}>{row.logo}</span>;
            } else {
              return row;
            }
          }
        },
        { 
          title: "Is EVFY Partner", 
          field: "is_partner",
          cellStyle: {
            minWidth: 180,
            maxWidth: 180
          },
          render: (row, type) => {
            if(type === 'row'){
              return <span style={{overflowWrap: 'break-word', wordBreak: 'break-all', display:'inline-block'}}>{row.is_partner ? "YES" : "NO"}</span>;
            } else {
              return row;
            }
          }
        }
      ]}
    />
  }

        /* SUBMIT */
        handleSubmit = (e) => {
            e.preventDefault();
            e.stopPropagation();
            let formValidate = true;
        
            if (this.state.edit_data.title == null || this.state.edit_data.title == "") {
                this.setState({
                    titleValidate:true,
                });
                formValidate = false;
            }else {
                this.setState({
                    titleValidate:false,
                });
            }


            if (formValidate == true) {
                this.callUpdateApi(this.state.edit_data);
            } else {
                return
            }
              
          };
          /* END SUBMIT */

    // DIALOGS
    deleteDialog = () => {
        return <CustomDialog 
          open={this.state.openDeleteDialog}
          title={'Delete Provider'}
          maxWidth={'xs'}
          padding={'0'}
          onClose={() => {
            this.setState({
              openDeleteDialog: false,
              delete_id: null,
            });
          }}
          onOk={() => {
            this.callDeleteApi(this.state.delete_id)
          }}
        >
         <Container>
            <Row>
                <Col className="d-flex flex-column justify-content-center align-items-center">
                    <div>Are you sure you want to delete?</div>
                    <div className="p-3 my-3 rounded bg-warning text-white">Related Charging staion will be deleted</div>
                </Col>
            </Row>
         </Container>
        </CustomDialog>
      }

      editDialog = () => {
      
        return <CustomDialog 
          open={this.state.openEditDialog}
          title={'Edit Provider'}
          maxWidth={'xs'}
          padding={'0'}
          hideButtons={true}
          onClose={() => {
            this.setState({
              openEditDialog: false,
              edit_data: null,
              titleValidate : false,
            });
          }}
        >
        <form noValidate autoComplete="off" onSubmit={this.handleSubmit}>
            <Container>
                <Row className="max-xs-width-control-900 p-4">
                <Col xs={12} md={12} lg={12} className="max-xs-width-control-900 d-flex py-2">
                    <div className="max-xs-width-control-900 w-50">
                    Title
                    </div>
                    <div className="max-xs-width-control-900 ps-2 w-50">
                        <input className={`form-control ${this.state.titleValidate ? 'invalid' : ''}`} value={this.state.edit_data && this.state.edit_data.title}
                        required
                        onChange={(e)=> {
                          let edit_data = this.state.edit_data
                          edit_data.title = e.target.value
                          this.setState({
                              edit_data:edit_data
                          })
                        }}/>
                        {this.state.titleValidate && (
                        <div className="error-message">Title is required.</div>
                    )}
                    </div>
                </Col>
                <Col xs={12} md={12} lg={12} className="max-xs-width-control-900 d-flex align-items-center py-2">
                <div className="max-xs-width-control-900 w-50">
                    is EVFY Partner
                </div>
                <div className="max-xs-width-control-900 ps-2 w-50">
                  <CustomSwitch 
                  checked={this.state.edit_data && this.state.edit_data.is_partner}
                  onChange={(isChecked) => {
                    let edit_data = this.state.edit_data
                    edit_data.is_partner = isChecked
                    this.setState({
                        edit_data:edit_data
                    })
                    // this.callUpdateIsActiveApi(row, () => {
                    //   this.callReadApi(this.refTableSearch.current.state.value);
                    //   apiUtil.toast('Successfully Updated', 'check_circle');
                    // });
                  }}
                  />
                </div>
                </Col>
                    <button disabled={this.state.isLoading == true ? true: false} className="upload-style text-white mt-4" style={{margin:'auto', width:'200px'}}
                    type="submit"
                    >
                    {this.state.isLoading? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Submit' }
                    </button>
                </Row>
            </Container>
        </form>
        </CustomDialog>
      }

      createDialog = () => {
      
        return <CustomDialog 
          open={this.state.openCreateDialog}
          title={'Create New Provider'}
          maxWidth={'xs'}
          padding={'0'}
          hideButtons={true}
          onClose={() => {
            this.setState({
              openCreateDialog: false,
              title: null,
              logo: null,
              is_partner: false,
              titleValidate: false,
            });
          }}
        >
        <form noValidate autoComplete="off" onSubmit={this.handleSubmit}>
            <Container>
                <Row className="max-xs-width-control-900 p-4">
                <Col xs={12} md={12} lg={12} className="max-xs-width-control-900 d-flex py-2">
                    <div className="max-xs-width-control-900 w-50">
                    Title
                    </div>
                    <div className="max-xs-width-control-900 ps-2 w-50">
                        <input className={`form-control ${this.state.titleValidate ? 'invalid' : ''}`} value={this.state.title}
                        required
                        onChange={(e)=> {
                            this.setState({
                              title:e.target.value
                            })
                        }}/>
                        {this.state.titleValidate && (
                        <div className="error-message">Title is required.</div>
                    )}
                    </div>
                </Col>
                <Col xs={12} md={12} lg={12} className="max-xs-width-control-900 d-flex align-items-center py-2">
                <div className="max-xs-width-control-900 w-50">
                    is EVFY Partner
                </div>
                <div className="max-xs-width-control-900 ps-2 w-50">
                  <CustomSwitch 
                    checked={this.state.is_partner}
                    onChange={(isChecked) => {
                      this.setState({
                        is_partner:isChecked
                      })
                      // this.callUpdateIsActiveApi(row, () => {
                      //   this.callReadApi(this.refTableSearch.current.state.value);
                      //   apiUtil.toast('Successfully Updated', 'check_circle');
                      // });
                    }}
                  />
                </div>
                </Col>
                    <button disabled={this.state.isLoading == true ? true: false} className="upload-style text-white mt-4" style={{margin:'auto', width:'200px'}}
                    onClick={(e)=> {
                      e.preventDefault();
                      e.stopPropagation();

                      this.callCreateApi()
                    }}
                    >
                    {this.state.isLoading? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Submit' }
                    </button>
                </Row>
            </Container>
        </form>
        </CustomDialog>
      }

      // detailDialog = () => {
      //   return <CustomDialog 
      //     open={this.state.openDetailDialog}
      //     title={'Location Details'}
      //     maxWidth={'md'}
      //     padding={'0'}
      //     hideButtons={true}
      //     onClose={() => {
      //       this.setState({
      //         openDetailDialog: false,
      //         detail: null,
      //       });
      //     }}
      //   >
      //    <Container>
      //       <Row style={{display:'flex', flexDirection: 'column'}}>
      //           <Col style={{display:'flex', justifyContent:'start', alignItems:'center', width: 'inherit', padding: '10px'}}>
      //               <div style={{width: 'inherit', padding: '10px'}}><strong>Title</strong></div>
      //               <div>{this.state.detail && this.state.detail.title}</div>
      //           </Col>
      //           <Col style={{display:'flex', justifyContent:'start', alignItems:'center', width: 'inherit', padding: '10px'}}>
      //               <div style={{width: 'inherit', padding: '10px'}}><strong>Address</strong></div>
      //               <div>{this.state.detail && this.state.detail.address}</div>
      //           </Col>
      //           <Col style={{display:'flex', justifyContent:'start', alignItems:'center', width: 'inherit', padding: '10px'}}>
      //               <div style={{width: 'inherit', padding: '10px'}}><strong>No. of AC Chargers</strong></div>
      //               <div>{this.state.detail && this.state.detail.ac_chargers}</div>
      //           </Col>
      //           <Col style={{display:'flex', justifyContent:'start', alignItems:'center', width: 'inherit', padding: '10px'}}>
      //               <div style={{width: 'inherit', padding: '10px'}}><strong>No. of DC Chargers</strong></div>
      //               <div>{this.state.detail && this.state.detail.dc_chargers}</div>
      //           </Col>
      //           <Col style={{display:'flex', justifyContent:'start', alignItems:'center', width: 'inherit', padding: '10px'}}>
      //               <div style={{width: 'inherit', padding: '10px'}}><strong>Latitude</strong></div>
      //               <div>{this.state.detail && this.state.detail.latitude}</div>
      //           </Col>
      //           <Col style={{display:'flex', justifyContent:'start', alignItems:'center', width: 'inherit', padding: '10px'}}>
      //               <div style={{width: 'inherit', padding: '10px'}}><strong>Longitude</strong></div>
      //               <div>{this.state.detail && this.state.detail.longitude}</div>
      //           </Col>
      //           <Col style={{display:'flex', justifyContent:'start', alignItems:'center', width: 'inherit', padding: '10px'}}>
      //               <div style={{width: 'inherit', padding: '10px'}}><strong>Provider</strong></div>
      //               <div>{this.state.detail && this.state.detail.provider}</div>
      //           </Col>
      //           <Col style={{display:'flex', justifyContent:'start', alignItems:'center', width: 'inherit', padding: '10px'}}>
      //               <div style={{width: 'inherit', padding: '10px'}}><strong>Postal Code</strong></div>
      //               <div>{this.state.detail && this.state.detail.postal_code}</div>
      //           </Col>
      //           <Col style={{display:'flex', justifyContent:'start', alignItems:'center', width: 'inherit', padding: '10px'}}>
      //               <div style={{width: 'inherit', padding: '10px'}}><strong>Status</strong></div>
      //               <div>{this.state.detail && this.state.detail.status}</div>
      //           </Col>
      //           <Col style={{display:'flex', justifyContent:'start', alignItems:'center', width: 'inherit', padding: '10px'}}>
      //               <div style={{width: 'inherit', padding: '10px'}}><strong>Exclude</strong></div>
      //               <div>{this.state.detail && this.state.detail.exclude}</div>
      //           </Col>
      //       </Row>
      //    </Container>
      //   </CustomDialog>
      // }

        /* EXPORT API */
    callExportApi  = () => {
        const { 
        REACT_APP_AUTH_API_URL
        } = process.env;
        const PATH = 'api/';
        const api = "charging_station_export";
        const URL = REACT_APP_AUTH_API_URL + PATH + api;
        let token = window.localStorage.getItem('access_token');
    
        let param = {
        };
        this.setState({
        isLoadingExcel:true
        }, () => {
            apiUtil.exportXLS(URL, {}, token)
            .then((result) => {
              apiUtil.parseResult(result, (response) => {
                apiUtil.saveToFile(response, `charging_locations.xls`);
          
                this.setState({
                    isLoadingExcel: false,
                });
              }, (error, type) => {
                this.setState({
                    isLoadingExcel: false,
                });
              });
          });
        })
  }
  /* END EXPORT API */

  render() {
    const {stats, delivery_perday, total_delivery} = this.state
    return <Box className="dashboard-page">
      <StickyPageHeader>
        <div  className="d-flex justify-content-between align-items-center" >
            {this.customStickyPageHeader()}
            <button onClick={()=>{this.props.history.push("/charging-location")}} className="back-btn">
                back
            </button>
        </div>
      </StickyPageHeader>
      
      <Card className={'job-listing-step'}>
            <button 
            disabled={this.state.isLoadingExcel ? true : false}
            onClick={()=>{
                this.setState({
                  openCreateDialog: true
                })
            }} className="back-btn p-2 mb-2">
                {this.state.isLoadingExcel ? 'Loading ...' : 'Create New'}
            </button>
        {this.customTable()}
      </Card>
      {this.deleteDialog()}
      {this.editDialog()}
      {this.createDialog()}
      {/* {this.detailDialog()} */}
    </Box>;
  }
}

export default Provider;
