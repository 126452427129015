import React, { Component } from 'react';
import BreadCrumb from "../../components/Navs/Breadcrumb"
import {
    Box,
    Grid,
    InputLabel,
    Table,
    Button,
    FormGroup,
    FormLabel,
    Checkbox,
    CircularProgress,
} from '@material-ui/core';
import Card from '../../components/Card/Card.jsx';
import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import axios from 'axios';
import apiUtil from "../../api/apiUtil.jsx";
import authApi from "../../api/api.jsx";
import CustomDialog from '../../components/Dialog/CustomDialog.jsx';

class DetailPromotion extends Component {
  static contextType = AuthContext;

    constructor(props) {
        super(props);

        this.refTable = React.createRef();
    this.refTableSearch = React.createRef();

    let id = (this.props.location && this.props.location.state && this.props.location.state.id && this.props.location.state.id > 0) ? this.props.location.state.id : 0;


    this.state = {
      data: [],
      rule_data: [],
      isLoadingStatus: false,
      page: 1,
      errorStatus:'',
      total: 2,
      limit: 10,
      isLoading: false,
      pageSize: 22,      // status: [],
      id: id,
      rule_list: [],
      name: null,
      application_companies: [],
      created_at: null,
      description: null,
      end_date: null,
      id: id,
      is_active: null,
      is_enable: false,
      is_for_companies: false,
      promo_rules: [],
      start_date: null,
      total_rate: null,
      updated_at: null,
      openDialog: false,
      openPromotionDialog: false,
      openPromotionDialogItem: null,
    }
    }
   
    componentDidMount() {

        this.callRuleReadApi();

        // if(this.state.id > 0) {
        //   this.callReadApi();
        // }
      }
    
      callReadApi() {
    
        let errorMessage = "";
        let status = 0;
        const {
          REACT_APP_AUTH_API_URL
        } = process.env;
        let dataid = this.state.id;
        const PATH = 'admin/api/';
        const api = `promotions/${dataid}`;
        const URL = REACT_APP_AUTH_API_URL + PATH + api;
        let token = window.localStorage.getItem('access_token');
    
        let param = {
          // application_company_id: dataid,
          // take: this.state.limit,
          // search: "",
          // start_date: startDate,
          // end_date: endDate,
          // sorting_array: ['created_at DESC'],
        };
        this.setState({
          isLoading:true
        } )
        axios.get(
          // "https://auth-api.worknode.ai/admin/api/application_companies?search=&page=1&take=10&sorting_array[]=created_at%20DESC",
          URL,
          {
            params: param,
            // data: param,
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token
            }
          })
          .catch(error => {
            try {
              status = error.response.status;
              this.setState({
                errorStatus : status
              })
              if(this.state.errorStatus == 401) {
                const { clearStorage } = this.context;
                this.setState({
                  profileMenu: null,
                  isDrawerOpen: false
                });
                clearStorage();
                window.location = "/";
              } else {
                if (error.response) {
                  // Request made and server responded
                  errorMessage = error.response.data;
                } else if (error.request) {
                  // The request was made but no response was received
                  errorMessage = error.request;
                } else {
                  // Something happened in setting up the request that triggered an Error
                  errorMessage = error.message;
                }
              }
            } catch (err) {
              errorMessage = 'Service Unavailable!'
            }
            this.setState({
              isLoading:false
            })
          }).then((response) => {
            if (response != null) {
    
              var json_data = response.data.result;
              var result = [];
              for (var i in json_data) {
                result[i] = json_data[i];
              }
              
              let rule_data =  this.state.rule_data ? this.state.rule_data : []
              let rule_list = []
              if(result.promo_rules.length > 0) {
                rule_list = result.promo_rules
                
                rule_list.map(data => {
                  rule_data.map(d => {
                    if(data.code == d.code) {
                      let index = rule_data.indexOf(d);
                      if (index !== -1) {
                        rule_data.splice(index, 1);
                      }
                      return
                    }
                  })
                  
                })
              }

              this.setState({
                // data: response.data.result,
                total: response.data.total,
                isLoading: false,
                data: result,
                name: result.name? result.name : this.state.name,
                description: result.description? result.description: this.state.description,
                start_date: result.start_date? result.start_date: this.state.start_date,
                end_date: result.end_date ? result.end_date : this.state.end_date,
                application_companies: result.application_companies ? result.application_companies : this.state.application_companies,
                is_active: result.is_active ? result.is_active : this.state.is_active,
                is_enable: result.is_enable ? result.is_enable : this.state.is_enable,
                is_for_companies: result.is_for_companies ? result.is_for_companies : this.state.is_for_companies,
                rule_data: rule_data,
                total_rate: result.total_rate ? result.total_rate : this.state.total_rate,
                rule_list: rule_list
              });
    
            }
    
          });
      }

      callRuleReadApi() {
    
        let errorMessage = "";
        let status = 0;
        const {
          REACT_APP_AUTH_API_URL
        } = process.env;
        let dataid = this.state.id;
        const PATH = 'admin/api/';
        const api = `promo_rules/`;
        const URL = REACT_APP_AUTH_API_URL + PATH + api;
        let token = window.localStorage.getItem('access_token');
    
        let param = {
            is_default: true,
          // take: this.state.limit,
          // search: "",
          // start_date: startDate,
          // end_date: endDate,
          // sorting_array: ['created_at DESC'],
        };
        if(this.state.id > 0)  {
          this.callReadApi()
        }
        this.setState({
          isLoading:true
        }, ()=>{
          axios.get(
          // "https://auth-api.worknode.ai/admin/api/application_companies?search=&page=1&take=10&sorting_array[]=created_at%20DESC",
          URL,
          {
            params: param,
            // data: param,
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token
            }
          })
          .catch(error => {
            try {
              status = error.response.status;
    
              if (error.response) {
                // Request made and server responded
                errorMessage = error.response.data;
              } else if (error.request) {
                // The request was made but no response was received
                errorMessage = error.request;
              } else {
                // Something happened in setting up the request that triggered an Error
                errorMessage = error.message;
              }
            } catch (err) {
              errorMessage = 'Service Unavailable!'
            }
            this.setState({
              isLoading:false
            })
          }).then((response) => {
            if (response != null) {

              let rule_data = response.data.result  
              let rule_list = this.state.rule_list ? this.state.rule_list : []
              if(rule_list.length > 0) {
                // rule_list = result.promo_rules
                
                rule_list.map(data => {
                  rule_data.map(d => {
                    if(data.code == d.code) {
                      let index = rule_data.indexOf(d);
                      if (index !== -1) {
                        rule_data.splice(index, 1);
                      }
                      return
                    }
                  })
                  
                })
              }

              this.setState({
                rule_data: rule_data,
                total: response.data.total,
                isLoading: false,
               
            
              });
    
            }
    
          });
        })
        
      }

      callfreeAccountputApi = (id, action , callback = null) => {

        let errorMessage = "";
        let status = 0;
        const {
          REACT_APP_AUTH_API_URL
        } = process.env;
        const PATH = 'admin/api/';
        const api = `free_accounts/${id}`;
        const param = {
          application_company_id: this.state.id,
          company_action: action,
        };
    
        const URL = REACT_APP_AUTH_API_URL + PATH + api;
        let token = window.localStorage.getItem('access_token');
    
        axios.put(
          // "https://auth-api.worknode.ai/admin/api/application_companies?search=&page=1&take=10&sorting_array[]=created_at%20DESC",
          URL,
          param,
          {
            
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token
            }
          })
          .catch(error => {
            try {
              status = error.response.status;
    
              if (error.response) {
                // Request made and server responded
                errorMessage = error.response.data;
              } else if (error.request) {
                // The request was made but no response was received
                errorMessage = error.request;
              } else {
                // Something happened in setting up the request that triggered an Error
                errorMessage = error.message;
              }
            } catch (err) {
              errorMessage = 'Service Unavailable!'
            }
          }).then((response) => {
            if(callback) {
              callback()
            }
            this.callReadApi();
            
          });
      }
      callPromotionPutApi = (id, action, callback = null) => {

        let errorMessage = "";
        let status = 0;
        const {
          REACT_APP_AUTH_API_URL
        } = process.env;
        const PATH = 'admin/api/';
        const api = `promotions/${this.state.id}`;
        const param = {
          application_company_id: id,
          company_action: action,
        };
    
        const URL = REACT_APP_AUTH_API_URL + PATH + api;
        let token = window.localStorage.getItem('access_token');
    
        axios.put(
          // "https://auth-api.worknode.ai/admin/api/application_companies?search=&page=1&take=10&sorting_array[]=created_at%20DESC",
          URL,
          param,
          {
            
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token
            }
          })
          .catch(error => {
            try {
              status = error.response.status;
    
              if (error.response) {
                // Request made and server responded
                errorMessage = error.response.data;
              } else if (error.request) {
                // The request was made but no response was received
                errorMessage = error.request;
              } else {
                // Something happened in setting up the request that triggered an Error
                errorMessage = error.message;
              }
            } catch (err) {
              errorMessage = 'Service Unavailable!'
            }
          }).then((response) => {
            if(callback) {
              callback()
            }

            this.callRuleReadApi();
          });
      }

    customStickyPageHeader = () => {
        return <Box clone pl={4} pr={4} height={'100%'} alignItems={'center'}>
            <Grid container>
                <BreadCrumb />
            </Grid>
        </Box>
    }

    submitForm = () => {
      const accessToken = window.localStorage.getItem('access_token');

      let row = {
        application_companies: this.state.application_companies,
        description: this.state.description,
        end_date: this.state.end_date,
        is_active: null,
        is_enable: this.state.is_enable,
        is_for_companies: this.state.is_for_companies,
        name: this.state.name,
        promo_rules: this.state.rule_list,
        start_date: this.state.start_date,
        total_rate: this.state.total_rate
      }
  
      this.setState({
        isLoading: true
      }, () => {
        if(this.state.id > 0) {

          authApi.update('promotions/' + this.state.id, row, accessToken)
          .then((result) => {
            apiUtil.parseResult(result, (data) => {
              this.setState({
                isLoading: false,
              }, () => {
                
                this.props.history.push('/promotion');
                apiUtil.toast('Successfully Updated', 'check_circle');
              });
            }, (error, type) => {
              this.setState({
                isLoading: false,
              }, () => {
                // if(callback){
                //   callback();
                // }
              });
            });
        });

        } else {
          authApi.create('promotions', row, accessToken)
          .then((result) => {
            apiUtil.parseResult(result, (data) => {
              this.setState({
                isLoading: false,
              }, () => {
                
                this.props.history.push('/promotion');
                apiUtil.toast('Successfully Created', 'check_circle');
              });
            }, (error, type) => {
              this.setState({
                isLoading: false,
              }, () => {
                // if(callback){
                //   callback();
                // }
              });
            });
        });
        }
        
      });
    }

    callDetltePromotionApi = () => {

      const accessToken = window.localStorage.getItem('access_token');

      this.setState({
        isLoading: true
      }, () => {
      authApi.delete('promotions/' + this.state.id, null, accessToken)
          .then((result) => {
            apiUtil.parseResult(result, (data) => {
              this.setState({
                isLoading: false,
              }, () => {
                
                this.props.history.push('/promotion');
                apiUtil.toast('Successfully Deleted', 'check_circle');
              });
            }, (error, type) => {
              this.setState({
                isLoading: false,
              });
            });
        });
      });
    }

    customDialog = () => {
      return <CustomDialog 
        open={this.state.openDialog}
        title={'Delete Promotion'}
        onClose={() => {
          this.setState({
            openDialog: false,
          });
        }}
        onOk={() => {
          
          this.callDetltePromotionApi()
          
        }}
      >
        <Box>Are you sure you want to delete this promotion?</Box>
      </CustomDialog>
    }

    customPromotionDialog = () => {
      return <CustomDialog 
        open={this.state.openPromotionDialog}
        title={'Remove'}
        onClose={() => {
          this.setState({
            openPromotionDialog: false,
            openPromotionDialogItem: null,
          });
        }}
        onOk={() => {
          let data = this.state.openPromotionDialogItem;
          if(data && data.type == 'freeAccount') {
            // this.callfreeAccountputApi(data.id, 'remove')
            this.callfreeAccountputApi(data.id, 'remove', () => {
              this.setState({
                openPromotionDialog: false,
                openPromotionDialogItem: null,
              }, () => {
                // this.callReadApi(this.refTableSearch.current.state.value);
                apiUtil.toast('Successfully remove', 'check_circle');
              });
            });
          } else if(data && data.type == 'promotion') {
            // this.callPromotionPutApi(data.id, 'remove')
            this.callPromotionPutApi(data.id, 'remove', () => {
              this.setState({
                openPromotionDialog: false,
                openPromotionDialogItem: null,
              }, () => {
                // this.callReadApi(this.refTableSearch.current.state.value);
                apiUtil.toast('Successfully remove', 'check_circle');
              });
            });
          }
          
        }}
      >
        <Box>Are you sure you want to remove?</Box>
      </CustomDialog>
    }

    promotion() {
        // var created_at = new Date(this.state.data.created_at);
        // var updated_at = new Date(this.state.data.updated_at);
        var created_at = this.state.data.created_at ?  new Date(this.state.data.created_at) : "";
        var updated_at = this.state.data.updated_at ? new Date(this.state.data.updated_at) : "";
        return <Box>
            <FormGroup>
                <Grid container spacing={2} style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                    <Grid item xs={4}>
                        <FormLabel>ID </FormLabel>
                        <FormLabel> {this.state.id ? this.state.id : ''}</FormLabel>
                    </Grid>
                    <Grid item xs={4}>
                        <FormLabel>Created Date : </FormLabel>
                        <FormLabel>{created_at? created_at.toLocaleDateString(): ''}</FormLabel>
                    </Grid>
                    <Grid item xs={4}>
                        <FormLabel>Updated Date :</FormLabel>
                        <FormLabel>{updated_at? updated_at.toLocaleDateString(): ''}</FormLabel>
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                    <Grid item xs={4}>
                        <InputLabel style={{ paddingBottom: '10px' }}>Promotion Name : </InputLabel>
                        <input type="text" 
                        value={this.state.name }
                        style={{ height: '35px', width: '100%', border: '1px solid #DDDDDD' }}
                        onChange={(e)=> {
                          this.setState({
                            name: e.target.value
                          })
                        }} />
                    </Grid>
                    <Grid item xs={4}>
                        <InputLabel style={{ paddingBottom: '10px' }}>Total Rate (%): </InputLabel>
                        <input type="text" 
                        value={this.state.total_rate} 
                        style={{ height: '35px', width: '100%', border: '1px solid #DDDDDD' }} 
                        onChange={(e)=> {
                          this.setState({
                            total_rate:e.target.value
                          })
                        }}/>
                    </Grid>
                    <Grid item xs={4}>
                        <InputLabel style={{ paddingBottom: '10px' }}>
                        <Checkbox 
                        checked={this.state.is_enable}
                        onChange={(checked)=> {
                          this.setState({
                            is_enable:!this.state.is_enable
                          })
                        }}
                        />Enable </InputLabel>
                        {/* <input type="text" value={this.state.data.is_enable? this.state.data.is_enable: false } 
                        style={{ height: '35px', width: '100%', border: '1px solid #DDDDDD' }} 
                        onChange={(e)=> {
                          this.setState({
                            is_enable:!this.state.is_enable
                          })
                        }}
                        /> */}
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                    <Grid item xs={12}>
                        <InputLabel style={{ paddingBottom: '10px' }}>Discription: </InputLabel>
                        <textarea type="text" value={this.state.description} 
                        rows="5" 
                        style={{ height: '35px', width: '100%', border: '1px solid #DDDDDD' }}
                        onChange={(e)=> {
                          this.setState({
                            description:e.target.value
                          })
                        }}
                        ></textarea>
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                    <Grid item xs={4}>
                        <InputLabel style={{ paddingBottom: '10px' }}>Billing Start: </InputLabel>
                        <input type="date" value={this.state.start_date } 
                        style={{ height: '35px', width: '100%', border: '1px solid #DDDDDD' }}
                        onChange={(e)=> {
                          this.setState({
                            start_date:e.target.value
                          })
                        }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <InputLabel style={{ paddingBottom: '10px' }}>Billing End: </InputLabel>
                        <input type="date" value={this.state.end_date } 
                        style={{ height: '35px', width: '100%', border: '1px solid #DDDDDD' }} 
                        onChange={(e)=> {
                          this.setState({
                            end_date:e.target.value
                          })
                        }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                    <Grid item xs={6}>
                        <InputLabel><Checkbox 
                        checked={this.state.is_for_companies }
                        onChange={(checked)=> {
                          this.setState({
                            is_for_companies:!this.state.is_for_companies
                          })
                        }}
                        />is For Specific Companies</InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      { this.state.id > 0 && 
                        <Button 
                        style={{backgroundColor:'#FF4949', float: 'right', borderRadius:'5px'}}
                        onClick={()=>{  
                          this.setState({
                            openDialog: true
                          })

                        }}
                        >
                          <span style={{color:'#FFFFFF'}}>Delete This Promotion</span>
                        </Button>
                      }
                    </Grid>
                </Grid>

            </FormGroup>
        </Box>
    }

    promotionRule() {
        return <Box>
            <h1>Promotion Rules</h1>
            <div>
                {this.state.rule_data.map((data) => 
                
                <>
                 <Button style={{backgroundColor:'#2e86aa', marginRight:'5px'}}
                  // disabled={false}
                  onClick={() => {
                    this.showRuleForm(data.code);
                }}
                 >
                 <span  style={{color:'#FFFFFF'}}>{data.name}</span>
                </Button>
                
                </>
                )
                }
            </div>
            
        </Box>
    }

    showRuleForm = (code) => {
      let rule_list = this.state.rule_list ? this.state.rule_list : []
      let rule_data =  this.state.rule_data ? this.state.rule_data : []
      rule_data.map(data => {
        if(data.code == code) {
          rule_list.push(data)
          let index = rule_data.indexOf(data);
          if (index !== -1) {
            rule_data.splice(index, 1);
          }
          return
        }
      })
      // let rule_listId = []
      // console.lo
      
      this.setState({
        rule_list : rule_list,
        rule_data : rule_data
      })

    }

    deleteRuleList = (code) => {
      let rule_list = this.state.rule_list ? this.state.rule_list : []
      let rule_data =  this.state.rule_data ? this.state.rule_data : []
      rule_list.map(data => {
        if(data.code == code) {
          rule_data.push(data)
          let index = rule_list.indexOf(data);
          if (index !== -1) {
            rule_list.splice(index, 1);
          }
          return
        }
      })
      // let rule_listId = []
      // console.lo
      
      this.setState({
        rule_list : rule_list,
        rule_data : rule_data
      })
    }

    ruleFormList = () => {
      let rule_list = this.state.rule_list ? this.state.rule_list : []
      if(rule_list.length > 0) {
        return <Box>
          {rule_list.map((data) =>
         
          <Card className={'job-listing-step'}>
            { console.log("data",data)}
          <Grid container spacing={2} style={{ paddingTop: '10px', paddingBottom: '10px' }}>
              <Grid item xs={6}>
                  <InputLabel style={{ padding: '10px', }}>Rule Code = [{data.code}] </InputLabel>
              </Grid>
              <Grid item xs={6}>
                  <Button 
                  style={{backgroundColor:'#FF4949', float: 'right', borderRadius:'5px'}}
                  onClick={()=>{  
                    this.deleteRuleList(data.code)

                  }}
                  >
                    <span style={{color:'#FFFFFF'}}>Delete</span>
                  </Button>
              </Grid>
          </Grid>
          <Grid container spacing={2} style={{ paddingTop: '10px', paddingBottom: '10px' }} className="rule-label">
              <Grid item xs={2}>
                <InputLabel style={{ paddingBottom: '10px' }}>Rule Name </InputLabel>
              </Grid>
              <Grid item xs={8}>
                <input type="text" style={{ height: '35px', width: '100%', border: '1px solid #DDDDDD' }}
                onChange={(e)=> {
                  let rule_list = this.state.rule_list
                  rule_list.map(d => {
                    if(d.id == data.id){
                      d.name = e.target.value
                      return
                    }
                  })
                  this.setState({
                    rule_list
                  })
                }}
                value={data.name}
                />
              </Grid>
          </Grid>
          <Grid container spacing={2} style={{ paddingTop: '10px', paddingBottom: '10px' }} className="rule-label">
              <Grid item xs={2}>
                <InputLabel style={{ paddingBottom: '10px' }}>Rule Description </InputLabel>
              </Grid>
              <Grid item xs={8}>
                <input type="text" style={{ height: '35px', width: '100%', border: '1px solid #DDDDDD' }}
                onChange={(e)=> {
                  let rule_list = this.state.rule_list
                  rule_list.map(d => {
                    if(d.id == data.id){
                      d.description = e.target.value
                      return
                    }
                  })
                  this.setState({
                    rule_list
                  })
                }}
                value={data.description}
                />
              </Grid>
          </Grid>
          <Grid container spacing={2} style={{ paddingTop: '10px', paddingBottom: '10px' }} className="rule-label">
              <Grid item xs={2}>
                <InputLabel style={{ paddingBottom: '10px' }}>{data.is_condition ? 'Rule Condition' : 'Total Apply' }</InputLabel>
              </Grid>
              <Grid item xs={8}>
                <input type="text" style={{ height: '35px', width: '100%', border: '1px solid #DDDDDD' }}
                onChange={(e)=> {
                  let rule_list = this.state.rule_list
                  rule_list.map(d => {
                    if(d.id == data.id){
                      if(data.is_condition) {
                        d.condition = e.target.value                        
                      } else {
                        d.total = e.target.value
                      }                      
                      return
                    }
                  })
                  this.setState({
                    rule_list
                  })
                }}
                value={data.is_condition ? data.condition : data.total}
                />
              </Grid>
          </Grid>
        </Card>
          )}
        </Box>
      }
      return <Box>
      </Box>
    }

    companies() {
        return <>
            <h1>Companies</h1>
            
            {this.state.application_companies.length > 0 ?
          <Table >
            <tr style={{ backgroundColor: "#565553", height: "50px" }}>
              <th style={{ color: "#FFFFFF" }}>ID</th>
              <th style={{ color: "#FFFFFF" }}>Company Name</th>
              <th style={{ color: "#FFFFFF" }}>Email</th>
              {/* <th style={{ color: "#FFFFFF" }}>End Date</th> */}
              <th style={{ color: "#FFFFFF" }}>Actions</th>
            </tr>
            {this.state.application_companies.map((data) =>
              <tr style={{ textAlign: "center", height: "50px" }}>
                <td>{data.id}</td>
                <td>{data.company_name}</td>
                <td>{data.email}</td>
                {/* <td>{data.end_date}</td> */}
                <td> <Button style={{ backgroundColor: '#FF4500' }}
                  onClick={() => {
                    
                    this.setState({
                      openPromotionDialog: true,
                      openPromotionDialogItem: {
                        id:data.id,
                        type:'promotion'
                      }
                    })
                    // this.callPromotionPutApi(data.id, 'remove')
                    
                  }}
                >Remove</Button>
                </td>
              </tr>
            )}
          </Table> : "no company list"
        }
        </>
    }

    render() {
        if(this.state.isLoading ) {
          return <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'55vh'}>
                <CircularProgress size={35} />
            </Box>
            
        }
        return <Box className="dashboard-page" >

            <Card className={'job-listing-step'}>
                {this.promotion()}
            </Card>

            <Card className={'job-listing-step'}>
                {this.state.isLoading ? <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'55vh'}>
                <CircularProgress size={35} />
            </Box>: this.promotionRule() }
                {/* {this.promotionRule()} */}
            </Card>

            {this.ruleFormList()}
            <Card className={'job-listing-step'}>
            <Button style={{backgroundColor:'#2e86aa', marginRight:'5px'}}
                  onClick={() => {
                    this.submitForm();
                }}
                 >
                 <span  style={{color:'#FFFFFF'}}>Save</span>
            </Button>
            </Card>
            <Card className={'job-listing-step'}>
                {this.companies()}
            </Card>
            {this.customDialog()}
            {this.customPromotionDialog()}
        </Box>;
    }
}

export default DetailPromotion;
