import React, { Component } from 'react';
import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import axios from 'axios';
import apiUtil from "../../api/apiUtil.jsx";
import { withRouter, Link } from 'react-router-dom';
import { Container, Row, Col, Button, Card, Modal } from 'react-bootstrap';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import ChargingTemplate from "../../assets/files/ChargingStations.xlsx"
import { saveAs } from "file-saver";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpFromBracket, faDownload, faFileLines } from '@fortawesome/free-solid-svg-icons'
import CustomDialog from '../../components/Dialog/CustomDialog.jsx';
import ImportDialog from './ImportantDialog.jsx';
import CustomButton from '../../components/CustomFields/CustomButton.jsx';
import CustomInput from "../../components/CustomFields/CustomInput.jsx";
import ReactJson from 'react-json-view'

class ManualLocation extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);

    this.refTable = React.createRef();
    let sp_sample_data = [
      {
        "id": 1306,
        "title": "Changi Airport Terminal 3",
        "address": "65 Airport Boulevard, Singapore 819663",
        "latitude": "1.3564573010308",
        "longitude": "103.9865063972240",
        "ac_charging": 6,
        "dc_charging": 0,
        "free_parking": 0,
        "free_parking_details": null
      },
      {
        "id": 1305,
        "title": "Asia Green",
        "address": "7 Tampines Grande, Singapore 528736",
        "latitude": "1.3562110282663",
        "longitude": "103.9416687460270",
        "ac_charging": 3,
        "dc_charging": 0,
        "free_parking": 0,
        "free_parking_details": null
      }]

    let bluecharge_sample_data = [
      {
        "id": "angmokio-angmokioav4-blk157",
        "name": "Ang Mo Kio/Ang Mo Kio Av 4/Blk 157",
        "emi3": "SG*TCB*P000001",
        "status": "AVAILABLE",
        "address": {
            "city": "SINGAPORE",
            "street": "Ang Mo Kio Avenue 4",
            "streetNumber": "157",
            "zipCode": "560157"
        },
        "coordinates": {
            "latitude": 1.375145,
            "longitude": 103.83908
        },
        "publicLocation": true,
        "chargingSpots": [
            {
                "id": "0f3aeb75-ccd5-52d3-9775-63d95135c794",
                "physicalRef": "Charger 1 - Left",
                "status": "AVAILABLE",
                "chargingSpotMaxPower": 3,
                "chargingSpotMaxAvailablePower": 3,
                "evses": [
                    {
                        "id": "angmokio-angmokioav4-blk157-1-1",
                        "emi3": "SG*TCB*E000001",
                        "name": "Ang Mo Kio/Ang Mo Kio Av 4/Blk 157/1L",
                        "status": "AVAILABLE",
                        "connectors": [
                            {
                                "id": 1,
                                "type": "T2"
                            }
                        ],
                        "maxPower": 3,
                        "serviceGroup": "SG*TCB_SG_C",
                        "serviceStatus": "IN_SERVICE",
                        "products": [
                            {
                                "productKey": "22990584240",
                                "subEmsp": "",
                                "price": {
                                    "currency": "SGD"
                                },
                                "name": "TASI DC charging",
                                "type": "CHARGE",
                                "preAuthorization": {
                                    "amount": 49,
                                    "nonTaxedAmount": 0,
                                    "vatRate": 0,
                                    "currency": "SGD"
                                },
                                "chargePointId": "angmokio-angmokioav4-blk157-1-1",
                                "connectorId": 1,
                                "priceDescriptions": [
                                    "Charging rate: 0.57 S$ / kWh (incl. VAT) "
                                ]
                            },
                            {
                                "productKey": "23340477598",
                                "subEmsp": "",
                                "price": {
                                    "amount": 2,
                                    "currency": "SGD"
                                },
                                "name": "Réservation",
                                "type": "RESERVATION",
                                "chargePointId": "angmokio-angmokioav4-blk157-1-1",
                                "connectorId": 1,
                                "priceDescriptions": [
                                    "S$ 2 (all tax incl.)"
                                ],
                                "evseId": 1,
                                "reservationDuration": 2700
                            }
                        ],
                        "capabilities": [
                            "REMOTE_START_STOP_CAPABLE",
                            "RESERVABLE"
                        ],
                        "vendorId": "TSGP"
                    }
                ]
            },
            {
                "id": "4417a877-4b1f-5ced-acba-8690325bff32",
                "physicalRef": "Charger 1 - Right",
                "status": "AVAILABLE",
                "chargingSpotMaxPower": 3,
                "chargingSpotMaxAvailablePower": 3,
                "evses": [
                    {
                        "id": "angmokio-angmokioav4-blk157-1-2",
                        "emi3": "SG*TCB*E000002",
                        "name": "Ang Mo Kio/Ang Mo Kio Av 4/Blk 157/1R",
                        "status": "AVAILABLE",
                        "connectors": [
                            {
                                "id": 2,
                                "type": "T2"
                            }
                        ],
                        "maxPower": 3,
                        "serviceGroup": "SG*TCB_SG_C",
                        "serviceStatus": "IN_SERVICE",
                        "products": [
                            {
                                "productKey": "22990584240",
                                "subEmsp": "",
                                "price": {
                                    "currency": "SGD"
                                },
                                "name": "TASI DC charging",
                                "type": "CHARGE",
                                "preAuthorization": {
                                    "amount": 49,
                                    "nonTaxedAmount": 0,
                                    "vatRate": 0,
                                    "currency": "SGD"
                                },
                                "chargePointId": "angmokio-angmokioav4-blk157-1-2",
                                "connectorId": 2,
                                "priceDescriptions": [
                                    "Charging rate: 0.57 S$ / kWh (incl. VAT) "
                                ]
                            },
                            {
                                "productKey": "23340477598",
                                "subEmsp": "",
                                "price": {
                                    "amount": 2,
                                    "currency": "SGD"
                                },
                                "name": "Réservation",
                                "type": "RESERVATION",
                                "chargePointId": "angmokio-angmokioav4-blk157-1-2",
                                "connectorId": 2,
                                "priceDescriptions": [
                                    "S$ 2 (all tax incl.)"
                                ],
                                "evseId": 1,
                                "reservationDuration": 2700
                            }
                        ],
                        "capabilities": [
                            "REMOTE_START_STOP_CAPABLE",
                            "RESERVABLE"
                        ],
                        "vendorId": "TSGP"
                    }
                ]
            },
            {
                "id": "661759ad-785f-5a48-a0e1-00386cfa78bc",
                "physicalRef": "Charger 2 - Left",
                "status": "AVAILABLE",
                "chargingSpotMaxPower": 3,
                "chargingSpotMaxAvailablePower": 3,
                "evses": [
                    {
                        "id": "angmokio-angmokioav4-blk157-2-1",
                        "emi3": "SG*TCB*E000003",
                        "name": "Ang Mo Kio/Ang Mo Kio Av 4/Blk 157/2L",
                        "status": "AVAILABLE",
                        "connectors": [
                            {
                                "id": 1,
                                "type": "T2"
                            }
                        ],
                        "maxPower": 3,
                        "serviceGroup": "SG*TCB_SG_C",
                        "serviceStatus": "IN_SERVICE",
                        "products": [
                            {
                                "productKey": "22990584240",
                                "subEmsp": "",
                                "price": {
                                    "currency": "SGD"
                                },
                                "name": "TASI DC charging",
                                "type": "CHARGE",
                                "preAuthorization": {
                                    "amount": 49,
                                    "nonTaxedAmount": 0,
                                    "vatRate": 0,
                                    "currency": "SGD"
                                },
                                "chargePointId": "angmokio-angmokioav4-blk157-2-1",
                                "connectorId": 1,
                                "priceDescriptions": [
                                    "Charging rate: 0.57 S$ / kWh (incl. VAT) "
                                ]
                            },
                            {
                                "productKey": "23340477598",
                                "subEmsp": "",
                                "price": {
                                    "amount": 2,
                                    "currency": "SGD"
                                },
                                "name": "Réservation",
                                "type": "RESERVATION",
                                "chargePointId": "angmokio-angmokioav4-blk157-2-1",
                                "connectorId": 1,
                                "priceDescriptions": [
                                    "S$ 2 (all tax incl.)"
                                ],
                                "evseId": 1,
                                "reservationDuration": 2700
                            }
                        ],
                        "capabilities": [
                            "REMOTE_START_STOP_CAPABLE",
                            "RESERVABLE"
                        ],
                        "vendorId": "TSGP"
                    }
                ]
            },
            {
                "id": "70a2a28c-822d-58f4-a541-e769d95c772c",
                "physicalRef": "Charger 2 - Right",
                "status": "OCCUPIED",
                "chargingSpotMaxPower": 3,
                "chargingSpotMaxAvailablePower": 0,
                "evses": [
                    {
                        "id": "angmokio-angmokioav4-blk157-2-2",
                        "emi3": "SG*TCB*E000004",
                        "name": "Ang Mo Kio/Ang Mo Kio Av 4/Blk 157/2R",
                        "status": "OCCUPIED",
                        "connectors": [
                            {
                                "id": 2,
                                "type": "T2"
                            }
                        ],
                        "maxPower": 3,
                        "serviceGroup": "SG*TCB_SG_C",
                        "serviceStatus": "IN_SERVICE",
                        "products": [
                            {
                                "productKey": "22990584240",
                                "subEmsp": "",
                                "price": {
                                    "currency": "SGD"
                                },
                                "name": "TASI DC charging",
                                "type": "CHARGE",
                                "preAuthorization": {
                                    "amount": 49,
                                    "nonTaxedAmount": 0,
                                    "vatRate": 0,
                                    "currency": "SGD"
                                },
                                "chargePointId": "angmokio-angmokioav4-blk157-2-2",
                                "connectorId": 2,
                                "priceDescriptions": [
                                    "Charging rate: 0.57 S$ / kWh (incl. VAT) "
                                ]
                            },
                            {
                                "productKey": "23340477598",
                                "subEmsp": "",
                                "price": {
                                    "amount": 2,
                                    "currency": "SGD"
                                },
                                "name": "Réservation",
                                "type": "RESERVATION",
                                "chargePointId": "angmokio-angmokioav4-blk157-2-2",
                                "connectorId": 2,
                                "priceDescriptions": [
                                    "S$ 2 (all tax incl.)"
                                ],
                                "evseId": 1,
                                "reservationDuration": 2700
                            }
                        ],
                        "capabilities": [
                            "REMOTE_START_STOP_CAPABLE",
                            "RESERVABLE"
                        ],
                        "vendorId": "TSGP"
                    }
                ]
            }
        ],
        "locationMaxPower": 3,
        "locationMaxAvailablePower": 3,
        "openingTimes": {
            "universalTextualRepresentation": "Mo-Su 00:00-24:00",
            "twentyfourseven": true,
            "regularHours": [],
            "exceptionalOpenings": [],
            "exceptionalClosings": []
        },
        "suboperator": {},
        "facilities": [],
        "cpoId": "bluecharge_singapore",
        "vendorId": "TSGP",
        "supportedConnectors": [
            "T2"
        ],
        "availableChargingSpotsCount": 3,
        "canSupportFastCharge": false,
        "canSupportPRM": false,
        "canSupportMotorcycle": false
      }]

      let shell_sample_data = [
        {
          "id": "10000048",
          "type": 0,
          "brand": "shell",
          "name": "SHELL JURONG WEST AVE5 611_01",
          "lat": 1.350024,
          "lng": 103.701209,
          "country_code": "SG",
          "address": "21 JURONG WEST AVENUE 5",
          "city": "SINGAPORE",
          "state": "Singapore",
          "postcode": "649481",
          "telephone": "+65 6258 8380",
          "country": "Singapore",
          "amenities": [
            "standard_toilet",
            "carwash",
            "loyalty_cards",
            "credit_card",
            "fuel_card",
            "credit_card_general",
            "full_service",
            "fleet_card_general",
            "twenty_four_hour",
            "manual",
            "selectshop",
            "loyalty_program",
            "other_loyalty_cards",
            "air_and_water",
            "manned"
          ],
          "inactive": false,
          "distance": 1.23588934056,
          "driving_distance": null,
          "driving_duration": null,
          "website_url": "https://find.shell.com/sg/fuel/10000048-shell-jurong-west-ave5-611_01/en_SG",
          "mdm_site_category": "Conventional Fuel Site",
          "formatted_address": "21 JURONG WEST AVENUE 5\nSINGAPORE, Singapore, 649481",
          "site_category": "conventional_fuel_site"
        },
        {
          "id": "10000002",
          "type": 0,
          "brand": "shell",
          "name": "SHELL BOON LAY 616_01",
          "lat": 1.343964,
          "lng": 103.707837,
          "country_code": "SG",
          "address": "2 BOON LAY AVENUE",
          "city": "SINGAPORE",
          "state": "Singapore",
          "postcode": "649960",
          "telephone": "+65 6264 2074",
          "country": "Singapore",
          "amenities": [
            "standard_toilet",
            "carwash",
            "high_speed_diesel_pump",
            "loyalty_cards",
            "credit_card",
            "fuel_card",
            "credit_card_general",
            "full_service",
            "fleet_card_general",
            "credit_card_visa",
            "credit_card_mastercard",
            "credit_card_american_express",
            "twenty_four_hour",
            "manual",
            "selectshop",
            "adblue_pack",
            "loyalty_program",
            "other_loyalty_cards",
            "service_bay",
            "air_and_water",
            "manned"
          ],
          "inactive": false,
          "distance": 2.22993937028,
          "driving_distance": null,
          "driving_duration": null,
          "website_url": "https://find.shell.com/sg/fuel/10000002-shell-boon-lay-616_01/en_SG",
          "mdm_site_category": "Conventional Fuel Site with EV",
          "formatted_address": "2 BOON LAY AVENUE\nSINGAPORE, Singapore, 649960",
          "site_category": "conventional_fuel_site_with_ev"
        }]
        

    this.state = {
      data :  [],
      providers: [],
      provider_name:'',
      sp_sample_data: sp_sample_data,
      bluecharge_sample_data: bluecharge_sample_data,
      shell_sample_data: shell_sample_data,
      openDialog: false,
      json: null,
      jsonSP: null,
      jsonBlue: null,
      jsonShell: null,
      jsonSPValidate: false,
      jsonShellValidate: false,
      jsonBlueChargeValidate: false,
    }

    // this.theme = {
    //   scheme: 'monokai',
    //   base00: '#272822',
    //   base01: '#383830',
    //   base02: '#49483e',
    //   base03: '#75715e',
    //   base04: '#a59f85',
    //   base05: '#f8f8f2',
    //   base06: '#f5f4f1',
    //   base07: '#f9f8f5',
    //   base08: '#f92672',
    //   base09: '#fd971f',
    //   base0A: '#f4bf75',
    //   base0B: '#a6e22e',
    //   base0C: '#a1efe4',
    //   base0D: '#66d9ef',
    //   base0E: '#ae81ff',
    //   base0F: '#cc6633',
    // };
  }
  componentDidMount() {
   this.callProviderApi(); 
  }

  callProviderApi(){
    let errorMessage = "";
    let status = 0;
    const { 
    REACT_APP_AUTH_API_URL
    } = process.env;
    const PATH = 'api/';
    const api = "providers";
    const URL = REACT_APP_AUTH_API_URL + PATH + api;
    let token = window.localStorage.getItem('access_token');

    let param = {
    page: this.state.page,
    take: this.state.limit,
    search: "",
    sorting_array: ['created_at DESC'],
    };
    this.setState({
    isLoading:true
    }, () => {
    axios.get(
            URL,
            { 
                params: param,
                data: param,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .catch( error => {
                try {
                    status = error.response.status;
                    this.setState({
                    errorStatus : status
                    })
                    if(this.state.errorStatus == 401) {
                    const { clearStorage } = this.context;
                    this.setState({
                        profileMenu: null,
                        isDrawerOpen: false
                    });
                    clearStorage();
                    window.location = "/";
                    } else {
                    if (error.response) {
                        // Request made and server responded
                        errorMessage = error.response.data;
                    } else if (error.request) {
                        // The request was made but no response was received
                        errorMessage = error.request;
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        errorMessage = error.message;
                    }
                    }
                } catch(err) {
                    errorMessage = 'Service Unavailable!'
                }
                this.setState({
                isLoading:false
                })
            }).then((response) => {
            // console.log(response);
            if(response != null){
            this.setState({
                providers: response.data.result,
                total: response.data.total,
                isLoading: false,
            });
            }
            });
    })
    
}

  importSPForm = () => {
      return <Container>
          <Row className="max-xs-width-control-900 p-4">
            <Col xs={12} md={6} className="max-xs-width-control-900 d-flex  align-items-start py-2">

              <div className="max-xs-width-control-900 w-100">
                <div>Import Json Data</div>
                  <textarea className={`form-control text-area-style ${this.state.jsonSPValidate ? 'invalid' : ''}`}
                  value={this.state.jsonSP}
                  onChange={(e)=> {
                    try {
                      const parsedData = JSON.parse(e.target.value);
                      let jsonData = e.target.value;
                      this.setState({
                        jsonSP: jsonData, // Update the json state with the parsed JSON data
                        jsonSPValidate: false, // If parsing succeeds, you can set jsonSPValidate to true
                      });
                    } catch (error) {
                      console.error('Error parsing JSON:', error);
                      this.setState({
                        jsonSP: e.target.value,
                        jsonSPValidate: true, // If parsing fails, set jsonSPValidate to false
                      });
                    }
                  }}/>
                  {this.state.jsonSPValidate && (
                      <div className="error-message">JSON data is invalid.</div>
                  )}
              </div>
            </Col>
            <Col xs={12} md={6} className="max-xs-width-control-900 d-flex  align-items-start py-2">
            <ReactJson src={this.state.sp_sample_data} />
            </Col>
              <button 
              onClick={(e)=>{
                e.preventDefault()
                e.stopPropagation()
                if (this.state.jsonSP == null) {
                  apiUtil.toast("Please add json first!!")
                } else {
                  try {
                    // Attempt to parse the JSON data
                    const parsedData = JSON.parse(this.state.jsonSP);
                    if (this.state.providers.filter(provider => provider.title && provider.title.trim() == "SP").length > 0) {
                      // If parsing succeeds, the JSON data is correct
                      // You can now proceed to call this.importApi('shell') or work with parsedData
                      this.importApi('SP');
                    } else {
                      this.setState({
                        openDialog : true,
                        provider_name: "SP"
                      })
                    }
                  } catch (error) {
                    // If parsing fails, the JSON data is not correct
                    console.error("JSON data is not correct:", error);
                    // Handle the error or show a message to the user
                    apiUtil.toast("Json format is not correct", "check_circle")
                  }
                }
              }}
              disabled={this.state.isLoading == true ? true: false} className="upload-style text-white mt-4" style={{margin:'auto', width:'200px'}}>
              {this.state.isLoading? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Submit' }
              </button>
          </Row>
      </Container>
  }

  importBlueChargeForm = () => {
    return <Container>
        <Row className="max-xs-width-control-900 p-4">
          <Col xs={12} md={6} className="max-xs-width-control-900 d-flex  align-items-start py-2">
            <div className="max-xs-width-control-900 w-100">
              <div>Import Json Data</div>
                <textarea className={`form-control text-area-style ${this.state.jsonBlueChargeValidate ? 'invalid' : ''}`}  value={this.state.jsonBlue}
                onChange={(e)=> {
                  try {
                    const parsedData = JSON.parse(e.target.value);
                    let jsonData = e.target.value;
                    this.setState({
                      jsonBlue: jsonData, // Update the json state with the parsed JSON data
                      jsonBlueChargeValidate: false, // If parsing succeeds, you can set jsonSPValidate to true
                    });
                  } catch (error) {
                    console.error('Error parsing JSON:', error);
                    this.setState({
                      jsonBlue: e.target.value,
                      jsonBlueChargeValidate: true, // If parsing fails, set jsonSPValidate to false
                    });
                  }
                }}/>
                {this.state.jsonBlueChargeValidate && (
                    <div className="error-message">JSON data is invalid.</div>
                )}
            </div>
          </Col>
          <Col xs={12} md={6} className="max-xs-width-control-900 d-flex  align-items-start py-2">
          <ReactJson src={this.state.bluecharge_sample_data} />
          </Col>
            <button 
            onClick={(e)=>{
              e.preventDefault()
              e.stopPropagation()
              if (this.state.jsonBlue == null) {
                apiUtil.toast("Please add json first!!")
              } else {
                try {
                  // Attempt to parse the JSON data
                  const parsedData = JSON.parse(this.state.jsonBlue);
              
                  // Check if the parsed data is an array
                  if (Array.isArray(parsedData)) {
                      // If parsing succeeds and the data is an array, you can proceed with your logic here
                      this.importApi('Blue Charge');
                  } else {
                      // If the parsed data is not an array, handle the error or show a message to the user
                      throw new Error('The parsed data is not in the expected format.');
                  }
              } catch (error) {
                  // If parsing fails or the data is not in the expected format, handle the error or show a message to the user
                  console.error('JSON data is not correct:', error);
                  // Handle the error or show a message to the user
                  apiUtil.toast('Json format is not correct', 'check_circle');
              }
              
              }
            }}
            disabled={this.state.isLoading == true ? true: false} className="upload-style text-white mt-4" style={{margin:'auto', width:'200px'}}>
            {this.state.isLoading? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Submit' }
            </button>
        </Row>
    </Container>
  }

  importShellForm = () => {
    return <Container>
        <Row className="max-xs-width-control-900 p-4">
          <Col xs={12} md={6} className="max-xs-width-control-900 d-flex  align-items-start py-2">

            <div className="max-xs-width-control-900 w-100">
              <div>Import Json Data</div>
                <textarea className={`form-control text-area-style ${this.state.jsonShellValidate ? 'invalid' : ''}`} value={this.state.jsonShell}
                onChange={(e)=> {
                  try {
                    const parsedData = JSON.parse(e.target.value);
                    let jsonData = e.target.value;
                    this.setState({
                      jsonShell: jsonData, // Update the json state with the parsed JSON data
                      jsonShellValidate: false, // If parsing succeeds, you can set jsonSPValidate to true
                    });
                  } catch (error) {
                    console.error('Error parsing JSON:', error);
                    this.setState({
                      jsonShell: e.target.value,
                      jsonShellValidate: true, // If parsing fails, set jsonSPValidate to false
                    });
                  }
                }}/>
                {this.state.jsonShellValidate && (
                    <div className="error-message">JSON data is invalid.</div>
                )}
            </div>
          </Col>
          <Col xs={12} md={6} className="max-xs-width-control-900 d-flex  align-items-start py-2">
          <ReactJson src={this.state.shell_sample_data} />
          </Col>
            <button 
            onClick={(e)=>{
              e.preventDefault()
              e.stopPropagation()
              if (this.state.jsonShell == null) {
                apiUtil.toast("Please add json data first!!")
              } else {
                try {
                  // Attempt to parse the JSON data
                  const parsedData = JSON.parse(this.state.jsonShell);
                  if (this.state.providers.filter(provider => provider.title && provider.title.trim() == "Shell").length > 0) {
                    // If parsing succeeds, the JSON data is correct
                    // You can now proceed to call this.importApi('shell') or work with parsedData
                    this.importApi('Shell');
                    } else {
                      this.setState({
                        openDialog : true,
                        provider_name: "Shell"
                      })
                    }
                 
                } catch (error) {
                  // If parsing fails, the JSON data is not correct
                  console.error("JSON data is not correct:", error);
                  // Handle the error or show a message to the user
                  apiUtil.toast("Json format is not correct", "check_circle")
                }
              }
            }}
            disabled={this.state.isLoading == true ? true: false} className="upload-style text-white mt-4" style={{margin:'auto', width:'200px'}}>
            {this.state.isLoading? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Submit' }
            </button>
        </Row>
    </Container>
  }

   // DIALOGS
   createDialog = () => {
    return <CustomDialog 
      open={this.state.openDialog}
      title={'Provider'}
      maxWidth={'xs'}
      padding={'0'}
      btnOkColor={'primary'}
      onClose={() => {
        this.setState({
          openDialog: false,
        });
      }}
      onOk={() => {
        this.importApi(this.state.provider_name)
      }}
    >
     <Container>
        <Row>
            <Col className="d-flex flex-column justify-content-center align-items-center">
                <div>No related Provider is found</div>
                <div className="p-3 my-3 rounded bg-warning text-white">{this.state.provider_name} will be created for all these charging locations</div>
            </Col>
        </Row>
     </Container>
    </CustomDialog>
  }

  /* IMPORT API JSON DATA */
  importApi = (type) => {
    let formValidate = true;
    let data = [];
    let api = null;
    const PATH = 'api/';
    let errorMessage = "";
    let status = 0;
    const {
        REACT_APP_AUTH_API_URL
    } = process.env;

    if (type == 'SP') {
      api = `import_sp`;
      let json = null;
      try {
        json = JSON.parse(this.state.jsonSP);
      } catch (error) {
        apiUtil.toast("Json format is not correct", "check_circle")
      }
      json.map((dd)=>{
        if (dd.address == "" || dd.address == null ||
            dd.title == "" || dd.title == null ||
            dd.latitude == "" || dd.latitude == null ||
            dd.longitude == "" || dd.longitude == null ) {
              this.setState({
                jsonSPValidate : true
              })
              formValidate = false;
        }

        data.push({
          address:dd.address,
          title:dd.title,
          latitude:dd.latitude,
          longitude:dd.longitude,
          ac_charging:dd.ac_charging,
          dc_charging:dd.dc_charging,
        })
      }) 
    } else if (type == 'Blue Charge') {
      api = `import_bluecharge`;
      let json = null;
      try {
        json = JSON.parse(this.state.jsonBlue);
      } catch (error) {
        apiUtil.toast("Json format is not correct", "check_circle")
      }
      json.map((dd)=>{
        if (dd.address == "" || dd.address == null ||
            dd.name == "" || dd.name == null) {
              this.setState({
                jsonBlueChargeValidate : true
              })
              formValidate = false;
        }

        data.push(dd)
      }) 
    } else if (type == 'Shell') {
      api = `import_shell`;
      let json = null;
      try {
        json = JSON.parse(this.state.jsonShell);
      } catch (error) {
        apiUtil.toast("Json format is not correct", "check_circle")
      }
      json.map((dd)=>{
        if (dd.name == "" || dd.name == null ||
            dd.address == "" || dd.address == null ||
            dd.lat == "" || dd.lat == null ||
            dd.postcode == "" || dd.postcode == null ||
            dd.lng == "" || dd.lng == null ) {
              this.setState({
                jsonShellValidate : true
              })
              formValidate = false;
        }

        data.push({
          name:dd.name,
          address:dd.address,
          lat:dd.lat,
          lng:dd.lng,
          postcode:dd.postcode,
          inactive:dd.inactive
        })
      }) 
    }
    if (formValidate == true) {
        this.setState({
            isLoading: true,
        });        

        const URL = REACT_APP_AUTH_API_URL + PATH + api;
        let token = window.localStorage.getItem('access_token');

        let param = {
        };
        axios.post(
            URL,
            data,
            {
                params: param,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .catch(error => {
                try {
                    status = error.response.status;

                    if (error.response) {
                        // Request made and server responded
                        errorMessage = error.response.data;
                    } else if (error.request) {
                        // The request was made but no response was received
                        errorMessage = error.request;
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        errorMessage = error.message;
                    }
                } catch (err) {
                    errorMessage = 'Service Unavailable!'
                }
            }).then((response) => {
                apiUtil.toast(response.data.message, 'check_circle');
                this.setState({
                    jsonSP : '',
                    jsonShell :'',
                    jsonBlue : '',
                    isLoading: false,
                    openDialog: false,
                    title: '',
                    titleValidate: false,
                    address: '',
                    addressValidate: false,
                    latitude: '',
                    latitudeValidate: false,
                    longitude: '',
                    longitudeValidate: false,
                    ac_chargers: '',
                    dc_chargers: '',
                    postal_code:'',
                    postal_codeValidate: false, 
                    formValidate: true,
                    provicer: '',
                    status: true,
                    exclude: '',
                    excle_file: null,
                    openImportDialog: false,
                    openImportDialogItem: null,
                })
            });
    } else {
        return
    }
      
  };
  /* END IMPORT API JSON DATA  */

  render() {
    return <Container className="dashboard-page">
       <Row className="px-4 py-3">
            <Col>
            <form noValidate autoComplete="off" onSubmit={this.handleSubmit}>
                <Card className="cd-shadow">
                  <h4 className='p-3'>Importing From <a href="https://www.spgroup.com.sg/sustainable-energy-solutions/electric-vehicle-solutions/charger-locations" target="_blank" style={{fontStyle: 'none', color:'#007cde'}}>https://www.spgroup.com.sg/sustainable-energy-solutions/electric-vehicle-solutions/charger-locations</a></h4>
                  {this.importSPForm()}
                </Card>
                <Card className="cd-shadow mt-3">
                  <h4 className='p-3'>Importing From <a href="https://chargingservices.totalenergies.sg/en/find_a_charger" target="_blank" style={{fontStyle: 'none', color:'#007cde'}}>https://chargingservices.totalenergies.sg/en/find_a_charger</a></h4>
                    {this.importBlueChargeForm()}
                </Card>
                <Card className="cd-shadow mt-3">
                  <h4 className='p-3'>Importing From <a href="https://www.shell.com.sg/motorists/shell-station-locator.html" target="_blank" style={{fontStyle: 'none', color:'#007cde'}}>https://www.shell.com.sg/motorists/shell-station-locator.html</a></h4>
                  {this.importShellForm()}
                </Card>
            </form>
            </Col>
        </Row>
        {this.createDialog()}
    </Container>;
  }
}

export default ManualLocation;
