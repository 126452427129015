import React, { Component } from 'react';
import BreadCrumb from "../../components/Navs/Breadcrumb"
import { 
  Box,
  Grid,
  IconButton,
} from '@material-ui/core';
import { ReactComponent as SearchIcon } from '../../assets/img/icons/search.svg';
import StickyPageHeader from '../../components/PageHeader/StickyPageHeader.jsx';
import Card from '../../components/Card/Card.jsx';
import Table from '../../components/Table/Table.jsx';
import CustomInput from '../../components/CustomFields/CustomInput.jsx';
import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import { NavLink, Link } from 'react-router-dom';
import axios from 'axios';
import HtmlParser from 'react-html-parser';

class Dashboard extends Component {
  searchData = '';
  static contextType = AuthContext;
  constructor(props){
    super(props);
    this.refTable = React.createRef();
    this.refTableSearch = React.createRef();

    

    let hiddenColumns = [];
    let columnHiddenSettingsId = 0;
    let columnHiddenSettings = JSON.parse(localStorage.getItem('job_column_settings'));

    if (columnHiddenSettings !== null) {
      hiddenColumns = columnHiddenSettings.json_data.replace(/[^0-9a-zA-Z-_.,]/g, "").split(",");
      columnHiddenSettingsId = columnHiddenSettings.id ? columnHiddenSettings.id : 0;
    }
    
    // console.log("contextType", AuthContext);
    this.state = {
      data :  [],
      page : 1,
      total : 0,
      limit : 20,
      errorStatus: '',
      isLoadingStatus: false,
      isLoading: false,
      pageSize : 20,      // status: [],
      hiddenColumnSettingId: columnHiddenSettingsId,
      hiddenColumns: hiddenColumns,
    }

   
  }
  componentDidMount() { 
    this.callReadApi();
  }

  
  callReadApi(search = ''){
    let errorMessage = "";
    let status = 0;
    const { 
      REACT_APP_AUTH_API_URL
    } = process.env;
    const PATH = 'admin/api/';
    const api = "application_companies";
    const URL = REACT_APP_AUTH_API_URL + PATH + api;
    let token = window.localStorage.getItem('access_token');
    
    let param = {
      page: this.state.page,
      take: this.state.limit,
      search: search,
      // start_date: startDate,
      // end_date: endDate,
      sorting_array: ['created_at DESC'],
    };
    this.setState({
      isLoading:true
    })
    axios.get(
      // "https://auth-api.worknode.ai/admin/api/application_companies?search=&page=1&take=10&sorting_array[]=created_at%20DESC",
      URL,
       { 
          params: param,
          data: param,
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token
          }
      })
      .catch( error => {
          try {
              status = error.response.status;
              this.setState({
                errorStatus : status
              })
              if(this.state.errorStatus == 401) {
                const { clearStorage } = this.context;
                this.setState({
                  profileMenu: null,
                  isDrawerOpen: false
                });
                clearStorage();
                window.location = "/";
              } else {
                if (error.response) {
                    // Request made and server responded
                    errorMessage = error.response.data;
                } else if (error.request) {
                    // The request was made but no response was received
                    errorMessage = error.request;
                } else {
                    // Something happened in setting up the request that triggered an Error
                    errorMessage = error.message;
                } 
              }
          } catch(err) {
              errorMessage = 'Service Unavailable!'
          }
          this.setState({
            isLoading:false
          })
      }).then((response) => {
        // console.log(response);
        if(response != null){
            console.log("d",response.data.total)
        this.setState({
          data: response.data.result,
          total: response.data.total,
          isLoading: false,
        });
        }
       
      });
  }

  customStickyPageHeader = () => {
    return <Box clone pl={4} pr={4} height={'100%'} alignItems={'center'}>
      <Grid container>
        <BreadCrumb />
      </Grid>
    </Box>
  }

  searchBox = () =>{
    return <Box mb={1}>
    <Grid container justify="flex-end">
    <Grid item xs={12} md={3}>
    <CustomInput
              ref={this.refTableSearch}
              placeholder={'Search Company'}
              value={this.searchData}
              endAdornment={
                <IconButton
                  onClick={(e) => {
                    this.callReadApi(this.refTableSearch.current.state.value);
                  }}
                >
                  <SearchIcon />
                </IconButton>
              }
              onPressEnter={(e) => {
                this.searchData = e.target.value
                this.callReadApi(e.target.value);
              }}
            />
     </Grid> 
    </Grid>
    </Box>
  }
 
  customTable = () => {

    return <Table 
      ref={this.refTable}
      className={'job-table-list' }  
      isLoading={this.state.isLoading}
      grouping={this.state.grouping}
      limit={this.state.limit}
      // pageSize= {this.state.pageSize}
      // pageSizeOptions ={this.state.pageSizeOptions}
      page={this.state.page}
      total={this.state.total}
      data={this.state.data}
      // defaultExpanded={true}
      // selection={true}
      onPageChange={(page,limit) => {
        
        // this.state.limit = limit;
        // this.pages  = page;
        // this.limits =  limit;
        this.setState({
          page: page,
          limit: limit
        }, () => { 
          // this.callReadApi();
          this.callReadApi(this.refTableSearch.current.state.value);
        });
      }}
      // Toolbar={this.customToolbar}
      // Row={this.customRow}
      columns={[
        { 
          title: "Action",
          field: "action",
          cellStyle: {
            minWidth: 120,
            maxWidth: 120            
          },
          render:(row, type) => {
            if(type === 'row'){
              return  <NavLink  
                to={`/company-detail/${row.id}`}
                 
                style={{ textDecoration: 'none' }}
                >View</NavLink>;
            } else {
              return row;
            }
          }
        },
        { 
          title: "Company Name", 
          field: "Company Name",
          cellStyle: {
            minWidth: 150,
            maxWidth: 150             
          },
          render:(row, type) => {
            if(type === 'row'){
              return (row.company_name   != ' ') ? HtmlParser(row.company_name) : '';
            } else {
              return row;
            }
          }
        },
        { 
          title: "Admin Name", 
          field: "admin-name",
          cellStyle: {
            minWidth: 240,
            maxWidth: 240            
          },

          render: (row, type) => {
            if(type === 'row'){
              if(row.hasOwnProperty("first_admin")){
                return <> 
                {(row.first_admin.first_name   != ' ') ? HtmlParser(row.first_admin.first_name) : ''}<br />
                {(row.first_admin.email  != ' ') ? HtmlParser(row.first_admin.email) : ''}<br />
                {(row.first_admin.disabled === true) ? <span style={{color:'#ef6428'}}>Not Activated </span> : <span style={{color:'#65f442'}}> Activated </span>}<br />
                </>
              } else {
                return '';
              } 
            } else {
              return row;
            }
          }
        },
        { 
          title: "Contact No", 
          field: "contact-number",
          cellStyle: {
            minWidth: 180,
            maxWidth: 180
          },
          render: (row, type) => {
            if(type === 'row'){
              return (row.contact_no   != ' ') ? HtmlParser(row.contact_no) : '';
             } else {
              return row;
            }
          }
        },
        { 
          title: "Signup date", 
          field: "signup-date",
          cellStyle: {
            minWidth: 150,
            maxWidth: 150            
          },
          render: (row, type) => {
               if(type === 'row'){
                 if(row.created_at   != ' ') {
                   var date = new Date(row.created_at);
                   return <>
                   {date.toLocaleTimeString()} <br /> 
                   {date.toLocaleDateString()}
                   </>
                 }
            } else {
              return row;
            }
          }
        },
        { 
          title: "Status", 
          field: "status",
          hidden: this.state.hiddenColumns.includes("'company_name'"),
          render:(row, type) => {
            if(type === 'row'){
              if(row.application_company_status_id == null) {
                return  <>
                <span style={{color:'#4286f4'}}>trial</span><br />
                {row.unpaid_bills? <span style={{color:'#65f442'}}>{row.unpaid_bills.length}</span>: ''}
                </>
              }
              else if(row.application_company_status_id == '1') {
                return  <>
                <span style={{color:'#474747'}}>blacklist</span><br />
                {row.unpaid_bills? <span style={{color:'#65f442'}}>{row.unpaid_bills.length}</span>: ''}
                </>
            }
              else if(row.application_company_status_id == '2') {
                return  <>
                <span style={{color:'#65f442'}}>Subscribed</span><br />
                {row.unpaid_bills?  <span style={{color:'#65f442'}}>{row.unpaid_bills.length}</span>: ''}
                </>
            }
            
          } else {
            return row;
          }
        }
        },
      ]}
    />
  }

  render() {
    return <Box className="dashboard-page">
      <StickyPageHeader>
        {this.customStickyPageHeader()}
      </StickyPageHeader>
      <Card className={'job-listing-step'}>
        {this.searchBox()}
        {this.customTable()}
      </Card>
    </Box>;
  }
}

export default Dashboard;
