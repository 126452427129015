import React, { useCallback } from 'react';
import {BrowserRouter, Switch, Route, Redirect} from 'react-router-dom';

import './assets/scss/main.scss';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AuthDataProvider from "./components/Auth/AuthDataProvider.jsx";
import RouteLayout from "./layout/Main/RouteLayout.jsx";  
import PageNotFound from "./views/PageNotFound/PageNotFound.jsx";
import Dashboard from "./views/Dashboard/Dashboard.jsx"; 
import Promotion from "./views/Promotion/Promotion.jsx";
import FreeAccount from "./views/FreeAccount/FreeAccount.jsx";
import ChargingLocation from "./views/ChargingLocation/ChargingLocation.jsx";
import ChargingLocationList from "./views/ChargingLocation/ChargingLocationList.jsx";
import DetailFreeAccount from "./views/FreeAccount/DetailFreeAccount.jsx";
import CreateFreeAccount from "./views/FreeAccount/CreateNewFreeAccount.jsx";
import DetailCompany from "./views/Dashboard/CompanyDetail.jsx";
import DetailPromotion from './views/Promotion/DetailPromotion.jsx';
import CreatePromotion from './views/Promotion/CreateNewPromotion.jsx';
import EditSmsCredential from './views/SMSCredential/EditSmsCredential.jsx';
import Login from './views/Login/login.jsx';
import RequestSMS from './views/SMSCredential/requestSms';
import RecordSMS from './views/SMSCredential/recordSms';
import ManualLocation from './views/ChargingLocation/ManualLocation';
import Provider from './views/ChargingLocation/Provider';
import routeOptimization from './views/RouteOptimization/routeOptimization';
var isClicked = false;


function App() {
  // const [path, setPath] = useState();
  // const [errorState, setErrorState] = useState();
  
  // setPath(window.localStorage.getItem('access_token'));
  // setErrorState(window.localStorage.getItem('errorState'));

  // if(path == null || error_state == true){
  //   if( ! (window.location.pathname == "/Sign-in"  || window.location.pathname == "/Sign-up" ) ) {
  //     window.location.href ="Sign-in";
  //   }
  // }
  
  const beforeinstallpromptHandler = useCallback(
    (deferredPrompt) => {
      if(!isClicked){
        toast(
          <button 
            onClick={() => {
              deferredPrompt.prompt();
    
              deferredPrompt.userChoice
                .then((choiceResult) => {
                  isClicked = true;
                  // if (choiceResult.outcome === 'accepted') {
                  //   console.log('User accepted the A2HS prompt');
                  // } else {
                  //   console.log('User dismissed the A2HS prompt');
                  // }
              });
            }}  
          >Install</button>
        );
      }
    }, []
  );
  window.addEventListener('beforeinstallprompt', beforeinstallpromptHandler);

  
  const getUserConfirmation = (dialogKey, callback) => {
    const dialogTrigger = window[Symbol.for(dialogKey)];
  
    if (dialogTrigger) {
      return dialogTrigger(callback);
    }
  
    callback(true);
  };

  // let grantedMenu = new RoleAccessService;
  // let currentRole = window.localStorage.getItem('current_role');

  // if(!grantedMenu.admin_roles_with_customer.includes(currentRole)){
  //   var root_url = "/live-tracking";
  //   console.log(currentRole, "Current Role")
  // } else {
  //   var root_url="/dashboard";
  // }

  return (
    <AuthDataProvider>
      <div className="App">
        <BrowserRouter basename="/" getUserConfirmation={getUserConfirmation}>
          <Switch>
            <Route exact path="/">              
                <Redirect to="/dashboard" />           
              </Route>

              <Route path="/login" component={Login} />
              <RouteLayout path="/dashboard" component={Dashboard} menu_name={"dashboard"} />
              <RouteLayout path="/promotion" component={Promotion} menu_name={"promotion"} />
              <RouteLayout path="/free-account" component={FreeAccount} menu_name={"free-account"} />
              <RouteLayout path="/charging-location" component={ChargingLocation} menu_name={"charging-location"} />
              <RouteLayout path="/provider" component={Provider} menu_name={"charging-location"} />
              <RouteLayout path="/manual-location" component={ManualLocation} menu_name={"manual-location"} />
              <RouteLayout path="/charging-location-list" component={ChargingLocationList} menu_name={"charging-location"} />
              {/* <RouteLayout path="/company-detail" component={DetailCompany} menu_name={"company-detail"} /> */}
              <RouteLayout path="/company-detail/:id" component={DetailCompany} menu_name={"company-detail"} />
              <RouteLayout path="/promotion-detail" component={DetailPromotion} menu_name={"promotion-detail"} />
              <RouteLayout path="/create-promotion" component={CreatePromotion} menu_name={"create-promotion"} />
              <RouteLayout path="/free-account-detail" component={DetailFreeAccount} menu_name={"free-account-detail"} />
              <RouteLayout path="/create-free-account" component={CreateFreeAccount} menu_name={"create-free-account"} />
              <RouteLayout path="/edit-sms-credential" component={EditSmsCredential} menu_name={"edit-sms-credential"} />
              <RouteLayout path="/request-sms" component={RequestSMS} menu_name={"request-sms"} />
              <RouteLayout path="/sms-usage" component={RecordSMS} menu_name={"record-sms"} />
              <RouteLayout path="/route-optimization" component={routeOptimization} menu_name={"route-optimization"} />
              <Route component={PageNotFound} />
            
          </Switch>
        </BrowserRouter>
        <ToastContainer className="custom-toast" autoClose={false} />
      </div>
 </AuthDataProvider>
  );
}


export default App;
