import React, { Component } from 'react'
import { 
    Box,
    Grid,
    Container,
    Divider,
    InputLabel,
    Select,
    IconButton,
    CircularProgress,
} from '@material-ui/core';
import CustomDateRangePicker from '../../components/CustomFields/CustomDateRangePicker.jsx';
import axios from 'axios';
import { NavLink, Link } from 'react-router-dom';

import Card from '../../components/Card/Card.jsx';
import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import CustomButton from '../../components/CustomFields/CustomButton.jsx';
import Table from '../../components/Table/Table.jsx';
import apiUtil from "../../api/apiUtil.jsx";
import authApi from "../../api/api.jsx";
import moment from 'moment';

class RecordSMS extends Component {
  static contextType = AuthContext;

  dataCompany= [];
  constructor(props) {
    super(props)
    this.refTable = React.createRef();
    this.refTableSearch = React.createRef();
    let rangeDate = {
      startDate: moment(),
      endDate: moment().add(1,'months')
    };

    let rangeDateItem = localStorage.getItem(this.pageName + '_rangeDate');
    if(rangeDateItem && rangeDateItem !== ''){
      try {
        rangeDate = JSON.parse(rangeDateItem);
      }catch(e){}
    }

    this.state = {
        activeInactiveItem : 0,
        data :  [],
        rangeDate: '',
        rangeDate: rangeDate,
        startDate: rangeDate.startDate,
        endDate: rangeDate.endDate,
        companyFilter: '',
        data_active :  [],
        data_disable :  [],
        company_id: 0,
        page : 1,
        selectedRow: [],
        total_active : 0,
        bulk_delete_id: '',
        company_id: '',
        total_disable : 0,
        limit : 20,
        total: 0,
        request_device: 0,
        billing: [],
        billing_date: [],      
        isLoadingStatus: false,
        pageSize : 10,     
      }

}

componentDidMount() {
   this.callReadApi();
   this.callSmsRequestApi();
}

callReadApi(company_id){
  let errorMessage = "";
  let status = "";
  let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
  let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());

  const { 
    REACT_APP_LDS_API_URL
  } = process.env;
  const PATH = 'admin/api/';
  const api = "sms_usages";
  const URL = REACT_APP_LDS_API_URL + PATH + api;
  let token = window.localStorage.getItem('access_token');
 
  let param = {
    page: this.state.page,
    take: this.state.limit,
    sort: 'latest',
    start_date: startDate,
    end_date: endDate,
    application_company_id: company_id,
    search: "",
  };
  this.setState({
    isLoading:true
  }, () => {
    axios.get(
          URL,
          { 
              params: param,
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + token
              }
          })
          .catch( error => {
              try {
                  status = error.response.status;
                  this.setState({
                    errorStatus : status
                  })
                  if(this.state.errorStatus == 401) {
                    const { clearStorage } = this.context;
                    this.setState({
                      profileMenu: null,
                      isDrawerOpen: false
                    });
                    clearStorage();
                    window.location = "/";
                  } else {
                    if (error.response) {
                      // Request made and server responded
                      errorMessage = error.response.data;
                    } else if (error.request) {
                      // The request was made but no response was received
                      errorMessage = error.request;
                    } else {
                      // Something happened in setting up the request that triggered an Error
                      errorMessage = error.message;
                    }
                  }
              } catch(err) {
                  errorMessage = 'Service Unavailable!'
              }
              this.setState({
                isLoading:false
              })
          }).then((response) => {
            if(response != null){
            this.setState({
              data: response.data.result,
              total: response.data.total,
              isLoading: false,
            });
            }
          });
  })
  
}

callSmsRequestApi () {
  let errorMessage = "";
  let status = 0;
  const { 
    REACT_APP_AUTH_API_URL
  } = process.env;
  const PATH = 'admin/api/';
  const api = "sms_requests";
  const URL = REACT_APP_AUTH_API_URL + PATH + api;
  let token = window.localStorage.getItem('access_token');

  this.setState({
    isLoading:true
  }, () => {
    axios.get(
          URL,
          { 
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + token
              }
          })
          .catch( error => {
              try {
                  status = error.response.status;
                    if (error.response) {
                      // Request made and server responded
                      errorMessage = error.response.data;
                    } else if (error.request) {
                      // The request was made but no response was received
                      errorMessage = error.request;
                    } else {
                      // Something happened in setting up the request that triggered an Error
                      errorMessage = error.message;
                    }
              } catch(err) {
                  errorMessage = 'Service Unavailable!'
              }
              this.setState({
                isLoading:false
              })
          }).then((response) => {
            if(response != null){
              this.dataCompany = response.data.result;
            }
          });
  })
}
    handleSubmit = (e) =>{
        e.preventDefault();
        e.stopPropagation();

        this.setState({
            isLoading: true,
        })
        
        const form = e.currentTarget;
        this.setState({
            formNumberValidated: false,
            accountSSIDValidated: false,
            authTokenValidated: false
        })

        if(this.state.formNumber === '') {
            this.setState({
                formNumberValidated: true,
            })
        }
        if(this.state.accountSSID === '') {
            this.setState({
                accountSSIDValidated: true,
            })
        }

        if(this.state.authToken === '') {
            this.setState({
                authTokenValidated: true,
            })
        }

        if(form.checkValidity() === true) {
            this.callUpdateApi();

        } else {
            this.setState({
                isLoading: false,
            })
        }

    }

    customTable = () => {

        return <Table 
        ref={this.refTable}
        className={'job-table-list' }  
        isLoading={this.state.isLoading}
        grouping={this.state.grouping}
        limit={this.state.limit}
        pageSize= {this.state.pageSize}
        // pageSizeOptions ={this.state.pageSizeOptions}
        page={this.state.page}
        total={this.state.total}
        data={this.state.data}
        // defaultExpanded={true}
        selection={true}
        onPageChange={(page,size) => {
          this.state.limit = size;
          this.setState({
            page: page,
          }, () => { 
            this.callReadApi();
          });
        }}
        onSelectionChange={(rows)=>{
        //   this.setState({selectedRow:rows});
        }}
        // Toolbar={this.customToolbar}
        // Row={this.customRow}
        columns={[
            { 
              title: "SMS ID",
              field: "sms id",
              cellStyle: {
                minWidth: 150,
                maxWidth: 150            
              },
              render: (row, type) => {
                if(type === 'row'){
                  return row.id? row.id : ''
                
              } else {
                  return row;
                }
              }
            },
            { 
              title: "Sender Company", 
              field: "sender company",
              cellStyle: {
                minWidth: 220,
                maxWidth: 220
              },
              render: (row, type) => {
                if(type === 'row'){
                return  <NavLink  
                to={`/company-detail/${row.application_company.id}`} 
                style={{ textDecoration: 'none' }}
                >{row.application_company.company_name? row.application_company.company_name: ''}</NavLink>;
                } else {
                  return row;
                }
              }
            },
            { 
              title: "To Customer", 
              field: "to customer",
              cellStyle: {
                minWidth: 220,
                maxWidth: 220
              },
              render: (row, type) => {
                if(type === 'row'){
                return row.to_customer? row.to_customer : '';
                } else {
                  return row;
                }
              }
            },
            { 
              title: "Order Number", 
              field: "order_number",
              cellStyle: {
                minWidth: 150,
                maxWidth: 150
              },
              render: (row, type) => {
                if(type === 'row'){
                return row.order_number? row.order_number : '';
                } else {
                  return row;
                }
              }
            },
            { 
              title: "Job Status", 
              field: "job_status",
              cellStyle: {
                minWidth: 150,
                maxWidth: 150
              },
              render: (row, type) => {
                if(type === 'row'){
                return row.order_status? row.order_status : '';
                } else {
                  return row;
                }
              }
            },
            { 
              title: "Status", 
              field: "status",
              cellStyle: {
                minWidth: 150,
                maxWidth: 150            
              },
              render: (row, type) => {
                if(type === 'row'){
                  return <Box>
                   {row.is_success == true? 
                   <span style={{color:"green"}}>Delivered</span>:
                   <span style={{color:"orange"}}>Pending</span>
                  }
                  </Box>;
                } else {
                  return row;
                }
              }
            },
            { 
                title: "Date", 
                field: "date",
                cellStyle: {
                  minWidth: 150,
                  maxWidth: 150            
                },
                render: (row, type) => {
                  if(type === 'row'){
                    return row.usage_date ? row.usage_date: '';

                  } else {
                    return row;
                  }
                }
              },
          ]}
        />
      }
    

      filter = () => {

        return <Box style={{display: 'flex', alignItems:'center',}} mb={3}>
          <Box mr={2}>Filter By</Box>
          <Box clone pb={{ xs: 2, md: 0 }} mr={2}>
          <Grid item xs={12} md={2}>
          <select 
          onChange={(e) => {
            this.callReadApi(e.target.value);
          }}
          className="dropdown-style" 
          name="company" 
          id="company">
          <option disabled selected value=''>Filter By Company</option>
            {
              this.dataCompany != null ? this.dataCompany.map(data =>
                <option value={data.application_company_id}>{data.application_company && data.application_company.company_name}</option>
              ) : ' '}
          </select>
          </Grid>
          </Box>
          <Box clone pb={{ xs: 2, md: 0 }}>
          <Grid item xs={12} md={2}>
            <CustomDateRangePicker
              className={'select-dates-step'}
              color={'#2e86aa'}
              range={this.state.rangeDate}
              onChange={(range) => {
                localStorage.setItem(this.pageName + '_rangeDate', JSON.stringify(range));

                this.setState({
                  rangeDate: range,
                  startDate: range.startDate,
                  endDate: range.endDate
                }, () => {
                  this.callReadApi();
              });
              }}
            />
          </Grid>
        </Box>
        </Box>
      }
    /* STICKY PAGE HEADER */
  customStickyPageHeader = () => {

    return <Box clone pl={4} pr={4} height={'100%'} alignItems={'center'}>
      <Grid container justify="flex-end">
        
        <Box clone>
          <Grid item xs={'auto'}>
       
            <CustomButton 
              type={'submit'}
              color={'secondary'}
              isLoading={this.state.isLoading}
            ><Box pl={2} pr={2}>Submit Form</Box>
            </CustomButton>
          
       
          </Grid>
        </Box>
      </Grid>
    </Box>
  }
  /* END STICKY PAGE HEADER */

    render() {
        return <Box>
             <Card className={'job-listing-step'}>
               <Box>
                 {this.filter()}
               </Box>
                <Box>
                    {this.customTable()}
                </Box>
             </Card>
        </Box>
    }

    

}

export default RecordSMS;