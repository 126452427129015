import React, { Component } from 'react';
import { read as readXsl, utils as xslUtils } from 'xlsx';
import uuid from 'uuid';
import moment from 'moment';

import apiUtil from "../../api/apiUtil.jsx";
import Geocode from "react-geocode";
import axios from 'axios';
import CustomLabel from '../../components/CustomFields/CustomLabel.jsx';
import CustomAlert from '../../components/Alert/CustomAlert.jsx';
import CustomButton from '../../components/CustomFields/CustomButton.jsx';
import { 
  Box,
  Grid,
  IconButton,
  CircularProgress,
} from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';

export class ImportDialog extends Component {
  
  constructor(props){
    super(props);
    
    this.refForm = React.createRef();
    let isLoading = ((props.isLoading === true || props.isLoading === false) ? props.isLoading : false);
    let file = (props.file) ? props.file : null;
    let dataFields = [
        { label: 'Title', value: 'Title', col: 'title', required: false, disabled: false, dataType: 'string' },
        { label: 'Address', value: 'Address', col: 'address', required: false, disabled: false, dataType: 'string' },
        { label: 'No. AC chargers', value: 'No. AC chargers', col: 'ac_chargers', required: false, disabled: false, dataType: 'string' },
        { label: 'No. DC chargers', value: 'No. DC chargers', col: 'dc_chargers', required: false, disabled: false, dataType: 'string' },
        { label: 'Latitude', value: 'Latitude', col: 'latitude', required: false, disabled: false, dataType: 'string' },
        { label: 'Longitude', value: 'Longitude', col: 'longitude', required: false, disabled: false, dataType: 'string' },
        { label: 'status', value: 'status', col: 'status', required: false, disabled: false, dataType: 'string' },
        { label: 'Postal Code', value: 'Postal Code', col: 'postal_code', required: false, disabled: false, dataType: 'string' },
        { label: 'Provider', value: 'Provider', col: 'provider', required: false, disabled: false, dataType: 'string' },
        // { label: 'Exclude', value: 'Exclude', col: 'exclude', required: false, disabled: false, dataType: 'string' },
        { label: 'Remarks', value: 'Remarks', col: 'exclude', required: false, disabled: false, dataType: 'string' },
    ];
   
    this.dataFieldsPom = JSON.parse(JSON.stringify(dataFields));

    this.state = {
        isLoading: isLoading,
        file: file,
        importedSheetData: [],
        dataFields: dataFields,
        headers: [],
        excelRows: [],
        customerItems: [],
        columns: [],
        rows: [],
        customerItemsIsLoading : false,
        isLoadingSteps: false,
        columnsSteps: [],
        rowsSteps: [],
        errLatlng: [],
        isLoadingItems: false,
        columnsItems: [],
        rowsItems: [],
        providers:[],
        status: [],
        isLoadingInitCalls: true,

        refresh: false,

        isLoadingAppSettings: false,
        rule: null,
        errMessages: [],
        isFirstTime: false,
        package_types: [],
        package_list: [],
        latLongErr:[]
    }
  }
  

  componentDidMount() {
   
    if(this.state.file){
        this.callReadApi(()=> {
            this.loadWorkSheet();
        })
    } else {
        if(this.props.onClose){
            this.props.onClose();
        }
    }
  }

  callReadApi(callback = null){
    let errorMessage = "";
    let status = 0;
    const { 
    REACT_APP_AUTH_API_URL
    } = process.env;
    const PATH = 'api/';
    const api = "providers";
    const URL = REACT_APP_AUTH_API_URL + PATH + api;
    let token = window.localStorage.getItem('access_token');

    let param = {
    page: this.state.page,
    take: this.state.limit,
    search: "",
    sorting_array: ['created_at DESC'],
    };
    this.setState({
    isLoading:true
    }, () => {
    axios.get(
            URL,
            { 
                params: param,
                data: param,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .catch( error => {
                try {
                    status = error.response.status;
                    this.setState({
                    errorStatus : status
                    })
                    if(this.state.errorStatus == 401) {
                    const { clearStorage } = this.context;
                    this.setState({
                        profileMenu: null,
                        isDrawerOpen: false
                    });
                    clearStorage();
                    window.location = "/";
                    } else {
                    if (error.response) {
                        // Request made and server responded
                        errorMessage = error.response.data;
                    } else if (error.request) {
                        // The request was made but no response was received
                        errorMessage = error.request;
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        errorMessage = error.message;
                    }
                    }
                } catch(err) {
                    errorMessage = 'Service Unavailable!'
                }
                this.setState({
                isLoading:false
                })
            }).then((response) => {
            // console.log(response);
            if(response != null){
            this.setState({
                providers: response.data.result,
                total: response.data.total,
                isLoading: false,
            }, () => {
                if(callback){
                  callback();
                }
              });
            }
            });
    })
    
}

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isLoading !== this.state.isLoading) {
        let isLoading = ((nextProps.isLoading === true || nextProps.isLoading === false) ? nextProps.isLoading : false);
        this.setState({
            isLoading: isLoading
        });
    }
    if (nextProps.collection_data !== this.state.collection_data) {
        let collection_data =  nextProps.collection_data ? nextProps.collection_data : [];
        this.setState({
            collection_data: collection_data
        });
    }
    if (nextProps.file !== this.state.file) {
        let file = (nextProps.file) ? nextProps.file : null;
        this.setState({
            file: file
        });
    }
  }

  /* FUNCTIONS */
  loadWorkSheet = () => {
    if (typeof (FileReader) !== 'undefined') {
        const reader = new FileReader();
        if (reader.readAsBinaryString) {
            reader.onload = (e) => {
                let wb = readXsl(reader.result, {type: 'binary'});
                
                let wsname = wb.SheetNames[0];
                let ws = wb.Sheets[wsname];
               
                let data = xslUtils.sheet_to_json(ws, { defval: null, raw: false, header: 1, cellDates: true});
                // data.splice(0, 13)

                let filteredData = data.filter(function (el) {
                  return el[0] !== null || el[1] !== null;
                });
                this.setState({
                    importedSheetData: data && data.length > 0 && data[0][0] == 'Title' ? filteredData : [],
                }, () => {
                    this.initColumns();
                });
            };
            reader.readAsBinaryString(this.state.file);
        }
    } else {
        console.log("This browser does not support HTML5.");
    }
  }
  
  initColumns = () => {
    let { isCustomerRequired } = this.context;
    let columns = [];
    let columnsSteps = [];
    let columnsItems = [];

    let headers = [];
    if(this.state.importedSheetData && this.state.importedSheetData.length > 0){
        let heads = this.state.importedSheetData[0];
        headers = heads.map((head, i) => {
            let labelItem = this.state.dataFields.filter(x => x.value === head);

            return {
                value: head,
                label: (labelItem && labelItem.length > 0) ? labelItem[0].value : head,
            }
        });
    }
    
    this.state.dataFields.map((feild, i) => {
        let headerIndex = headers.findIndex(x => x.value === feild.value);
        
        if(headerIndex !== -1){
            feild.selected = feild.value;
            feild.isSelected = true;
        } else {
            feild.isSelected = false;
        }

        columns.push({ 
            field: feild.selected,
            realField: feild.value.trimEnd(),
            title: feild.label,
            required: feild.required , 
            dataType: feild.dataType,
            isSelected: feild.isSelected,
            colDB: feild.col,
            width: 200,
            sorting: false,
            searchable: false,
            grouping: false,
            filtering: false,
            render: (row, type) => {
                return this.customCell(row, feild, feild.colIndex !== undefined ? feild.colIndex : i);
            }
        });
    });

    this.setState({
        columns: columns,
        columnsSteps: columnsSteps,
        columnsItems: columnsItems,
        headers: headers,
    }, () => {
        if(!this.state.isFirstTime){
            this.initRows(()=> {
                
            });
        } else {
            this.setState({
                isLoadingInitCalls: false,
            });
        }
    });
  }

  customCell = (row, feild, i) => {
    let { isCustomerRequired } = this.context;
    row[feild.value + '_validated'] = (row[feild.value] && row[feild.value] !== '') ? false : feild.required;// this.unRequiredFileldUti(feild.label, feild.required);
    let isError = (feild.required) ? row[feild.value + '_validated'] : false;
    isError = ((isError === false) || (isError === true)) ? isError : false;
    let isSelected = this.state.columns[i] !== undefined ? this.state.columns[i].isSelected : false;

    if(isSelected){
        return <Box>
        </Box>;
    } else {
        return <Box></Box>
    }
  }

  initStepColumns = (dataFields = [], callback = null) => {
    let columnsSteps = [];

    let headers = [];

    if(this.state.importedSheetData && this.state.importedSheetData.length > 0){
        let heads = this.state.importedSheetData[0];
        headers = heads.map((head, i) => {
            let labelItem = dataFields.filter(x => x.value === head);

            return {
                value: head,
                label: (labelItem && labelItem.length > 0) ? labelItem[0].label : head,
            }
        });
    }

    dataFields.map((feild, i) => {
        let headerIndex = headers.findIndex(x => x.value === feild.value);
        if(headerIndex !== -1){
            feild.selected = feild;
        }

        // if(feild.type === DataFieldType.Step){
        //     columnsSteps.push({ 
        //         field: feild.selected,
        //         realField: feild.value,
        //         title: <Box fontWeight={'bold'}>{feild.label}{((feild.required) ? <span className={'text-danger font-size-18'}>*</span> : '')}</Box>,
        //         required: feild.required,
        //         dataType: feild.dataType,
        //         colDB: feild.col,
        //         width: 200,
        //         sorting: false,
        //         searchable: false,
        //         grouping: false,
        //         filtering: false,
        //         render: (row, type) => {
        //             return this.customCellSteps(row, feild);
        //         }
        //     });
        // }
    });

    columnsSteps.push({ 
        title: "", 
        width: 'auto',
        sorting: false,
        searchable: false,
        grouping: false,
        filtering: false,
        action: true,
        render: (row) => {
            return <Box>
                {(this.state.rowsSteps && this.state.rowsSteps.length > 1) && <IconButton
                    onClick={() => {
                        this.removeStep(row.uuid);
                    }}
                >
                    {/* <DeleteIcon /> */}
                </IconButton>}
            </Box>
        }
    });

    this.setState({
        columnsSteps: columnsSteps,
    }, () => {
        if(callback){
            callback();
        }
    });
  }

  initRows = (callback = null) => {
    let excelRows = [];
    this.setState({
        isLoadingInitCalls: true,
    });

    if (this.state.importedSheetData && this.state.importedSheetData.length > 1) {
        excelRows = this.state.importedSheetData.map((item, i) => {
            if(i > 0){
                let row = this.state.importedSheetData[0].reduce((sum, key, index) => Object.assign(sum, { [key]: item[index] }), {});
                return row;
            } else {
                return null;
            }
        });
    }


    
    if(excelRows && excelRows.length > 0){
        excelRows.splice(0, 1);
    }
    
    let rows = excelRows;
    let errMessages = [] 
    let errLatLng = []
    let tempDate = ''
    let tempDO = ''
    
    if(rows.length < 1) {
          errMessages.push(`Station is empty.`)
    } 

   
    const uniqueTitles = [];
    
    rows.map(dd => {
        let exist_name = rows.filter(item => item.Address && item.Address.trim() == dd.Address && dd.Address.trim())
        if (exist_name.length > 1) {
            uniqueTitles.push(dd.Address)
        }
    })
    const uniqueArray = [...new Set(uniqueTitles)];
    if (uniqueArray && uniqueArray.length > 0) {
        errMessages.push(`${uniqueArray.join(", ")} Address same column exist`)
    }

    const uniqueLatLng = [];
    
    rows.map(dd => {
        let exist_name = rows.filter(item => item.Latitude == dd.Latitude && item.Longitude  == dd.Longitude)
        if (exist_name.length > 1) {
            uniqueLatLng.push(dd.Latitude + ', ' + dd.Longitude)
        }
    })
    const uniqueLatLngArray = [...new Set(uniqueLatLng)];
    if (uniqueLatLngArray && uniqueLatLngArray.length > 0) {
        errMessages.push(`${uniqueLatLngArray.join("/ ")} Lat, Lng same column exist`)
    }
    rows.forEach((row, i) => {
        
        if(row['Title'] == '' || row['Title'] == null) {
            errMessages.push(`Title is Invalid at row ${i+2}.`)
        }

        if(row['Address'] == '' || row['Address'] == null) {
            errMessages.push(`Address is Invalid at row ${i+2}.`)
        }

        // if(row['Latitude'] == '' || row['Latitude'] == null || !isNaN(+row['Latitude']) == false) {
        //     errMessages.push(`Latitude is Invalid at row ${i+2}.`)
        // }

        // if(row['Longitude'] == '' || row['Longitude'] == null || !isNaN(+row['Longitude']) == false) {
        //     errMessages.push(`Longitude is Invalid at row ${i+2}.`)
        // }     
        
        if(row['Postal Code'] != null && row['Postal Code'] != "" && !apiUtil.postalCodeValidation(row['Postal Code'])) {
            errMessages.push(`Postal Code is Invalid at row ${i+2}.`)
        }  
      })
    
    this.setState({
        excelRows: excelRows,
        rows: rows,
        errMessages: errMessages,
    }, () => {
        if(!this.state.isFirstTime){
            this.initApiCalls()
        }
        
        this.setState({
            isLoadingInitCalls: false,
            isFirstTime: false,
        });

        if(this.state.rowsSteps && this.state.rowsSteps.length === 0){
            this.addStep();
        }
        this.addItem();

        if(callback){
            callback();
        }
    });
    
  }

  initApiCalls = () => {
    let rows = this.state.rows;
    this.setState({
        isLoadingInitCalls: true,
    }, () => {
        const promises = [];

        if(rows && rows.length > 0){
            /*this.setState({
                geocoderAddressIsLoading: true
            })*/
            rows.forEach((item, i) => {
                // if(item['drop_off_address'] && item['drop_off_address'] !== ''){
                //     promises.push(this.asyncCallGeoCoderAddressApi(item, 'drop_off_address'));
                // }
                
                /*if(item['Address'] && item['Address'] !== ''){
                        Geocode.setApiKey(REACT_APP_GOOGLE_MAP_API_KEY)
                    setTimeout(()=> {Geocode.fromAddress(item['Address']).then(
                        (response) => {
                          const { lat, lng } = response.results[0].geometry.location;
                          let coord = {
                            lat,
                            lng
                        };
        
                        item['coord'] = coord;
                        },
                        (error) => {
                          console.error(error);
                        }
                      );

                      if(i == rows.length -1) {
                        this.setState({
                            geocoderAddressIsLoading: false
                        })
                      }
                    }, 30 * i)
                    
                    
                    // promises.push(this.asyncCallGeoCoderAddressApi(item, 'Address'));
                } else {
                    this.setState({
                        geocoderAddressIsLoading: false
                    })
                }*/
    
                if(item['company_code'] && item['company_code'] !== ''){
                    promises.push(this.asyncCallCheckCompanyCodeApi(item, 'company_code'));
                }
            });
        }

        if(promises && promises.length > 0){
            Promise.all(promises).then((results) => {
                this.setState({
                    isLoadingInitCalls: false,
                });
            }).catch((e) => {
                this.setState({
                    isLoadingInitCalls: false,
                });
            });
        }else{
            this.setState({
                isLoadingInitCalls: false,
            });
        }
    });
  }

  checkAddressApiCalls = (field) => {
    let rows = this.state.rows;
    
    this.setState({
        isLoadingInitCalls: true,
    }, () => {
        const promises = [];

        if(rows && rows.length > 0){
            rows.forEach((item, i) => {
                promises.push(this.asyncCallGeoCoderAddressApi(item, field));
            });
        }

        if(promises && promises.length > 0){
            Promise.all(promises).then((results) => {
                this.setState({
                    isLoadingInitCalls: false,
                });
            }).catch((e) => {
                this.setState({
                    isLoadingInitCalls: false,
                });
            });
        }else{
            this.setState({
                isLoadingInitCalls: false,
            });
        }
    });
  }
  checkCodeApiCalls = (field) => {
    let rows = this.state.rows;
    
    this.setState({
        isLoadingInitCalls: true,
    }, () => {
        const promises = [];

        if(rows && rows.length > 0){
            rows.forEach((item, i) => {
                promises.push(this.asyncCallCheckCompanyCodeApi(item, field));
            });
        }

        if(promises && promises.length > 0){
            Promise.all(promises).then((results) => {
                this.setState({
                    isLoadingInitCalls: false,
                });
            }).catch((e) => {
                this.setState({
                    isLoadingInitCalls: false,
                });
            });
        }else{
            this.setState({
                isLoadingInitCalls: false,
            });
        }
    });
  }

  asyncCallGeoCoderAddressApi = (item, field) => {
    return new Promise(resolve => { 
        this.callGeoCoderAddressApi(item[field], (data) => {
            if(data){
                let coord = {
                    lat: data.lat,
                    lng: data.lng,
                };

                item['coord'] = coord;
                resolve(coord);
            } else {
                item['coord'] = null;
                resolve(null);
            }
        }); 
    });
  }

  asyncCallCheckCompanyCodeApi = (item, field) => {
    return new Promise(resolve => { 
        this.callCheckCompanyCodeApi(item[field], (data) => {
            if(data){
                if(data.exist && data.exist.length > 0){
                    item['isValidCode'] = true;
                    resolve(true);
                } else {
                    item['isValidCode'] = false;
                    resolve(false);
                }
            } else {
                item['isValidCode'] = false;
                resolve(false);
            }
        }); 
    });
  }

  formatDateField = (row, realField, dataType) => {
    let value = (row && realField && row[realField] && row[realField] !== '') ? row[realField] : '';

    if(realField == "Contact No." || realField == "Alternative Contact No.") {
        return value.replaceAll(" ","")
    }
    if(dataType && dataType === 'date'){
        try {
            let date = moment(value);
            return (date.isValid()) ? date.format(apiUtil.getDefaultDateFormat()) : value;
        } catch(e) {
            return value
        }
    } else if(dataType && dataType === 'datetime'){
        try {
            let datetime = moment(value);
            return (datetime.isValid()) ? datetime.format(apiUtil.getDefaultDateFormat() + ' ' + this.state.timeFormat) : value;
        } catch(e) {
            return value
        }
    } else {
        return value
    }
  }

  initChangeColumns = (excelRows = [], type = 1) => {
 
    let rows = [];
    if(excelRows && excelRows.length > 0){
        excelRows.map((row, i) => {
            let rowArray = [];
            let columns = JSON.parse(JSON.stringify(this.state.columns));

            let counter = 0;
            Object.entries(row).map(([ key, value ]) => {
                let realKey = key;
                if(this.state.dataFields && this.state.dataFields[counter] && this.state.dataFields[counter].value){
                    realKey = this.state.dataFields[counter].value;
                };
                let columnsIndex = columns.findIndex(x => x.realField === realKey);
                if(columnsIndex !== -1){
                    if(columns[columnsIndex].realField && columns[columnsIndex].realField !== ''){
                        
                        let dataType = columns[columnsIndex].dataType;
                        let realField = columns[columnsIndex].realField;
                        rowArray[realField] = this.formatDateField(row, realField, dataType);
                        rowArray[realField + '_validated'] = false;

                        columns.splice(columnsIndex, 1);
                    } else {
                        rowArray[realKey] = '';
                        rowArray[realKey + '_validated'] = false;
                    }
                } else {
                    rowArray[realKey] = '';
                    rowArray[realKey + '_validated'] = false;
                }
                
                counter++;
            });
            rows.push(Object.assign({}, rowArray));
        });
    }
    return rows;
   
  }

  changeColumns = (realField, selectedField) => {
    let excelRows = this.state.excelRows;
    let rows = this.state.rows;
    let newRows = [];
    if( realField == "Phone No" && selectedField == "Phone" ){
        if((rows && rows.length > 0) && (excelRows && excelRows.length > 0)){
            newRows = rows.map((row, i) => {
                row[realField] = (excelRows[i][selectedField] && excelRows[i][selectedField] !== '') ? excelRows[i][selectedField].replaceAll(" ","") : '';
                return row;
            });
        } 
    }else{ 
        if((rows && rows.length > 0) && (excelRows && excelRows.length > 0)){
            newRows = rows.map((row, i) => {
                row[realField] = (excelRows[i][selectedField] && excelRows[i][selectedField] !== '') ? excelRows[i][selectedField] : '';
                return row;
            });
        }
    }

    return newRows;
  }

  addStepBtn = () => {
    this.setState({
        isLoadingSteps: true,
    }, () => {
        let rowsSteps = this.state.rowsSteps;
        rowsSteps.push({
            uuid: uuid(),
            address: '',
            name: '',
            signature: '',
            scanning: '',
        });
        this.setState({
            rowsSteps: rowsSteps,
            isLoadingSteps: false,
        });
    });
  }
  addStep = () => {
    let item = '';
    let dataFields = this.state.dataFields;
    let itemIndex = dataFields.findIndex(x => x.label === 'Address');
    let itemIndexAddress = dataFields.findIndex(x => x.col === 'address');
    if(itemIndex !== -1){
        item = dataFields[itemIndex].value;
        dataFields[itemIndexAddress].value = item;
    }

    this.initStepColumns(dataFields, () => {
        this.setState({
            dataFields: dataFields,
        }, () => {
            this.setState({
                isLoadingSteps: true,
            }, () => {
                let rowsSteps = this.state.rowsSteps;
                rowsSteps.push({
                    uuid: uuid(),
                    [item]: item,
                    address: '',
                    name: 'Job Type',
                    job_step_pic: 'Step Person In Charge',
                    job_step_pic_contact: "Step Phone Number",
                    description: "Step Remark",
                    signature: '',
                    scanning: '',
                });
                this.setState({
                    rowsSteps: rowsSteps,
                    isLoadingSteps: false,
                });
            });
        });
    });
  }
  addStepSecond = (label, name, person_in_change, phone_number, remark) => {
    let item = '';
    let dataFields = this.state.dataFields;
    // let itemIndex = dataFields.findIndex(x => x.label === label);
    // let itemIndexAddress = dataFields.findIndex(x => x.col === address);
    // if(itemIndex !== -1){
    //     item = dataFields[itemIndex].value;
    //     dataFields[itemIndexAddress].value = item;
    // }

    this.initStepColumns(dataFields, () => {
        this.setState({
            dataFields: dataFields,
        }, () => {
            this.setState({
                isLoadingSteps: true,
            }, () => {
                let rowsSteps = this.state.rowsSteps;
                rowsSteps.push({
                    uuid: uuid(),
                    label: label,
                    address: label,
                    name: name,
                    job_step_pic: person_in_change,
                    job_step_pic_contact: phone_number,
                    description: remark,
                    signature: '',
                    scanning: '',
                });
                this.setState({
                    rowsSteps: rowsSteps,
                    isLoadingSteps: false,
                });
            });
        });
    });
    
  }
  removeStep = (uuid) => {
    this.setState({
        isLoadingSteps: true,
    }, () => {
        let rowsSteps = this.state.rowsSteps;

        let index = rowsSteps.findIndex(x => x.uuid === uuid);
        if(index !== -1){
            rowsSteps.splice(index, 1);
        }
        
        this.setState({
            rowsSteps: rowsSteps,
            isLoadingSteps: false,
        });
    });
  }
  
  addItem = () => {
    this.setState({
        isLoadingItems: true,
    }, () => {
        let rowsItems = this.state.rowsItems;
        rowsItems.push({
            uuid: uuid(),
            desc: 'Item Name',
            qty: 'Quantity',
            uom: 'UOM',
            sku: 'SKU',
            weight: 'Weight (KG)',
            remarks: 'Remark',
        });
        this.setState({
            rowsItems: rowsItems,
            isLoadingItems: false,
        });
    });
  }
  removeItem = (uuid) => {
    this.setState({
        isLoadingItems: true,
    }, () => {
        let rowsItems = this.state.rowsItems;
        
        let index = rowsItems.findIndex(x => x.uuid === uuid);
        if(index !== -1){
            rowsItems.splice(index, 1);
        }

        this.setState({
            rowsItems: rowsItems,
            isLoadingItems: false,
        });
    });
  }

  getForm(){
    let columns = this.state.columns;
    let rows = this.state.rows;
    let excelRows = this.state.excelRows;

    if(rows && rows.length > 0){
        rows = rows.map((objData, o) => {
            let excelRow = excelRows[o];
            let package_info = {}
            let data_row = {}
            if(columns && columns.length > 0){
                columns.forEach(c => {
                    if (c.title === 'status') {
                        data_row[c.colDB] = objData[c.realField] == "" || objData[c.realField] == null ?  "Available" : objData[c.realField];
                    } else if (c.title === 'Provider') {
                        let exit_provider = []
                        exit_provider = this.state.providers && this.state.providers && this.state.providers.filter(provider => provider.title && provider.title && provider.title.trim() === objData[c.realField] && objData[c.realField] && objData[c.realField].trim());
                        data_row["provider_id"] =  exit_provider.length > 0 ? exit_provider[0].id : null
                    } else {
                        data_row[c.colDB] = objData[c.realField];
                    }
                })
            }
            return data_row;
        });
    }
    
    return rows;
  }
  /* END FUNCTIONS */


  /* TABLE */
  customTableItems = () => {
    // if(!this.state.isLoadingItems){
    //     return <Table 
    //         className={'import-table'}
    //         paging={false}
    //         tableLayout={'fixed'}
    //         data={this.state.rowsItems}
    //         columns={this.state.columnsItems}
    //         isLoading={this.state.isLoadingItems}
    //     />
    // }
  }

  customTableSteps = () => {
    //   if(!this.state.isLoadingSteps){
    //     return <Table 
    //         className={'import-table'}
    //         paging={false}
    //         tableLayout={'fixed'}
    //         data={this.state.rowsSteps}
    //         columns={this.state.columnsSteps}
    //         isLoading={this.state.isLoadingSteps}
    //     />
    //   }
  }

  customTable = () => {
    // return <Table 
    //     className={'import-table has-overflow'}
    //     Header={this.customHeader}
    //     paging={false}
    //     tableLayout={'fixed'}
    //     data={this.state.rows}
    //     columns={this.state.columns}
    //     maxBodyHeight={'500px'}
    // />
  }

  customHeader = (props) => {
    let { isCustomerRequired } = this.context;

    return <thead className={'custom-head-thead'}>
        <tr className={'custom-head-tr'}>{props.columns.map((col, i) => {
                return (col.hidden === undefined ? <td key={i} className={'custom-head-th'} width={col.width}>
                    <Box>
                        {
                            (col.dataType === 'checkbox')
                            ?
                            <React.Fragment>
                                <CustomLabel 
                                    className={"custom-label"}
                                    label={<Box fontWeight={'bold'}>{col.title}{((col.required) ? <span className={'text-danger font-size-18'}>*</span> : '')}</Box>}
                                />
                                {/* <CustomCheckbox
                                    label={(col.checked) ? 'Unmark All' : 'Mark All'}
                                    checked={true}
                                    onChange={(checked) => {
                                        if(this.state.isFirstTime){
                                            this.initRows(() => {
                                                col.checked = checked;
                                                let rows = this.state.rows;
                                                if(rows && rows.length > 0){
                                                    rows.map((item, i) => {
                                                        item[col.field] = checked;
                                                        item[col.colDB] = checked;
                                                        return item;
                                                    });
                                                }
                                                this.setState({
                                                    rows: rows,
                                                });
                                            })
                                        } else {
                                            col.checked = checked;
                                            let rows = this.state.rows;
                                            if(rows && rows.length > 0){
                                                rows.map((item, i) => {
                                                    item[col.field] = checked;
                                                    item[col.colDB] = checked;
                                                    return item;
                                                });
                                            }
                                            this.setState({
                                                rows: rows,
                                            });
                                        }
                                    }}
                                /> */}
                            </React.Fragment>
                            :
                            (col.field === 'Company Code' && !isCustomerRequired) ? "" : ""
                            // <CustomSelectOption
                            //     className={'no-margin white'}
                            //     label={<Box fontWeight={'bold'}>{col.title}{((col.required) ? <span className={'text-danger font-size-18'}>*</span> : '')}</Box>}
                            //     placeholder={'Choose one'}
                            //     initFirstItem={true}
                            //     value={col.field}
                            //     items={this.state.headers}
                            //     onChange={(value, item) => {
                            //         if(this.state.isFirstTime){
                            //             this.initRows(() => {
                            //                 let columns = this.state.columns;
                            //                 let headers = this.state.headers;

                            //                 let selected = col.field;
                                            
                            //                 if(value !== 0){
                            //                     col.field = item.value;
                            //                     selected = col.field;
                            //                     col.isSelected = true;
                            //                 } else {
                            //                     col.field = '';
                            //                     selected = col.realField;
                            //                     col.isSelected = false;
                            //                 }

                            //                 columns[i] = col;
                            //                 let dataFieldsIndex = this.state.dataFields.findIndex(x => x.value === col.realField);
                            //                 if(dataFieldsIndex !== -1){
                            //                     this.dataFieldsPom[dataFieldsIndex].value = selected
                            //                 }
                                            
                            //                 this.setState({
                            //                     columns: columns,
                            //                     headers: headers,
                            //                 }, () => {
                            //                     let rows = this.changeColumns(col.realField, col.field);
                                                
                            //                     this.setState({
                            //                         rows: rows,
                            //                     }, () => {
                            //                         if((col.field !== '') && col.title === 'Address'){
                            //                             this.checkAddressApiCalls(col.realField);
                            //                         } /*else if((col.field !== '') && col.title === 'Company code'){
                            //                             this.checkCodeApiCalls(col.realField);
                            //                         }*/

                            //                         if(col.title === "Address"){
                            //                             let item = '';
                            //                             let dataFields = this.state.dataFields;
                            //                             let itemIndex = dataFields.findIndex(x => x.label === 'Address');
                            //                             let itemIndexAddress = dataFields.findIndex(x => x.col === 'address');
                            //                             if(itemIndex !== -1){
                            //                                 item = col.field;
                            //                                 dataFields[itemIndexAddress].value = col.field;
                            //                             }
                                                        
                            //                             this.initStepColumns(dataFields, () => {
                            //                                 let rowsSteps = this.state.rowsSteps;
                            //                                 if(rowsSteps && rowsSteps.length > 0){
                            //                                     rowsSteps = rowsSteps.map((step, i) => {
                            //                                         step[item] = item;
                            //                                         return step;
                            //                                     });
                            //                                     this.setState({
                            //                                         rowsSteps: rowsSteps,
                            //                                     });
                            //                                 }
                            //                             });
                            //                         }
                            //                     });
                            //                 });
                            //             });
                            //         } else {
                            //             let columns = this.state.columns;
                            //             let headers = this.state.headers;

                            //             let selected = col.field;
                                        
                            //             if(value !== 0){
                            //                 col.field = item.value;
                            //                 selected = col.field;
                            //                 col.isSelected = true;
                            //             } else {
                            //                 col.field = '';
                            //                 selected = col.realField;
                            //                 col.isSelected = false;
                            //             }

                            //             columns[i] = col;

                            //             let dataFieldsIndex = this.state.dataFields.findIndex(x => x.value === col.realField);
                            //             if(dataFieldsIndex !== -1){
                            //                 this.dataFieldsPom[dataFieldsIndex].value = selected
                            //             }
                                        
                            //             this.setState({
                            //                 columns: columns,
                            //                 headers: headers,
                            //             }, () => {
                            //                 let rows = this.changeColumns(col.realField, col.field);
                                            
                            //                 this.setState({
                            //                     rows: rows,
                            //                 }, () => {
                            //                     if((col.field !== '') && col.title === 'Address'){
                            //                         this.checkAddressApiCalls(col.realField);
                            //                     } /*else if((col.field !== '') && col.title === 'Company code'){
                            //                         this.checkCodeApiCalls(col.realField);
                            //                     }*/

                            //                     if(col.title === "Address"){
                            //                         let item = '';
                            //                         let dataFields = this.state.dataFields;
                            //                         let itemIndex = dataFields.findIndex(x => x.label === 'Address');
                            //                         let itemIndexAddress = dataFields.findIndex(x => x.col === 'address');
                            //                         if(itemIndex !== -1){
                            //                             item = col.field;
                            //                             dataFields[itemIndexAddress].value = col.field;
                            //                         }

                            //                         this.initStepColumns(dataFields, () => {
                            //                             let rowsSteps = this.state.rowsSteps;
                            //                             if(rowsSteps && rowsSteps.length > 0){
                            //                                 rowsSteps = rowsSteps.map((step, i) => {
                            //                                     step[item] = item;
                            //                                     return step;
                            //                                 });

                            //                                 this.setState({
                            //                                     rowsSteps: rowsSteps,
                            //                                 });
                            //                             }
                            //                         });
                            //                     }
                            //                 });
                            //             });
                            //         }
                            //     }}
                            // />
                        }
                    </Box>
                </td> : "")
            })}</tr>
    </thead>
  }


  /* FORM */
  isValid = (callback = null) => {
    let state = true;
    let errLatLng = []; 
    let rows = this.state.rows;
    if(rows && rows.length > 0){
        // rows.forEach((row, i) => {
        //     if(!row.crood || !row.crood.lat || !row.crood.lng) {
        //         errLatLng.push(`Latitude or Longtitude is empty at row ${i+15}.`)
        //     }
        // })

        for(let i = 0; i < rows.length; i++){
            if(callback !== null){
                state = callback(state, rows[i]);
                if(!state)
                break;
            }
        };
    }
    return {state , errLatLng};
  }

  setError = () => {
    const { isCustomerRequired } = this.context;
    if (isCustomerRequired) {
      let state = this.isValid((isValidCode = true, item) => {
          if((!item.isValidCode || item.isValidCode === null || item.isValidCode === undefined || item.isValidCode === '') && item.hasCheck){
              isValidCode = false;
          }

          return isValidCode;
      });

      let isSelected = false;
      if(this.state.columns && this.state.columns.length > 0){
          let companyCodeItem = this.state.columns.filter(x => x.title === 'Company code');
          if(companyCodeItem && companyCodeItem.length > 0){
              isSelected = (companyCodeItem[0].field && companyCodeItem[0].field !== '') ? true : false;
          }
      }
      if(isSelected && !state){
          return <Box clone pt={1} pb={1}>
              <Grid item xs={12}>
                  <CustomAlert
                      color={'error'}
                      icon={<FontAwesomeIcon icon={faCircleInfo} />}
                  >
                      Invalid company code. Please create the missing company codes or the system won't be able to import their jobs.
                  </CustomAlert>
              </Grid>
          </Box>
      }
    }
  }

  setNewError = () => {
    const errMessages = this.state.errMessages
      if(errMessages.length > 0) {
          return <Box clone pt={1} pb={1}>
            <Grid item xs={12}>
                {errMessages.map((err) => (
                <Box style={{margin:'1px'}}>
                  <CustomAlert
                      color={'error'}
                      icon={<FontAwesomeIcon icon={faCircleInfo} />}
                  >
                      {err}
                  </CustomAlert>
                  </Box>
                  ))} 
              </Grid>
          </Box>
      } 
    
  }

  setLatLngError = () => {
    const errLatLng = this.state.latLongErr
    if(errLatLng && errLatLng.length > 0) {
          return <Box clone pt={1} pb={1}>
            <Grid item xs={12}>
                {errLatLng.map((err) => (
                <Box style={{margin:'1px'}}>
                  <CustomAlert
                      color={'warning'}
                      icon={<FontAwesomeIcon icon={faCircleInfo} />}
                  >
                      {err}
                  </CustomAlert>
                  </Box>
                  ))} 
              </Grid>
            
              
          </Box>
      } 
    
  }

  setWarning = () => {
    let {state, errLatLng} = this.isValid((isValidCoord = true, item) => {
        
        if(!item.coord || item.coord === null || item.coord === undefined || (!item.coord.lat || !item.coord.lng)){
            isValidCoord = false;
        }

        return isValidCoord;
    });

    let isSelected = false;
    if(this.state.columns && this.state.columns.length > 0){
        let recipientAddressItem = this.state.columns.filter(x => x.title === 'Address');
        if(recipientAddressItem && recipientAddressItem.length > 0){
            isSelected = (recipientAddressItem[0].field && recipientAddressItem[0].field !== '') ? true : false;
        }
    }

    if(isSelected && !state){
        return <><Box clone pt={1} pb={1}>
             <Grid item xs={12}>
                {errLatLng.map((err) => (
                <Box style={{margin:'1px'}}>
                  <CustomAlert
                      color={'warning'}
                      icon={<FontAwesomeIcon icon={faCircleInfo} />}
                  >
                      {err}
                  </CustomAlert>
                  </Box>
                  ))} 
            </Grid>
            </Box>
            <Box clone pt={1} pb={1}>
                <Grid item xs={12}>
                    {/* <CustomAlert
                        color={'warning'}
                        icon={<FontAwesomeIcon icon={faCircleInfo} />}
                    >
                        There are some addresses that we could not recognize. You can still import the job but we would not be able to give coordinates to your driver(s).
                    </CustomAlert> */}
                </Grid>
            </Box></>
    }
    return false
  }

  setForm = () => {
    return <Box>
        <Grid container>
            <Box clone pb={1}>
                <Grid item xs={12}>
                    <CustomLabel
                        className={'no-margin'}
                        label={<Box fontWeight={'bold'}>Match your uploaded sheet headers to our form. Fields marked with <span className={'text-danger font-size-18'}>*</span> is mandatory.</Box>}
                        size={'md'}
                    />
                    <CustomLabel
                        className={'no-margin'}
                        label={'You may perform changes to your customer details in the columns below.'}
                        size={'sm'}
                    />
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12}>
                    {this.customTable()}
                </Grid>
            </Box>
            <Box clone pt={2} pb={1}>
                <Grid item xs={12}>
                    <Box>
                        <Grid container alignItems={'center'}>
                            <Box clone>
                                <Grid item xs={12} md={true}>
                                    <CustomLabel
                                        className={'no-margin'}
                                        label={'JOB STEPS'}
                                        size={'lg'}
                                        weight={'bold'}
                                    />
                                    <CustomLabel
                                        className={'no-margin'}
                                        label={<Box>Each step added here represents an <span style={{ fontWeight: 'bold' }}>step per job</span>. No preview available.</Box>}
                                        size={'md'}
                                    />
                                </Grid>
                            </Box>
                            <Box clone>
                                <Grid item xs={12} md={'auto'}>
                                    <CustomButton 
                                        color={'primary'}
                                        variant={'outlined'}
                                        onClick={() => {
                                            this.addStepBtn();
                                        }}
                                    >
                                        Add Step
                                    </CustomButton>
                                </Grid>
                            </Box>
                        </Grid>
                    </Box>
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12}>
                    {this.customTableSteps()}
                </Grid>
            </Box>
            <Box clone pt={2} pb={1}>
                <Grid item xs={12}>
                    <Box>
                        <Grid container alignItems={'center'}>
                            <Box clone>
                                <Grid item xs={12} md={true}>
                                    <CustomLabel
                                        className={'no-margin'}
                                        label={'ITEMS (PER JOB)'}
                                        size={'lg'}
                                        weight={'bold'}
                                    />
                                    <CustomLabel
                                        className={'no-margin'}
                                        label={<Box>Each item added here represents an <span style={{ fontWeight: 'bold' }}>item per job</span>. No preview available.</Box>}
                                        size={'md'}
                                    />
                                </Grid>
                            </Box>
                            <Box clone>
                                <Grid item xs={12} md={'auto'}>
                                    <CustomButton 
                                        color={'primary'}
                                        variant={'outlined'}
                                        onClick={() => {
                                            this.addItem();
                                        }}
                                    >
                                        Add Item
                                    </CustomButton>
                                </Grid>
                            </Box>
                        </Grid>
                    </Box>
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12}>
                    {this.customTableItems()}
                </Grid>
            </Box>
        </Grid>
    </Box>
  }
  
  setButtons = () => {
    return <Box>
        <Grid container alignItems={'center'} >
            <Box clone textAlign={'right'} pr={2}>
                <Grid item xs={true}>
                    <CustomButton 
                        className="text-dark-cancel"
                        onClick={() => {
                            if(this.props.onClose){
                                this.props.onClose();
                            }
                        }}
                    >
                        Cancel
                    </CustomButton>
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={'auto'}>
                    <CustomButton 
                        type={'submit'}
                        color={'primary'}
                        disabled={this.state.errMessages.length > 0 || this.setWarning() == true ? true : false}
                        isLoading={this.state.isLoading ? this.state.isLoading : this.state.geocoderAddressIsLoading ? this.state.geocoderAddressIsLoading : this.state.customerItemsIsLoading? this.state.customerItemsIsLoading : false}
                    >
                        Confirm
                    </CustomButton>
                </Grid>
            </Box>
        </Grid>
    </Box>
  }

  setIsLoading = () => {
      if(this.state.isLoadingInitCalls || this.state.geocoderAddressIsLoading ){
        return <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'35vh'} width={'100%'}>
            <CircularProgress size={55} />
        </Box>
      } else {
        return <React.Fragment>
            {this.setError()}
            {this.setNewError()}
            {this.setLatLngError()}
            {this.setWarning()}
            <Box clone pt={2} pb={2}>
                <Grid item xs={12}>
                    {/* {this.setForm()} */}
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12}>
                    {this.setButtons()}
                </Grid>
            </Box>
        </React.Fragment>
      }
  }
  /* END FORM */


  /* SUBMIT */
  handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    // this.setState({
    //     isLoading: true,
    // });
    const form = e.currentTarget;
    
    let isSelected = true;
    if(this.state.columns && this.state.columns.length > 0){
        let cols = this.state.columns.filter(x => x.required === true); //x.required === true 
        if(cols && cols.length > 0){
            for(let c = 0; c <= cols.length; c++){
                try {
                    if(cols[c].isSelected !== true){
                        isSelected = false;
                        break;
                    }
                } catch(e){}
            }
        }
    }
    
    if (form.checkValidity() === true && isSelected ) {
        if(this.state.rule){
            let newRule = this.state.rule;
            newRule.json_data = JSON.stringify(this.dataFieldsPom);
        
            this.callUpdateAppSettingsApi(newRule);
        }

        if(this.props.onImport){
            this.setState({
                isLoading : true,
            })
          //this.getForm();
          this.props.onImport(this.getForm());
        }
    } else {
        this.setState({
            isLoading: false
        }, () => {
            
                apiUtil.toast('There are some field(s) which are empty. Please fill in these empty fields to continue.', 'check_circle', 'error');

        });
    }
  }
  /* END SUBMIT */


  render() {
    return <Box>
        <form noValidate autoComplete="off" onSubmit={this.handleSubmit} ref={f => (this.refForm = f)}>
            <Grid container>
                {this.setIsLoading()}
            </Grid>
        </form>
    </Box>
  }
}

export default ImportDialog;