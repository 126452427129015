import React, { Component } from 'react'
import { 
    Box,
    Grid,
    Container,
    Divider,
    InputLabel,
    Select,
    IconButton,
    CircularProgress,
} from '@material-ui/core';
import StickyPageHeader from '../../components/PageHeader/StickyPageHeader.jsx';
import Card from '../../components/Card/Card.jsx';
import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import CustomLabel from '../../components/CustomFields/CustomLabel.jsx';
import CustomInput from '../../components/CustomFields/CustomInput.jsx';
import CustomButton from '../../components/CustomFields/CustomButton.jsx';
import apiUtil from "../../api/apiUtil.jsx";
import authApi from "../../api/api.jsx";

class EditSmsCredential extends Component {
  static contextType = AuthContext;

    constructor(props) {
        super(props)

        this.state = {
            isLoading: false,
            formNumber: '',
            accountSSID: '',
            authToken: '',
            errorStatus:'',
            price: null,
            formNumberValidated: false,
            accountSSIDValidated: false,
            authTokenValidated: false,
            priceValidated: false,
        }

    }

    componentDidMount() {
        this.callReadApi();
    }

    callReadApi = (callback = null) => {
        const accessToken  = window.localStorage.getItem('access_token');

          this.setState({
            isLoading: true
          }, () => {
            authApi.read('sms_credentials/1', null, accessToken)
              .then((result) => {
                apiUtil.parseResult(result, (data) => {                  
                  this.setState({
                    formNumber: data.result.form_number,
                    accountSSID: data.result.account_key,
                    authToken: data.result.account_secret,
                    price: data.result.sms_price,
                    isLoading: false,
      
                  }, () => {
                    if(callback){
                      callback((data && data.result) ? data.result : null);
                    }
                  });
                }, (error, type) => {
                  this.setState({
                    isLoading: false,
                  }, () => {
                    if(callback){
                      callback(null, []);
                    }
                  });
                });
            });
          });
    }

    callUpdateApi = ( id=1, callback = null) => {
        const accessToken  = window.localStorage.getItem('access_token');
        let data = {
            form_number: this.state.formNumber,
            account_key: this.state.accountSSID,
            account_secret: this.state.authToken,
            price: this.state.sms_price
        }
        this.setState({
          isLoading: true
        }, () => {
            authApi.update('sms_credentials/' + id , data, accessToken)
            .then((result) => {
              apiUtil.parseResult(result, (data) => {
                apiUtil.toast('Successfully updated', 'check_circle');
                this.setState({
                  isLoading: false,
                }, () => {
                  if(callback){
                    callback();
                  }
                });
              }, (error, type) => {
                this.setState({
                  isLoading: false,
                }, () => {
                  if(callback){
                    callback();
                  }
                });
              });
          });
        });
      }

    handleSubmit = (e) =>{
        e.preventDefault();
        e.stopPropagation();

        this.setState({
            isLoading: true,
        })
        
        const form = e.currentTarget;
        this.setState({
            formNumberValidated: false,
            accountSSIDValidated: false,
            authTokenValidated: false,
            priceValidated: false
        })

        if(this.state.formNumber === '') {
            this.setState({
                formNumberValidated: true,
            })
        }
        if(this.state.accountSSID === '') {
            this.setState({
                accountSSIDValidated: true,
            })
        }

        if(this.state.authToken === '') {
            this.setState({
                authTokenValidated: true,
            })
        }

        if(this.state.price === '') {
          this.setState({
              priceValidated: true,
          })
      }


        if(form.checkValidity() === true) {
            this.callUpdateApi();

        } else {
            this.setState({
                isLoading: false,
            })
        }

    }

    /* STICKY PAGE HEADER */
  customStickyPageHeader = () => {

    return <Box clone pl={4} pr={0} height={'100%'} alignItems={'center'}>
      <Grid container justify="flex-end">
        
        <Box clone>
          <Grid item xs={'auto'}>
       
            <CustomButton 
              type={'submit'}
              color={'primary'}
              isLoading={this.state.isLoading}
            ><Box pl={2} pr={2}>Submit Form</Box>
            </CustomButton>
          
       
          </Grid>
        </Box>
      </Grid>
    </Box>
  }
  /* END STICKY PAGE HEADER */

    render() {
        return <Box>
            <form noValidate autoComplete="off" onSubmit={this.handleSubmit}>
                {/* <StickyPageHeader isSmall={true}>
                    {this.customStickyPageHeader()}
                </StickyPageHeader> */}
                <Card>
                <Grid container>
                    <Grid item xs={12}>
                        <CustomLabel
                            label={'SMS Credential'}
                            weight={'bold'}
                            size={'lg'}
                        />
                        <Divider light />
                    </Grid>
                    <Grid item xs={12}>
                        <Container maxWidth={'xs'}>
                            <Box clone pt={5}>
                                <Grid container>
                                    <Box clone>
                                        <Grid item xs={12}>
                                            <CustomInput
                                            label={"Form Number"}
                                            placeholder={'Enter Form Number'}
                                            error={this.state.formNumberValidated}
                                            required={true}
                                            value={this.state.formNumber}
                                            onChange={(e) => {
                                                this.setState({
                                                    formNumber: e.target.value,
                                                    formNumberValidated: (e.target.value !== '') ? false : true,
                                                });
                                            }}
                                            />
                                        </Grid>
                                    </Box>
                    
                                    <Box clone>
                                        <Grid item xs={12}>
                                            <CustomInput
                                            label={"Account SSID"}
                                            placeholder={'Enter Account SSID'}
                                            error={this.state.accountSSIDValidated}
                                            required={true}
                                            value={this.state.accountSSID}
                                            onChange={(e) => {
                                                this.setState({
                                                    accountSSID: e.target.value,
                                                    accountSSIDValidated: (e.target.value !== '') ? false : true,
                                                });
                                            }}
                                            />
                                        </Grid>
                                    </Box>
                                    <Box clone>
                                        <Grid item xs={12}>
                                            <CustomInput
                                            label={"Auth Token"}
                                            placeholder={'Enter Auth Token'}
                                            error={this.state.authTokenValidated}
                                            required={true}
                                            value={this.state.authToken}
                                            onChange={(e) => {
                                                this.setState({
                                                    authToken: e.target.value,
                                                    authTokenValidated: (e.target.value !== '') ? false : true,
                                                });
                                            }}
                                            />
                                        </Grid>
                                    </Box>
                                    <Box clone>
                                        <Grid item xs={12}>
                                            <CustomInput
                                            label={"Price"}
                                            type="number"
                                            placeholder={'per SMS'}
                                            error={this.state.priceValidated}
                                            required={true}
                                            value={this.state.price}
                                            onChange={(e) => {
                                                this.setState({
                                                    price: e.target.value,
                                                    priceValidated: (e.target.value !== '') ? false : true,
                                                });
                                            }}
                                            />
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Box>
                            <Box>
                            {this.customStickyPageHeader()}
                            </Box>
                        </Container>
                    </Grid>
                    </Grid>
                </Card>
            </form>
        </Box>
    }

    

}

export default EditSmsCredential;