import React, { Component } from 'react';
import BreadCrumb from "../../components/Navs/Breadcrumb"
import {
    Box,
    Grid,
    InputLabel,
    Button,
    FormGroup,
    FormLabel,
    Checkbox,
    Modal,
    Table,
    Typography,
} from '@material-ui/core';
import moment from 'moment';
import Card from '../../components/Card/Card.jsx';
import DataTable from '../../components/Table/Table.jsx';
import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import axios from 'axios';
import HtmlParser from 'react-html-parser';
import CustomButton from '../../components/CustomFields/CustomButton.jsx';
import apiUtil from "../../api/apiUtil.jsx";
import authApi from "../../api/api.jsx";
import CustomDialog from '../../components/Dialog/CustomDialog.jsx';
// import Typography from '@mui/material/Typography';
// import Modal from '@mui/material/Modal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #565553',
    boxShadow: 24,
    p: 4,
  };

class Dashboard extends Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);

        this.refTable = React.createRef();
        this.refTableSearch = React.createRef();

        let hiddenColumns = [];
        let columnHiddenSettingsId = 0;
        let columnHiddenSettings = JSON.parse(localStorage.getItem('job_column_settings'));

        if (columnHiddenSettings !== null) {
            hiddenColumns = columnHiddenSettings.json_data.replace(/[^0-9a-zA-Z-_.,]/g, "").split(",");
            columnHiddenSettingsId = columnHiddenSettings.id ? columnHiddenSettings.id : 0;
        }
        this.state = {
            name: '',
            is_enabled: false,
            description: '',
            start_date: '',
            end_date: '',
            data: [],
            data_companies: [],
            isLoadingStatus: false,
            status: [],
            page: 1,
            errorStatus:'',
            total: 2,
            invoice: false,
            invoice_detail: true,
            limit: 10,
            isLoadingStatus: false,
            delete_modal: false,
            pageSize: 22,      // status: [],
            hiddenColumnSettingId: columnHiddenSettingsId,
            hiddenColumns: hiddenColumns,
            id: this.props.location.state.id,
            openDialog: false,
            openDialogItem: null,
        }
    }
    componentDidMount() {
        if (this.state.id != '') {
            this.callReadApi();
        }
    }

    handleOpen () {
        this.setState({
            delete_modal: true
        })
    }
    handleClose () {
        this.setState({
            delete_modal: false
        })
    }

    callReadApi() {

        let errorMessage = "";
        let status = 0;
        const {
            REACT_APP_AUTH_API_URL
        } = process.env;
        let dataid = this.state.id;
        const PATH = 'admin/api/';
        const api = `free_accounts/${dataid}`;
        const URL = REACT_APP_AUTH_API_URL + PATH + api;
        let token = window.localStorage.getItem('access_token');

        let param = {
            // application_company_id: dataid,
            // take: this.state.limit,
            // search: "",
            // start_date: startDate,
            // end_date: endDate,
            // sorting_array: ['created_at DESC'],
        };
        axios.get(
            // "https://auth-api.worknode.ai/admin/api/application_companies?search=&page=1&take=10&sorting_array[]=created_at%20DESC",
            URL,
            {
                params: param,
                // data: param,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .catch(error => {
                try {
                    status = error.response.status;
                    this.setState({
                        errorStatus : status
                      })
                      if(this.state.errorStatus == 401) {
                        const { clearStorage } = this.context;
                        this.setState({
                          profileMenu: null,
                          isDrawerOpen: false
                        });
                        clearStorage();
                        window.location = "/";
                      } else {
                        if (error.response) {
                            // Request made and server responded
                            errorMessage = error.response.data;
                        } else if (error.request) {
                            // The request was made but no response was received
                            errorMessage = error.request;
                        } else {
                            // Something happened in setting up the request that triggered an Error
                            errorMessage = error.message;
                        }
                    }
                } catch (err) {
                    errorMessage = 'Service Unavailable!'
                }
            }).then((response) => {
                if (response != null) {

                    var json_data = response.data.result;
                    var result = [];
                    for (var i in json_data) {
                        result[i] = json_data[i];
                    }

                    this.setState({
                        // data: response.data.result,
                        total: response.data.total,
                        isLoading: false,
                        data: result,
                        data_companies: response.data.result.application_companies,
                        name: result.name,
                        is_enabled: result.is_enabled,
                        start_date: result.start_date,
                        end_date: result.end_date,
                    });

                    console.log("response qs", this.state.data_companies);
                    console.log("response sas", this.state.data);
                    console.log("response id", this.state.data.id);
                }

            });
    }

    callCreateApi() {

        let errorMessage = "";
        let status = 0;
        const {
            REACT_APP_AUTH_API_URL
        } = process.env;
        // let dataid = this.state.id;
        const PATH = 'admin/api/';
        const api = `free_accounts`;
        const data = {
            name: this.state.name,
            is_enabled: this.state.is_enabled,
            description: this.state.description,
            start_date: this.state.start_date,
            end_date: this.state.end_date,
            id: '',
            created_at: '',
            updated_at: '',
        };
        console.log("hell0o", data);
        const URL = REACT_APP_AUTH_API_URL + PATH + api;
        let token = window.localStorage.getItem('access_token');

        let param = {
            // application_company_id: dataid,
            // take: this.state.limit,
            // search: "",
            // start_date: startDate,
            // end_date: endDate,
            // sorting_array: ['created_at DESC'],
        };
        axios.post(
            // "https://auth-api.worknode.ai/admin/api/application_companies?search=&page=1&take=10&sorting_array[]=created_at%20DESC",
            URL,
            data,
            {
                params: param,
                // data: param,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .catch(error => {
                try {
                    status = error.response.status;

                    if (error.response) {
                        // Request made and server responded
                        errorMessage = error.response.data;
                    } else if (error.request) {
                        // The request was made but no response was received
                        errorMessage = error.request;
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        errorMessage = error.message;
                    }
                } catch (err) {
                    errorMessage = 'Service Unavailable!'
                }
            }).then((response) => {
                apiUtil.toast('Successfully created', 'check_circle');
                this.props.history.push('/free-account');
            });
    }

    callUpdateApi(id) {

        // let date = moment(this.state.end_date);
        if (moment(this.state.end_date).isAfter(moment())) {
           
        } else {
            apiUtil.toast('End Date is less than today', 'warning', 'error');
            return
        }
        let errorMessage = "";
        let status = 0;
        const {
            REACT_APP_AUTH_API_URL
        } = process.env;
        // let dataid = this.state.id;
        const PATH = 'admin/api/';
        const api = `free_accounts/${id}`;
        const data = {
            name: this.state.name,
            is_enabled: this.state.is_enabled,
            description: this.state.description,
            start_date: this.state.start_date,
            end_date: this.state.end_date,
            id: '',
            created_at: '',
            updated_at: '',
        };
        console.log("hell0o", data);
        console.log("hell0o", api);
        const URL = REACT_APP_AUTH_API_URL + PATH + api;
        let token = window.localStorage.getItem('access_token');

        let param = {
            // application_company_id: dataid,
            // take: this.state.limit,
            // search: "",
            // start_date: startDate,
            // end_date: endDate,
            // sorting_array: ['created_at DESC'],
        };
        axios.put(
            // "https://auth-api.worknode.ai/admin/api/application_companies?search=&page=1&take=10&sorting_array[]=created_at%20DESC",
            URL,
            data,
            {
                params: param,
                // data: param,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .catch(error => {
                try {
                    status = error.response.status;

                    if (error.response) {
                        // Request made and server responded
                        errorMessage = error.response.data;
                    } else if (error.request) {
                        // The request was made but no response was received
                        errorMessage = error.request;
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        errorMessage = error.message;
                    }
                } catch (err) {
                    errorMessage = 'Service Unavailable!'
                }
            }).then((response) => {
                apiUtil.toast('Successfully updated', 'check_circle');
                this.props.history.push('/free-account');
            });
    }

    callDeleteApi(id) {

        let errorMessage = "";
        let status = 0;
        const {
            REACT_APP_AUTH_API_URL
        } = process.env;
        // let dataid = this.state.id;
        const PATH = 'admin/api/';
        const api = `free_accounts/${id}`;
        const URL = REACT_APP_AUTH_API_URL + PATH + api;
        let token = window.localStorage.getItem('access_token');

        axios.delete(
            // "https://auth-api.worknode.ai/admin/api/application_companies?search=&page=1&take=10&sorting_array[]=created_at%20DESC",
            URL,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .catch(error => {
                try {
                    status = error.response.status;

                    if (error.response) {
                        // Request made and server responded
                        errorMessage = error.response.data;
                    } else if (error.request) {
                        // The request was made but no response was received
                        errorMessage = error.request;
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        errorMessage = error.message;
                    }
                } catch (err) {
                    errorMessage = 'Service Unavailable!'
                }
            }).then((response) => {
                apiUtil.toast('Successfully deleted', 'check_circle');
                this.props.history.push('/free-account');
                // this.callReadApi();
            });
    }

    customStickyPageHeader = () => {
        return <Box clone pl={4} pr={4} height={'100%'} alignItems={'center'}>
            <Grid container>
                <BreadCrumb />
            </Grid>
        </Box>
    }

    callfreeAccountputApi = (id, action , callback = null) => {

        let errorMessage = "";
        let status = 0;
        const {
          REACT_APP_AUTH_API_URL
        } = process.env;
        const PATH = 'admin/api/';
        const api = `free_accounts/${this.state.id}`;
        const param = {
          application_company_id: id,
          company_action: action,
        };
    
        const URL = REACT_APP_AUTH_API_URL + PATH + api;
        let token = window.localStorage.getItem('access_token');
    
        axios.put(
          // "https://auth-api.worknode.ai/admin/api/application_companies?search=&page=1&take=10&sorting_array[]=created_at%20DESC",
          URL,
          param,
          {
            
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token
            }
          })
          .catch(error => {
            try {
              status = error.response.status;
    
              if (error.response) {
                // Request made and server responded
                errorMessage = error.response.data;
              } else if (error.request) {
                // The request was made but no response was received
                errorMessage = error.request;
              } else {
                // Something happened in setting up the request that triggered an Error
                errorMessage = error.message;
              }
            } catch (err) {
              errorMessage = 'Service Unavailable!'
            }
          }).then((response) => {
            if(callback) {
              callback()
            }
            this.callReadApi();
            
          });
      }
      callPromotionPutApi = (id, action, callback = null) => {

        let errorMessage = "";
        let status = 0;
        const {
          REACT_APP_AUTH_API_URL
        } = process.env;
        const PATH = 'admin/api/';
        const api = `promotions/${this.state.id}`;
        const param = {
          application_company_id: id,
          company_action: action,
        };
    
        const URL = REACT_APP_AUTH_API_URL + PATH + api;
        let token = window.localStorage.getItem('access_token');
    
        axios.put(
          // "https://auth-api.worknode.ai/admin/api/application_companies?search=&page=1&take=10&sorting_array[]=created_at%20DESC",
          URL,
          param,
          {
            
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token
            }
          })
          .catch(error => {
            try {
              status = error.response.status;
    
              if (error.response) {
                // Request made and server responded
                errorMessage = error.response.data;
              } else if (error.request) {
                // The request was made but no response was received
                errorMessage = error.request;
              } else {
                // Something happened in setting up the request that triggered an Error
                errorMessage = error.message;
              }
            } catch (err) {
              errorMessage = 'Service Unavailable!'
            }
          }).then((response) => {
            if(callback) {
              callback()
            }

            this.callRuleReadApi();
          });
      }

    freeAccount() {
        var created_at = this.state.data.created_at ?  new Date(this.state.data.created_at) : "";
        var updated_at = this.state.data.updated_at ? new Date(this.state.data.updated_at) : "";
        console.log(created_at,updated_at)

        return <Box>
            <FormGroup>
                <Grid container spacing={2} style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                    <Grid item xs={4}>
                        <FormLabel>ID </FormLabel>
                        <FormLabel> {this.state.data.id ? this.state.data.id : ''}</FormLabel>
                    </Grid>
                    <Grid item xs={4}>
                        <FormLabel>Created Date : </FormLabel>
                        <FormLabel>{created_at ? created_at.toLocaleDateString() : ''}</FormLabel>
                    </Grid>
                    <Grid item xs={4}>
                        <FormLabel>Updated Date : </FormLabel>
                        <FormLabel>{updated_at ? updated_at.toLocaleDateString() : ''}</FormLabel>
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                    <Grid item xs={6}>
                        <InputLabel style={{ paddingBottom: '10px' }}>Promotion Name : </InputLabel>
                        <input type="text" style={{ height: '35px', width: '100%', border: '1px solid #DDDDDD' }}
                            onChange={(e) => this.setState({ name: e.target.value })}
                            defaultValue={this.state.data.name ? this.state.data.name : ""}
                        />
                    </Grid>
                    <Grid item xs={6}>
                    <InputLabel style={{ paddingBottom: '10px' }}>
                        <Checkbox 
                        checked={this.state.is_enabled}
                        onChange={(checked)=> {
                          this.setState({
                            is_enabled:!this.state.is_enabled
                          })
                        }}
                        />Enable </InputLabel>
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                    <Grid item xs={12}>
                        <InputLabel style={{ paddingBottom: '10px' }}>Discription: </InputLabel>
                        <textarea type="text" rows="5" style={{ height: '35px', width: '100%', border: '1px solid #DDDDDD' }}
                            onChange={(e) => this.setState({ description: e.target.value })}
                            defaultValue={this.state.data.description ? this.state.data.description : ""}
                        ></textarea>
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                    <Grid item xs={4}>
                        <InputLabel style={{ paddingBottom: '10px' }}>Date Start: </InputLabel>
                        <input type="date" style={{ height: '35px', width: '100%', border: '1px solid #DDDDDD' }}
                            onChange={(e) => this.setState({ start_date: e.target.value })}
                            defaultValue={this.state.data.start_date ? this.state.data.start_date : ""} />
                    </Grid>
                    <Grid item xs={4}>
                        <InputLabel style={{ paddingBottom: '10px' }}>Date End: </InputLabel>
                        <input type="date"
                            defaultValue={this.state.data.end_date ? this.state.data.end_date : ""}
                            onChange={(e) => this.setState({ end_date: e.target.value })}
                            style={{ height: '35px', width: '100%', border: '1px solid #DDDDDD' }} />
                    </Grid>
                </Grid> 
                <Grid container spacing={2} style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                    <Grid item xs={12}>
                        {!this.state.data.id ? (
                            <Button style={{ backgroundColor: '#2e86aa' }}
                                onClick={() => {
                                    this.callCreateApi();
                                }}
                            >
                                <span style={{ color: '#FFFFFF' }}>Create</span>
                            </Button>
                        ) : (
                            <>
                                <Button style={{ backgroundColor: '#2e86aa' }}
                                    onClick={() => {
                                        this.callUpdateApi(this.state.data.id);
                                    }}
                                >
                                    <span style={{ color: '#FFFFFF' }}>Save</span>
                                </Button>
                                <Button style={{ backgroundColor: '#CC1919', marginLeft: '20px' }}
                                    onClick={() => {
                                        this.handleOpen()
                                    }}
                                >
                                    <span style={{ color: '#FFFFFF' }}>Delete</span>
                                </Button>
                            </>
                        )
                        }
                    </Grid>
                </Grid>
            </FormGroup>
            <Modal
                open={this.state.delete_modal}
                onClose={() => {this.handleClose()}}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography style={{ color: '#ff726f'}} id="modal-modal-title" variant="h6" component="h2">
                        Warning Delete!!
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Are you sure you want to delete?
                    </Typography>
                    <Box style={{ marginTop:'20px', float: "right" }}>
                    <Button style={{ backgroundColor: '#565553', marginLeft: '20px' }}
                                    onClick={() => {
                                        this.handleClose()
                                    }}
                                >
                                    <span style={{ color: '#FFFFFF' }}>cancel</span>
                                </Button>
                    <Button style={{ backgroundColor: '#CC1919', marginLeft: '20px' }}
                                    onClick={() => {
                                        this.callDeleteApi(this.state.data.id)
                                        this.handleClose()
                                    }}
                                >
                                    <span style={{ color: '#FFFFFF' }}>Delete</span>
                     </Button>
                     </Box>
                </Box>
            </Modal>
        </Box>
    }

    customPromotionDialog = () => {
        return <CustomDialog 
          open={this.state.openDialog}
          title={'Remove'}
          onClose={() => {
            this.setState({
              openDialog: false,
              openDialogItem: null,
            });
          }}
          onOk={() => {
            let data = this.state.openDialogItem;
            if(data && data.type == 'freeAccount') {
              // this.callfreeAccountputApi(data.id, 'remove')
              this.callfreeAccountputApi(data.id, 'remove', () => {
                this.setState({
                  openDialog: false,
                  openDialogItem: null,
                }, () => {
                  // this.callReadApi(this.refTableSearch.current.state.value);
                  apiUtil.toast('Successfully remove', 'check_circle');
                });
              });
            } else if(data && data.type == 'promotion') {
              // this.callPromotionPutApi(data.id, 'remove')
              this.callPromotionPutApi(data.id, 'remove', () => {
                this.setState({
                  openDialog: false,
                  openDialogItem: null,
                }, () => {
                  // this.callReadApi(this.refTableSearch.current.state.value);
                  apiUtil.toast('Successfully remove', 'check_circle');
                });
              });
            }
            
          }}
        >
          <Box>Are you sure you want to remove?</Box>
        </CustomDialog>
      }

    companies() {
        return <>
            <h2>Companies</h2>
            
            {this.state.data_companies.length > 0 ?
            <Table >
                <tr style={{ backgroundColor: "#565553", height: "50px" }}>
                <th style={{ color: "#FFFFFF" }}>ID</th>
                <th style={{ color: "#FFFFFF" }}>Company Name</th>
                <th style={{ color: "#FFFFFF" }}>Email</th>
                {/* <th style={{ color: "#FFFFFF" }}>End Date</th> */}
                <th style={{ color: "#FFFFFF" }}>Actions</th>
                </tr>
                {this.state.data_companies.map((data) =>
                <tr style={{ textAlign: "center", height: "50px" }}>
                    <td>{data.id}</td>
                    <td>{data.company_name}</td>
                    <td>{data.email}</td>
                    {/* <td>{data.end_date}</td> */}
                    <td> <Button style={{ backgroundColor: '#FF4500' }}
                    onClick={() => {
                        
                        this.setState({
                        openDialog: true,
                        openDialogItem: {
                            id:data.id,
                            type:'freeAccount'
                        }
                        })
                        // this.callPromotionPutApi(data.id, 'remove')
                        
                    }}
                    >Remove</Button>
                    </td>
                </tr>
                )}
            </Table> : " no company list"
        }
        </>
    }

    render() {
        return <Box className="dashboard-page" >

            <Card className={'job-listing-step'}>
                {this.freeAccount()}
            </Card>

            <Card className={'job-listing-step'}>
                {this.companies()}
            </Card>
            {this.customPromotionDialog()}
        </Box>;
    }
}

export default Dashboard;
