import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";

import { 
  Box,
  List,
  ListItem,
} from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';

// import Icon from '@material-ui/core/Icon';
import logoTextWhite from '../../assets/img/logo_white.png';

import { ReactComponent as ArrowRightIcon } from '../../assets/img/left_menu/arrow-right.svg';
import { ReactComponent as ArrowDownIcon } from '../../assets/img/left_menu/arrow-down.svg';
import { ReactComponent as DashboardIcon } from '../../assets/img/left_menu/dashboard.svg';
import { ReactComponent as JobsIcon } from '../../assets/img/left_menu/jobs.svg';
import { ReactComponent as LiveTrackingIcon } from '../../assets/img/left_menu/live-tracking.svg';
import { ReactComponent as CustomersIcon } from '../../assets/img/left_menu/customers.svg';
import { ReactComponent as LocationIcon } from '../../assets/img/left_menu/vendor.svg';
import { ReactComponent as ReportIcon } from '../../assets/img/left_menu/report.svg';

import { Select } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import WMSIcon from '../../assets/img/services-icon1.png';
import LDSIcon from '../../assets/img/services-icon2.png';
import {RoleAccessService} from '../../data/role-access'; 
const CustomMenuItem = (props) => {
  let {text, icon, url, active, items, onDrawerToggle} = props;
  let [open, setOpen] = React.useState(active);

  return <React.Fragment>
    <ListItem
      // onClick = {() => onDrawerToggle? onDrawerToggle(): ""}
      className={'custom-item ' + ((typeof active === "function") ? active() : (active ? "active" : ""))}
    >
      <Link to={(items && items.length > 0) ? "" : url}
        onClick={(e) => {
          // e.stopPropagation();

          if(items && items.length > 0){
            e.preventDefault();
            setOpen(!open);
            
            if(onDrawerToggle){
              onDrawerToggle()
            }
          }
        }}
      >
        {icon ? icon : <span className={'no-icon'}></span>}
        <span>{text}</span>
        {(items && items.length > 0) && (open ? <ArrowDownIcon className={'custom-expand-down'} /> : <ArrowRightIcon className={'custom-expand-right'} />)}
      </Link>
    </ListItem>
    {(items && items.length > 0) && <Collapse in={open} timeout="auto" unmountOnExit>
      <List className={'sub-nav'} component="div" disablePadding>
        {items.map((item, i) => {
          return <React.Fragment key={i}>
            {item}
          </React.Fragment>;
        })}
      </List>
    </Collapse>}
  </React.Fragment>
}


class LeftMenu extends Component {
  static contextType = AuthContext;
  
  validURL = (str) => {
    let pattern = new RegExp('^(https?:\\/\\/)?'+
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+
      '((\\d{1,3}\\.){3}\\d{1,3}))'+
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+
      '(\\?[;&a-z\\d%_.~+=-]*)?'+
      '(\\#[-a-z\\d_]*)?$','i');
    return !!pattern.test(str);
  }

  handleServiceChange = (e) => {
    let url = e.target.value;
    if (!this.validURL(url)) return false;
    window.location.replace(url);
  } 

  

  render() {
    return <Box>
      <List component="nav">
        <div className={this.props.className + ' custom-logo'}>
          <Link to={'/'}>
            <img src={logoTextWhite} alt={'WorkNode'} width={'100%'} />
          </Link>
        </div>

        <hr />

        {<CustomMenuItem
          onDrawerToggle={this.props.onDrawerToggle} 
          text={'Dashboard'}
          icon={<DashboardIcon />}
          url={'/dashboard'}
          active={(this.props.location.pathname === "/dashboard" ? true : false)}
        />
        }

        {<CustomMenuItem
          onDrawerToggle={this.props.onDrawerToggle} 
          text={'Promotion'}
          icon={<JobsIcon />}
          url={'/promotion'}
          active={((this.props.location.pathname === "/promotion") ? true : false)}
        />
        }

        {<CustomMenuItem
          onDrawerToggle={this.props.onDrawerToggle} 
          text={'Free Account'}
          icon={<CustomersIcon />}
          url={'/free-account'}
          active={(this.props.location.pathname === "/free-account" ? true : false)}
        />
        }
        {<CustomMenuItem
          onDrawerToggle={this.props.onDrawerToggle} 
          text={'Charging Location'}
          icon={<LocationIcon />}
          url={'/charging-location'}
          active={(this.props.location.pathname === "/charging-location" || this.props.location.pathname === "/manual-location" || this.props.location.pathname === "/charging-location-list" ? true : false)}
        />
        }
         {<CustomMenuItem
          onDrawerToggle={this.props.onDrawerToggle} 
          text={'Provider'}
          icon={<LocationIcon />}
          url={'/provider'}
          active={(this.props.location.pathname === "/provider" ? true : false)}
        />
        }
        {/* {<CustomMenuItem
          onDrawerToggle={this.props.onDrawerToggle} 
          text={'Edit SMS'}
          icon={<ReportIcon />}
          url={'/edit-sms-credential'}
          active={(this.props.location.pathname === "/edit-sms-credential" ? true : false)}
        />
        } */}
        {<CustomMenuItem
          // onDrawerToggle={this.props.onDrawerToggle} 
          text={'SMS'}
          icon={<ReportIcon />}
          active={() => {
            if(this.props.location.pathname === "/request-sms") {
              return true
            } else if(this.props.location.pathname === "/record-sms") {
              return true
            } else if(this.props.location.pathname === "/edit-sms-credential") {
              return true
            } else {
              return false;
            }
          }}

          //items list of resource of new enhancement role
          // items={this.getResourceItemListForNewRole(currentRole)}  
          items={[
            <CustomMenuItem
              onDrawerToggle={this.props.onDrawerToggle}   
              text={'SMS Credential'}
              url={'/edit-sms-credential'}
              active={(this.props.location.pathname === "/edit-sms-credential" ? true : false)}
            />,
            <CustomMenuItem
              onDrawerToggle={this.props.onDrawerToggle} 
              text={'Requested SMS'}
              url={'/request-sms'}
              active={(this.props.location.pathname === "/request-sms" ? true : false)}
            />,
            <CustomMenuItem
              onDrawerToggle={this.props.onDrawerToggle} 
              text={'SMS Usage'}
              url={'/sms-usage'}
              active={(this.props.location.pathname === "/sms-usage" ? true : false)}
            />,
            
          ] }       
        />}
        {<CustomMenuItem
          onDrawerToggle={this.props.onDrawerToggle} 
          text={'Route Optimisation'}
          icon={<LocationIcon />}
          url={'/route-optimization'}
          active={(this.props.location.pathname === "/route-optimization" ? true : false)}
        />
        }
      </List>
    </Box>
  }

  
}

export default withRouter(LeftMenu);
