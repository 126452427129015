import React, { Component } from 'react';
import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import axios from 'axios';
import apiUtil from "../../api/apiUtil.jsx";
import { withRouter, Link } from 'react-router-dom';
import { Container, Row, Col, Button, Card, Modal } from 'react-bootstrap';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import ChargingTemplate from "../../assets/files/ChargingStations.xlsx"
import { saveAs } from "file-saver";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpFromBracket, faDownload, faFileLines } from '@fortawesome/free-solid-svg-icons'
import CustomDialog from '../../components/Dialog/CustomDialog.jsx';
import ImportDialog from './ImportantDialog.jsx';
import CustomButton from '../../components/CustomFields/CustomButton.jsx';
import CustomInput from "../../components/CustomFields/CustomInput.jsx";


class ChargingLocation extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);

    this.refTable = React.createRef();
    this.fileInput = React.createRef();
    this.state = {
      data :  [],
      providers: [],
      title: '',
      titleValidate: false,
      address: '',
      addressValidate: false,
      latitude: '',
      latitudeValidate: false,
      longitude: '',
      longitudeValidate: false,
      ac_chargers: '',
      dc_chargers: '',
      provider_id: '',
      postal_code:'',
      postal_codeValidate: false, 
      formValidate: true,
      status: 'Available',
      exclude: '',
      excle_file: null,
      openImportDialog: false,
      openImportDialogItem: null,
    }
  }
  componentDidMount() {
    this.callReadApi();
  }

  callReadApi(){
    let errorMessage = "";
    let status = 0;
    const { 
    REACT_APP_AUTH_API_URL
    } = process.env;
    const PATH = 'api/';
    const api = "providers";
    const URL = REACT_APP_AUTH_API_URL + PATH + api;
    let token = window.localStorage.getItem('access_token');

    let param = {
    page: this.state.page,
    take: this.state.limit,
    search: "",
    sorting_array: ['created_at DESC'],
    };
    this.setState({
    isLoading:true
    }, () => {
    axios.get(
            URL,
            { 
                params: param,
                data: param,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .catch( error => {
                try {
                    status = error.response.status;
                    this.setState({
                    errorStatus : status
                    })
                    if(this.state.errorStatus == 401) {
                    const { clearStorage } = this.context;
                    this.setState({
                        profileMenu: null,
                        isDrawerOpen: false
                    });
                    clearStorage();
                    window.location = "/";
                    } else {
                    if (error.response) {
                        // Request made and server responded
                        errorMessage = error.response.data;
                    } else if (error.request) {
                        // The request was made but no response was received
                        errorMessage = error.request;
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        errorMessage = error.message;
                    }
                    }
                } catch(err) {
                    errorMessage = 'Service Unavailable!'
                }
                this.setState({
                isLoading:false
                })
            }).then((response) => {
            // console.log(response);
            if(response != null){
            this.setState({
                providers: response.data.result,
                total: response.data.total,
                isLoading: false,
            });
            }
            });
    })
    
}
  /* IMPORT API */
  callImportApi = (row, callback = null) => {

      this.setState({
        isLoading: true
      }, () => {
        let errorMessage = "";
        let status = 0;
        const {
            REACT_APP_AUTH_API_URL
        } = process.env;
 
        const PATH = 'api/';
        const api = `charging_stations_import`;
        const data = row
        const URL = REACT_APP_AUTH_API_URL + PATH + api;
        let token = window.localStorage.getItem('access_token');

        let param = {
            // application_company_id: dataid,
            // take: this.state.limit,
            // search: "",
            // start_date: startDate,
            // end_date: endDate,
            // sorting_array: ['created_at DESC'],
        };
        axios.post(
            // "https://auth-api.worknode.ai/admin/api/application_companies?search=&page=1&take=10&sorting_array[]=created_at%20DESC",
            URL,
            data,
            {
                params: param,
                // data: param,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .then((response) => {
                apiUtil.toast('Successfully created', 'check_circle');
                this.props.history.push('/charging-location-list');
            }).catch(error => {
                try {
                    status = error.response.status;
                    
                    if (status != 200) {
                        error.response && error.response.data?.error != null && error.response.data?.error != '' ?
                        apiUtil.toast(error.response.data.error, 'check_circle')
                        :
                        apiUtil.toast("unsuccessful attempt, please try again!", 'check_circle') 

                        this.setState({
                            openImportDialog: false,
                            openImportDialogItem: null,
                            excle_file: '',
                            data_form: [],
                            isLoading: false
                          });
                          this.fileInput.current.value = '';
                        return
                    }
                    if (error.response) {
                        // Request made and server responded
                        errorMessage = error.response.data;
                    } else if (error.request) {
                        // The request was made but no response was received
                        errorMessage = error.request;
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        errorMessage = error.message;
                    }
                } catch (err) {
                    errorMessage = 'Service Unavailable!'
                }
            });
      });
    }
    /* END IMPORT API */

    jobDetailsForm = () => {
        return <Container>
            <Row className="max-xs-width-control-900 p-4">
              <Col xs={12} md={6} className="max-xs-width-control-900 d-flex  align-items-center py-2">
                <div className="max-xs-width-control-900 text-end w-50">
                  Title
                </div>
                <div className="max-xs-width-control-900 ps-2 w-50">
                    <input className={`form-control ${this.state.titleValidate ? 'invalid' : ''}`}  value={this.state.title}
                    onChange={(e)=> {
                        this.setState({
                            title:e.target.value
                        })
                    }}/>
                    {this.state.titleValidate && (
                        <div className="error-message">Title is required.</div>
                    )}
                </div>
              </Col>
              <Col xs={12} md={6} className="max-xs-width-control-900 d-flex  align-items-center py-2">
                <div className="max-xs-width-control-900 text-end w-50">
                  Address
                </div>
                <div className="max-xs-width-control-900 ps-2 w-50" style={{pointerEvents: (this.state.currentRole == 'Customer Template Role') ? 'none': 'auto' }}>
                  <input className={`form-control ${this.state.addressValidate ? 'invalid' : ''}`} value={this.state.address}
                  onChange={(e)=> {
                    this.setState({
                        address:e.target.value
                    })
                  }}/>
                  {this.state.addressValidate && (
                        <div className="error-message">Address is required.</div>
                    )}
                </div>
              </Col>
              <Col xs={12} md={6} className="max-xs-width-control-900 d-flex  align-items-center py-2">
                <div className="max-xs-width-control-900 text-end w-50">
                  Latitude
                </div>
                <div className="max-xs-width-control-900 ps-2 w-50" style={{pointerEvents: (this.state.currentRole == 'Customer Template Role') ? 'none': 'auto' }}>
                  <input className={`form-control`}  value={this.state.latitude}
                  type={'number'}
                  onChange={(e)=> {
                    this.setState({
                        latitude:e.target.value
                    })
                  }}/>
                </div>
              </Col>
              <Col xs={12} md={6} className="max-xs-width-control-900 d-flex  align-items-center py-2">
                <div className="max-xs-width-control-900 text-end w-50">
                  Longitude
                </div>
                <div className="max-xs-width-control-900 ps-2 w-50" style={{pointerEvents: (this.state.currentRole == 'Customer Template Role') ? 'none': 'auto' }}>
                  <input className={`form-control`} value={this.state.longitude}
                  type={'number'}
                  onChange={(e)=> {
                    this.setState({
                        longitude:e.target.value
                    })
                  }}/>
                </div>
              </Col>
              <Col xs={12} md={6} className="max-xs-width-control-900 d-flex  align-items-center py-2">
                <div className="max-xs-width-control-900 text-end w-50">
                  No. of AC Chargers
                </div>
                <div className="max-xs-width-control-900 ps-2 w-50" style={{pointerEvents: (this.state.currentRole == 'Customer Template Role') ? 'none': 'auto' }}>
                  <input className="form-control" value={this.state.ac_chargers}
                  type={'number'}
                  onChange={(e)=> {
                    this.setState({
                        ac_chargers:e.target.value
                    })
                  }}/>
                </div>
              </Col>
              <Col xs={12} md={6} className="max-xs-width-control-900 d-flex  align-items-center py-2">
                <div className="max-xs-width-control-900 text-end w-50">
                  No. of DC Chargers
                </div>
                <div className="max-xs-width-control-900 ps-2 w-50" style={{pointerEvents: (this.state.currentRole == 'Customer Template Role') ? 'none': 'auto' }}>
                  <input className="form-control"  value={this.state.dc_chargers}
                  type={'number'}
                  onChange={(e)=> {
                    this.setState({
                        dc_chargers:e.target.value
                    })
                  }}/>
                </div>
              </Col>
              <Col xs={12} md={6} className="max-xs-width-control-900 d-flex  align-items-center py-2">
                <div className="max-xs-width-control-900 text-end w-50">
                  Postal Code
                </div>
                <div className="max-xs-width-control-900 ps-2 w-50" style={{pointerEvents: (this.state.currentRole == 'Customer Template Role') ? 'none': 'auto' }}>
                  <input className={`form-control ${this.state.postal_codeValidate ? 'invalid' : ''}`}  value={this.state.postal_code}
                  type={'text'}
                  onChange={(e)=> {
                    if(/^[0-9]*$/.test(e.target.value)) {
                        this.setState({
                            postal_code:e.target.value
                        })
                    }
                  }}/>
                  {this.state.postal_codeValidate && (
                        this.state.postal_code ? <div className="error-message">postal must be at least 2 and max 6</div> : <div className="error-message">Postal Code is invalid.</div>
                    )}
                </div>
              </Col>
              <Col xs={12} md={6} className="max-xs-width-control-900 d-flex align-items-center py-2">
                <div className="max-xs-width-control-900 text-end w-50">
                  Provider
                </div>
                <div className="max-xs-width-control-900 ps-2 w-50" style={{pointerEvents: (this.state.currentRole == 'Customer Template Role') ? 'none': 'auto' }}>
                  <select className="form-control" value={this.state.provider_id}
                  onChange={(e)=> {
                    this.setState({
                        provider_id:e.target.value
                    })
                  }}>
                    <option value={''}>Select Provider</option>
                    {this.state.providers && this.state.providers.map(provider => 
                      <option value={provider.id}>{provider.title}</option>
                    )}
                  </select>
                </div>
              </Col>
              <Col xs={12} md={6} className="max-xs-width-control-900 d-flex align-items-center py-2">
                <div className="max-xs-width-control-900 text-end w-50">
                  Exclude
                </div>
                <div className="max-xs-width-control-900 ps-2 w-50" style={{pointerEvents: (this.state.currentRole == 'Customer Template Role') ? 'none': 'auto' }}>
                  <input className="form-control" value={this.state.exclude}
                  onChange={(e)=> {
                    this.setState({
                        exclude:e.target.value
                    })
                  }}/>
                </div>
              </Col>
              <Col xs={12} md={6} className="max-xs-width-control-900 d-flex align-items-center py-2">
                <div className="max-xs-width-control-900 text-end w-50">
                  Status
                </div>
                <div className="max-xs-width-control-900 ps-2 w-50" style={{pointerEvents: (this.state.currentRole == 'Customer Template Role') ? 'none': 'auto' }}>
                  <select className="form-control" value={this.state.status}
                  onChange={(e)=> {
                    this.setState({
                        status:e.target.value
                    })
                  }}>
                    <option value="Available">Available</option>
                    <option value="Not Built">Not Built</option>
                    <option value="Unavailable">Unavailable</option>
                  </select>
                </div>
              </Col>
                <button type="submit" disabled={this.state.isLoading == true ? true: false} className="upload-style text-white mt-4" style={{margin:'auto', width:'200px'}}>
                {this.state.isLoading? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Submit' }
                </button>
            </Row>
        </Container>
    }

      /* SUBMIT */
  handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let formValidate = true;
    if (this.state.address == null || this.state.address == "") {
        this.setState({
            addressValidate:true,
        });
        formValidate = false;
    }else {
        this.setState({
            addressValidate:false,
        });
    }

    if (this.state.title == null || this.state.title == "") {
        this.setState({
            titleValidate:true,
        });
        formValidate = false;
    }else {
        this.setState({
            titleValidate:false,
        });
    }

    if (this.state.postal_code != null && this.state.postal_code != "" && !apiUtil.postalCodeValidation(this.state.postal_code)) {
        this.setState({
            postal_codeValidate:true,
        });
        formValidate = false;
    }else {
        this.setState({
            postal_codeValidate:false,
        });
    }
            
    if (formValidate == true) {
        this.setState({
            isLoading: true,
        });        
        let errorMessage = "";
        let status = 0;
        const {
            REACT_APP_AUTH_API_URL
        } = process.env;
        // let dataid = this.state.id;
        const PATH = 'api/';
        const api = `charging_stations`;
 
        const data = {
            address:this.state.address,
            title:this.state.title,
            latitude:this.state.latitude,
            longitude:this.state.longitude,
            ac_chargers:this.state.ac_chargers,
            dc_chargers:this.state.dc_chargers,
            postal_code:this.state.postal_code,
            provider_id :this.state.provider_id,
            status:this.state.status,
            exclude:this.state.exclude,
        }
        const URL = REACT_APP_AUTH_API_URL + PATH + api;
        let token = window.localStorage.getItem('access_token');

        let param = {
        };
        axios.post(
            URL,
            data,
            {
                params: param,
                // data: param,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            }).then((response) => {
                apiUtil.toast('Successfully created', 'check_circle');
                this.setState({
                    isLoading: false,
                    title: '',
                    titleValidate: false,
                    address: '',
                    addressValidate: false,
                    latitude: '',
                    latitudeValidate: false,
                    longitude: '',
                    longitudeValidate: false,
                    ac_chargers: '',
                    dc_chargers: '',
                    postal_code:'',
                    postal_codeValidate: false, 
                    formValidate: true,
                    provider_id: '',
                    status:'Available',
                    exclude: '',
                    excle_file: null,
                    openImportDialog: false,
                    openImportDialogItem: null,
                })
            })
            .catch(error => {
                try {
                    status = error.response.status;
                    if (status != 200) {
                        error.response && error.response.data?.error != null && error.response.data?.error != '' ?
                        apiUtil.toast(error.response.data.error, 'check_circle') 
                        :
                        apiUtil.toast("unsuccessful attempt, please try again!", 'check_circle') 

                        this.setState({
                            openImportDialog: false,
                            openImportDialogItem: null,
                            excle_file: '',
                            data_form: [],
                            isLoading: false
                          });
                          this.fileInput.current.value = '';
                        return
                    }
                    if (error.response) {
                        // Request made and server responded
                        errorMessage = error.response.data;
                    } else if (error.request) {
                        // The request was made but no response was received
                        errorMessage = error.request;
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        errorMessage = error.message;
                    }
                } catch (err) {
                    errorMessage = 'Service Unavailable!'
                }
            });
    } else {
        return
    }
      
  };
  /* END SUBMIT */

  importDialog = () => {
    return <CustomDialog 
      open={this.state.openImportDialog}
      title={'Import Jobs'}
      maxWidth={'md'}
      padding={'0'}
      hideButtons={true}
      onClose={() => {
        this.setState({
          openImportDialog: false,
          openImportDialogItem: null,
          excle_file: '',
          data_form: []
        });
        this.fileInput.current.value = '';
      }}
    >
      <ImportDialog
        file={this.state.openImportDialogItem}
        isLoading={this.state.openImportDialogIsLoading}
        onClose={() => {
          this.setState({
            openImportDialog: false,
            openImportDialogItem: null,
            openImportDialogIsLoading: false,
            data_form: [],
            excle_file: '',
          });
          this.fileInput.current.value = '';
        }}
        onImport={(form) => {
          this.setState({
            data_form: form,
            openImportDialog: false,
            openImportDialogItem: null,
            openImportDialogIsLoading: false
          });
        }}
      />
    </CustomDialog>
  }

  render() {
    return <Container className="dashboard-page">
       <Row className="px-4 py-3">
            <Col>
            <Card className="cd-shadow">
                <Button style={{marginLeft:"0em"}} className="upload-style" onClick={()=> {this.props.history.push('/charging-location-list')}}>View List</Button>
            <button onClick={()=> saveAs(
                            ChargingTemplate,
                            "charging_station_location.xlsx"
                          )} className="download-order-template my-2">customised charging station locations template <FontAwesomeIcon icon={faDownload} /></button>
            </Card>
            <Card className="cd-shadow mt-3">
                <div className="row d-flex flex-row justify-content-center align-items-center w-100 max-xs-width-control-100 p-3 ms-1">
                    <div className="col-12 col-xs-12 col-md-12 col-lg-12">Upload CSV or Excel file:</div>
                    <div className="col-12 col-xs-12 col-md-12 col-lg-12"> 
                    <input type="file" 
                    accept=".xls, .xlsx, .csv"
                    ref={this.fileInput}
                    className="form-control flip-form"
                    onChange={(e) => {
                      let files = e.target.files
                      if (files && files.length > 0){
                        this.setState({
                          openImportDialog: true,
                          openImportDialogItem: files[0],
                          excle_file: e.target.value
                        });
                      } else {
                        this.setState({
                          openImportDialogItem: null,
                          excle_file: ''
                        });
                      }
                    }}
                    />
                    </div>
                </div>
                <div className="d-flex justify-content-center align-items-center p-3">
                    <Button className="upload-style me-3"
                    isLoading={this.state.isLoading}
                    disabled = {this.state.excle_file == '' || this.state.excle_file == null || this.state.isLoading ? true : false}
                    onClick={()=>{
                       this.state.data_form.length > 0 ?
                          this.callImportApi(this.state.data_form, (data) => {
                            apiUtil.toast('jobs successfully imported', 'check_circle', 'success');
                
                            this.setState({
                              openImportDialog: false,
                              openImportDialogItem: null,
                              openImportDialogIsLoading: false,
                              data_form: [],
                              excle_file: '',
                            });
                          }) :
                          apiUtil.toast("Please select file first", "check_circle") 
                    }}
                    >{this.state.isLoading? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : <>&nbsp; Upload Locations</>}</Button>
                     <Button className="upload-style"
                     disabled = {!(this.state.excle_file == '' || this.state.excle_file == null) || this.state.isLoading ? true : false}
                    onClick={()=>{
                      this.props.history.push("/manual-location")
                    }}
                    >Manual Upload Locations</Button>
                </div>
            </Card>
            <form noValidate autoComplete="off" onSubmit={this.handleSubmit}>
                <Card className="cd-shadow mt-3">
                    {this.jobDetailsForm()}
                </Card>
            </form>
            </Col>
        </Row>
        {this.importDialog()}
    </Container>;
  }
}

export default ChargingLocation;
