import React, { useRef, useState, useContext, useEffect } from 'react';

import { useHistory, Link } from "react-router-dom";

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';

import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import authApi from '../../api/Authentication.jsx';
import apiUtil from '../../api/apiUtil.jsx';

import logo from '../../assets/img/wn_logo.svg';
import {RoleAccessService} from "./../../data/role-access"


export default function SignIn() {
  let history = useHistory();

  const { clearStorage, token, refreshToken, authenticated, setAuthenticated, setToken, setCurrentRole, setAccessToken, setRefreshToken, setTokenType, setUser, setAppPermission, setUniqueRolePermissions } = useContext(AuthContext);
  
  const [validatedEmail, setValidatedEmail] = useState(false);
  const [errorEmail, setErrorEmail] = useState(null);
  const [validatedResetEmail, setValidatedResetEmail] = useState(false);
  const [errorResetEmail, setErrorResetEmail] = useState(null);
  const [validatedPass, setValidatedPass] = useState(false);
  const [errorPass, setErrorPass] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showForgotPw, setShowForgotPw] = useState(false);
  const [sentEamil, setSentEmail] = useState(null);

  const isMounted = useRef(null);
  const emailInput = useRef();
  const resetEmailInput = useRef();
  
  const pswInput = useRef();
  const axiosRef = useRef();


  useEffect(() => {
    //check if authenticated
    // checkToken();
    if(authenticated){
        history.push("/");
    }

    // executed when component mounted
    axiosRef.current = apiUtil.getCancelToken();
    isMounted.current = true;

    return () => {
      // executed when unmount
      isMounted.current = false;
      axiosRef.current.cancel();
    }
  }, []);

  const checkToken = () => {
    let tokenObj = apiUtil.getObject(token);
    if(tokenObj && refreshToken){
      var created = new Date(tokenObj.created_at);
      var expired = new Date(created);
      expired.setSeconds(expired.getSeconds() + tokenObj.expires_in);

      if (expired < new Date()) {
        setAuthenticated(false);
        callRefreshTokenApi(refreshToken);
      }
    } else {
      clearStorage();
      return;
    }
  }

  const callRefreshTokenApi = (refreshToken) => {
    authApi.refreshToken(refreshToken)
      .then((result) => {
        apiUtil.parseResult(result, (data) => {
          data.created_at = data.created_at * 1000;
          
          setToken(JSON.stringify(data));
          setAccessToken(data.access_token);
          setRefreshToken(data.refresh_token);
          setTokenType(data.token_type);
          setAuthenticated(true);
        }, (error, type) => {
          clearStorage();
          return;
        });
    });
  }

  const handleSubmit = async e => {
    e.preventDefault();
    e.stopPropagation();
    
    const form = e.currentTarget;

    setError(null);

    setValidatedEmail(false);
    setErrorEmail(null);
    setValidatedPass(false);
    setErrorPass(null);
    
    if(emailInput.current.lastChild.firstChild.value === ''){
      setValidatedEmail(true);
      setErrorEmail('Please enter your email address.');
    }

    if(pswInput.current.lastChild.firstChild.value === ''){
      setValidatedPass(true);
      setErrorPass('Please enter your password.');
    }

    if (form.checkValidity() === true) {
      setIsLoading(true);
   //   authApi.clearStorage();
 
      authApi.login(emailInput.current.lastChild.firstChild.value, pswInput.current.lastChild.firstChild.value, axiosRef.current.token)
        .then((result) => {
          console.log("result ", result);
        
          apiUtil.parseResult(result, (data) => {
            let appAccessPermissions = [];
           
            var roles_ =data.owner_info.roles;
            var login_user_role =roles_[0].role_name;
            if(login_user_role == "SuperAdministrator"){
              if (data.owner_info.application_registrations.length > 0) {
                data.owner_info.application_registrations.forEach((el) => {
                  if (el.name === "AUTH") {
                    appAccessPermissions.push("auth");
                  } else if (el.name === "WLS") {
                    appAccessPermissions.push("wls");
                  } else if (el.name === "WMS") {
                    appAccessPermissions.push("wms");
                  }
                });

                window.localStorage.setItem("appPermission", appAccessPermissions);
              }


              //Grouping the permissions of associated Users
              let uniqueRolePermissions = []
              if (data.owner_info.roles[0].permissions.length > 0) {
                // uniqueRolePermissions = [...new Set(data.owner_info.roles[0].permissions.map(item => item.group))]; // [ 'A', 'B']
                uniqueRolePermissions = data.owner_info.roles[0].permissions; // [ 'A', 'B']
                
                window.localStorage.setItem("uniqueRolePermissions", JSON.stringify(uniqueRolePermissions));
              }

              setAuthenticated(true);
              setCurrentRole(data.owner_info.roles[0] !== undefined ? data.owner_info.roles[0].role_name : null);
              setAccessToken(data.token.access_token);
              setRefreshToken(data.token.refresh_token);
              setTokenType(data.token.token_type);
              setToken(JSON.stringify(data.token));
              setUser(JSON.stringify(data));
              setAppPermission(appAccessPermissions);
              setUniqueRolePermissions(uniqueRolePermissions);

              let role = localStorage.getItem('current_role');
              let grantedMenu = new RoleAccessService;

              if (role === 'Customer Template Role' || !grantedMenu.isShouldShowMenu(role,"dashboard")) {
                history.push("/job-list");
              } else {
                history.push("/");
              }
            }else{
              setError({ title: "Unauthicated user" });
              setIsLoading(false);
            }
          }, (error, type) => {
            setError({ title: error });
            setIsLoading(false);
          });
      });
    }
  };

  const handleResetSubmit = async e => {
    e.preventDefault();
    e.stopPropagation();
    
    const form = e.currentTarget;

    setError(null);

    setValidatedResetEmail(false);
    setErrorResetEmail(null);
    setSentEmail(null)
    if(resetEmailInput.current.lastChild.firstChild.value === ''){
      setValidatedResetEmail(true);
      setErrorResetEmail('Please enter your email address.');
    } else {
      let email = resetEmailInput.current.lastChild.firstChild.value
      let param ={
        email
      }
      setIsLoading(true);
      authApi.validEmail('validate/email', param)
      .then((result) => {
      apiUtil.parseResult(result, (data) => {
        callForgotPasswordApi(email)
        setIsLoading(false);
      }, (error, type) => {
        console.log("error",error)
        setErrorResetEmail(error);
        setIsLoading(false);
      });
      });
      
      
    }

  }
  const callForgotPasswordApi = email => {
    
    let param = {
      proper_mail: email,
      email
    }
    setIsLoading(true);
      authApi.validEmail('forgot/password', param)
      .then((result) => {
      apiUtil.parseResult(result, (data) => {
        setSentEmail('Please check your email for a message with your code')
        setIsLoading(false);
      }, (error, type) => {
        
        setIsLoading(false);
      });
      });

  }

  return (
    <div className="signin container">
    <div className="login" component="main">
      {
        error && !isLoading
        &&
        <Box textAlign="left" mt={0} mb={3}>
          <Alert severity="error">
            "Incorrect username or password!"
            {/* {error.title && <b>{error.title}</b>}
            {(error.errors && error.errors.length > 0) && error.errors.map((e, i) => {
              return <div key={i}>{e}</div>
            })} */}
          </Alert>
        </Box>
      }
      {
        errorResetEmail && !isLoading
        &&
        <Box textAlign="left" mt={0} mb={3}>
          <Alert severity="error">
            {errorResetEmail}
          </Alert>
        </Box>
      }
      {
        sentEamil && !isLoading
        &&
        <Box textAlign="left" mt={0} mb={3}>
          <Alert severity="success">
            {sentEamil}
          </Alert>
        </Box>
      }
      <div className="form-wrapper">
   <form noValidate onSubmit={handleSubmit}>
        <Box textAlign="center" mb={3}>
          <div className="form-title">
          Admin Login
          </div>
          <div className="form-description">
          Login into your dash express dashboard with your credentials
          </div>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            error={validatedEmail} 
            helperText={errorEmail}
            ref={emailInput}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            error={validatedPass} 
            helperText={errorPass}
            ref={pswInput}
          />
        </Box>
        <Box mb={1}>
          <Grid container>
            <Grid item xs>
              {/*<Link href="#" variant="body2">
                Forgot password?
              </Link>*/}
            </Grid>
            <Grid item>
            </Grid>
          </Grid>
        </Box>
        <Button
          type="submit"
          fullWidth
          variant="contained" 
          color="primary"
          disabled={isLoading}
        >
          <Box pl={2} pr={2} style={{ color: 'white' }}>SIGN IN</Box>
          {isLoading && <CircularProgress size={15} style={{ color: 'white' }} />}
        </Button>
        {/* <Box mt={2}><Button  onClick={() => setShowForgotPw(true)}>Forgot your Password?</Button></Box>  */}
        
      </form>
      
      
      {/* <Box mt={6}>
        <Typography variant="body2" color="textSecondary" align="center">
          {'Copyright © '}
          <Link color="inherit" href="/">TaskkMe</Link>
          {' '}
          {new Date().getFullYear()}
          {'.'}
        </Typography>
      </Box> */}
      </div>
    </div>
    </div>
  );
}