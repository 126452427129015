import React, { Component } from 'react';
import BreadCrumb from "../../components/Navs/Breadcrumb"
import { 
  Box,
  Grid,
} from '@material-ui/core';
import StickyPageHeader from '../../components/PageHeader/StickyPageHeader.jsx';
import Card from '../../components/Card/Card.jsx';
import { NavLink } from 'react-router-dom';
import Table from '../../components/Table/Table.jsx';
import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import CustomButton from '../../components/CustomFields/CustomButton.jsx';
import axios from 'axios';
import HtmlParser from 'react-html-parser';

class Dashboard extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);

    this.refTable = React.createRef();
    this.refTableSearch = React.createRef();

    let hiddenColumns = [];
    let columnHiddenSettingsId = 0;
    let columnHiddenSettings = JSON.parse(localStorage.getItem('job_column_settings'));

    if (columnHiddenSettings !== null) {
      hiddenColumns = columnHiddenSettings.json_data.replace(/[^0-9a-zA-Z-_.,]/g, "").split(",");
      columnHiddenSettingsId = columnHiddenSettings.id ? columnHiddenSettings.id : 0;
    }
    

    this.state = {
      data :  [],
      page : 1,
      total : 2,
      limit : 10,
      errorStatus:'',
      isLoadingStatus: false,
      isLoading: false,
      pageSize : 22,      // status: [],
      hiddenColumnSettingId: columnHiddenSettingsId,
      hiddenColumns: hiddenColumns,
    }
  }
  componentDidMount() {
    this.callReadApi();
  }

  callReadApi(){
    let errorMessage = "";
    let status = 0;
    const { 
      REACT_APP_AUTH_API_URL
    } = process.env;
    const PATH = 'admin/api/';
    const api = "free_accounts";
    const URL = REACT_APP_AUTH_API_URL + PATH + api;
    let token = window.localStorage.getItem('access_token');
   
    let param = {
      page: this.state.page,
      take: this.state.limit,
      search: "",
      sorting_array: ['created_at DESC'],
    };
    this.setState({
      isLoading:true
    }, () => {
      axios.get(
            URL,
            { 
                params: param,
                data: param,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .catch( error => {
                try {
                    status = error.response.status;
                    this.setState({
                      errorStatus : status
                    })
                    if(this.state.errorStatus == 401) {
                      const { clearStorage } = this.context;
                      this.setState({
                        profileMenu: null,
                        isDrawerOpen: false
                      });
                      clearStorage();
                      window.location = "/";
                    } else {
                      if (error.response) {
                        // Request made and server responded
                        errorMessage = error.response.data;
                      } else if (error.request) {
                        // The request was made but no response was received
                        errorMessage = error.request;
                      } else {
                        // Something happened in setting up the request that triggered an Error
                        errorMessage = error.message;
                      }
                    }
                } catch(err) {
                    errorMessage = 'Service Unavailable!'
                }
                this.setState({
                  isLoading:false
                })
            }).then((response) => {
              // console.log(response);
              if(response != null){
                  console.log("d",response.data.total)
              this.setState({
                data: response.data.result,
                total: response.data.total,
                isLoading: false,
              });
              }
            });
    })
    
  }

  customStickyPageHeader = () => {
    return <Box clone pl={4} pr={4} height={'100%'} alignItems={'center'}>
      <Grid container>
        <BreadCrumb />
      </Grid>
    </Box>
  }

  leftPad = (number, targetLength) => {
    var output = number + '';
    while (output.length < targetLength) {
        output = '0' + output;
    }
    return output;
}
  customTable = () => {

    return <Table 
    ref={this.refTable}
    className={'job-table-list' }  
    isLoading={this.state.isLoading}
    grouping={this.state.grouping}
    limit={20}
    pageSize= {20}
    // pageSizeOptions ={this.state.pageSizeOptions}
    page={1}
    total={20}
    data={this.state.data}
    // defaultExpanded={true}
    // selection={true}
    onPageChange={(page,size) => {
      
      this.state.limit = size;
      this.setState({
        page: page,
      }, () => { 
        this.callReadApi();
        // this.callReadApi(this.refTableSearch.current.state.value);
      });
    }}
    // Toolbar={this.customToolbar}
    // Row={this.customRow}
    columns={[
        { 
          title: "Action",
          field: "action",
          cellStyle: {
            minWidth: 240,
            maxWidth: 240            
          },
          render: (row, type) => {
            if(type === 'row'){
              return  <NavLink   to={{
                pathname: "/free-account-detail",
                state : {id:row.id} // your data array of objects
              }}   
               style={{ textDecoration: 'none' }}>View</NavLink>;
            } else {
              return row;
            }
          }
        },
        { 
          title: "ID", 
          field: "id",
          cellStyle: {
            minWidth: 150,
            maxWidth: 150            
          },
          render: (row, type) => {
            if(type === 'row'){
              return (row.id   != ' ') ? HtmlParser(this.leftPad(row.id, 3)) : '';
            } else {
              return row;
            }
          }
        },
        { 
          title: "Billing Start", 
          field: "billing-start",
          cellStyle: {
            minWidth: 150,
            maxWidth: 150            
          },

          render: (row, type) => {
            if(type === 'row'){
              return (row.start_date   != ' ') ? HtmlParser(row.start_date) : '';
            } else {
              return row;
            }
          }
        },
        { 
          title: "Billing End", 
          field: "billing-end",
          cellStyle: {
            minWidth: 180,
            maxWidth: 180
          },
          render: (row, type) => {
            if(type === 'row'){
              return (row.end_date   != ' ') ? HtmlParser(row.end_date) : '';
            } else {
              return row;
            }
          }
        },
        { 
          title: "Enable", 
          field: "enable",
          cellStyle: {
            minWidth: 150,
            maxWidth: 150            
          },
          render: (row, type) => {
            if(type === 'row'){
              return (row.is_enabled == true) ? <span style={{color:'#65f442'}}>true</span> : <span style={{color:'#ef6428'}}>false</span>;
            } else {
              return row;
            }
          }
        },
      ]}
    />
  }

  render() {
    const {stats, delivery_perday, total_delivery} = this.state
    return <Box className="dashboard-page">
      <StickyPageHeader>
        {this.customStickyPageHeader()}
      </StickyPageHeader>
        <Box pr={1} style={{padding: '20px', paddingLeft:'30px' }}>
          <Grid item xs={'auto'}>          
          <NavLink   
          to={{
                pathname: "/create-free-account",
                state : {id:''} // your data array of objects
              }}   
              style={{ textDecoration: 'none' }}>
            <CustomButton color={'primary'} >
                  <Box display={'inline-block'} pl={1}>Create New</Box>
            </CustomButton>
            </NavLink>
          </Grid>
        </Box>
      <Card className={'job-listing-step'}>
        {this.customTable()}
      </Card>
    </Box>;
  }
}

export default Dashboard;
