import React, { Component } from 'react';
import BreadCrumb from "../../components/Navs/Breadcrumb"
import { 
  Box,
  Grid,
} from '@material-ui/core';
import StickyPageHeader from '../../components/PageHeader/StickyPageHeader.jsx';
import Card from '../../components/Card/Card.jsx';
import { NavLink } from 'react-router-dom';
import Table from '../../components/Table/Table.jsx';
import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import CustomButton from '../../components/CustomFields/CustomButton.jsx';
import { Container, Row, Col, Button } from 'react-bootstrap';
import axios from 'axios';
import HtmlParser from 'react-html-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faEdit, faEye } from '@fortawesome/free-solid-svg-icons';
import CustomDialog from '../../components/Dialog/CustomDialog.jsx';
import apiUtil from "../../api/apiUtil.jsx";
import CustomSwitch from "../../components/CustomFields/CustomSwitch"
import Select from 'react-select';
class routeOptimization extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);

    this.refTable = React.createRef();
    this.refTableSearch = React.createRef();
    

    this.state = {
      oldData: [],
      data :  [],
      value: [],
      total: 0,
      errorStatus: null,
      is_disable_NB: false,
      is_disable_google: false,
      allow_company_ids: []
    }
  }
  componentDidMount() {
    this.callReadSettings();
    this.callReadApi();

  }
  
  callReadSettings(callback=null) {
      let status = 0;
      const { 
      REACT_APP_AUTH_API_URL
      } = process.env;
      const PATH = 'admin/api/';
      const api = "route-optimization-settings";
      const URL = REACT_APP_AUTH_API_URL + PATH + api;
      let token = window.localStorage.getItem('access_token');  

      this.setState({
        isLoading:true
      }, () => {
      axios.get(
              URL,
              { 
                  headers: {
                      'Content-Type': 'application/json',
                      'Authorization': 'Bearer ' + token
                  }
              })
              .catch( error => {
                  try {
                      status = error.response.status;
                      this.setState({
                        errorStatus : status
                      })
                      if(this.state.errorStatus == 401) {
                        const { clearStorage } = this.context;
                        clearStorage();
                        window.location = "/";
                      }
                  } catch(err) {
                  }
                  this.setState({
                    isLoading:false
                  },() => {
                    if(callback) {
                      callback();
                    }                        
                  })
              }).then((response) => {
              // console.log(response);
              if(response != null){
                response.data.result.map((obj) => {
                  if(obj.name == 'Google OR') {
                    this.setState({
                      is_disable_google: obj.active
                    });                        
                  } else {
                    this.setState({
                      is_disable_NB: obj.active,
                      allow_company_ids: obj.company_ids ? obj.company_ids.split(',') : []
                    });
                  }
                });
                
                if(callback) {
                  callback();   
                }
              }
              });
      })      
  }

  callReadApi(){
      let errorMessage = "";
      let status = 0;
      const { 
      REACT_APP_AUTH_API_URL
      } = process.env;
      const PATH = 'admin/api/';
      const api = "application_companies";
      const URL = REACT_APP_AUTH_API_URL + PATH + api;
      let token = window.localStorage.getItem('access_token');
  
      let param = {
      search: "",
      active: true,
      selected: true,
      sorting_array: ['created_at DESC'],
      };
      this.setState({
        isLoading:true
      }, () => {
      axios.get(
              URL,
              { 
                  params: param,
                  data: param,
                  headers: {
                      'Content-Type': 'application/json',
                      'Authorization': 'Bearer ' + token
                  }
              })
              .catch( error => {
                  try {
                      status = error.response.status;
                      this.setState({
                        errorStatus : status
                      })
                      if(this.state.errorStatus == 401) {
                        const { clearStorage } = this.context;
                        clearStorage();
                        window.location = "/";
                      } else {
                        if (error.response) {
                            // Request made and server responded
                            errorMessage = error.response.data;
                        } else if (error.request) {
                            // The request was made but no response was received
                            errorMessage = error.request;
                        } else {
                            // Something happened in setting up the request that triggered an Error
                            errorMessage = error.message;
                        }
                      }
                  } catch(err) {
                      errorMessage = 'Service Unavailable!'
                  }
                  this.setState({
                    isLoading:false
                  })
              }).then((response) => {
              // console.log(response);
              if(response != null){
                this.setState({
                    oldData: response.data.result,
                    data: response.data.result.filter((obj) => !this.state.allow_company_ids.includes(obj.value.toString())),
                    value: response.data.result.filter((obj) => this.state.allow_company_ids.includes(obj.value.toString())),
                    total: response.data.total,
                    isLoading: false,
                });
              }
              });
      })
      
  }

  callUpdateApi(type) {
      this.setState({
          isLoading: true
      })
      let errorMessage = "";
      let status = 0;
      const {
          REACT_APP_AUTH_API_URL
      } = process.env;
      // let dataid = this.state.id;
      const PATH = 'admin/api/';
      const api = 'route-optimization';
      const data = {
        is_disable_google: this.state.is_disable_google,
        is_disable_NB: this.state.is_disable_NB,
        allow_company_ids: this.state.value.length > 0 ? this.state.value.map(obj => obj.value).join() : null,
        type: type
      };
      
      const URL = REACT_APP_AUTH_API_URL + PATH + api;
      let token = window.localStorage.getItem('access_token');

      axios.put(
          URL,
          data,
          {
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + token
              }
          }).then((response) => {
              apiUtil.toast('Successfully updated', 'check_circle');
              this.setState({
                  isLoading: false,
              })
          })
          .catch(error => {
              try {
                  status = error.response.status;
                  if (status != 200) {
                      error.response && error.response.data?.error != null && error.response.data?.error != '' ?
                      apiUtil.toast(error.response.data.error, 'check_circle') 
                      :
                      apiUtil.toast("unsuccessful attempt, please try again!", 'check_circle') 

                      this.setState({
                          isLoading: false,
                        });
                      return
                  }
                  if (error.response) {
                      // Request made and server responded
                      errorMessage = error.response.data;
                  } else if (error.request) {
                      // The request was made but no response was received
                      errorMessage = error.request;
                  } else {
                      // Something happened in setting up the request that triggered an Error
                      errorMessage = error.message;
                  }
              } catch (err) {
                  errorMessage = 'Service Unavailable!'
              }
          });
  }

  render() {
    const {data} = this.state
    return <Box className="dashboard-page">      
      <Card className={'job-listing-step'}>
        <h3 className="mb-4">Route Optimization Setting</h3>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3} >
            <Box className="p-4" style={{border: '1px solid #eee'}}>
              <Box style={{textAlign: 'center',marginTop: 50}}>
                <img src="https://nextbillion.ai/wp-content/uploads/2022/10/nb-logo-colored-80x73.png" style={{borderRadius: '50%',width: 100,height: 100,marginBottom: 20}} />
                <h4>Next Billion</h4>
              </Box>
              <Box>
                <label>Status</label>
                <CustomSwitch 
                label={'Enable Tiwilo'} 
                checked={this.state.is_disable_NB}
                value={this.state.is_disable_NB}
                onChange={ (e) => {     
                  this.setState({
                    is_disable_NB: e
                  },() => {
                    this.callUpdateApi('NB');
                  });                      
                }
                }/>                
              </Box>
              <Box>
                <label className="mb-3">Allowed Company</label>
                <Select
                  isMulti
                  options={data}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  value={this.state.value}
                  onChange={(e,opt) => {
                    let company = []
                    if(opt.action == 'select-option') {
                      company = this.state.data.filter((obj) => !e.filter((sub) => sub.value == obj.value).length > 0);
                      this.setState({
                        value: e,
                        data: company
                      }, () => {
                        this.callUpdateApi('NB');   
                      });                          
                    } else if(opt.action == 'remove-value') {
                      company = this.state.data
                      company.push(opt.removedValue);
                      this.setState({
                        value: e,
                        data: company                     
                      }, () => {
                         this.callUpdateApi('NB');     
                      });
                    } else if(opt.action == 'clear') {
                      this.setState({
                        value: [],
                        data: this.state.oldData
                      },() => {
                        this.callUpdateApi('NB');      
                      });                          
                    }
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box className="p-4" style={{border: '1px solid #eee',height: '100%'}}>
              <Box style={{textAlign: 'center',marginTop: 50}}>
                <img src="https://developers.google.com/static/optimization/images/orLogo_72.png" style={{borderRadius: '50%',width: 100,height: 100,marginBottom: 20}} />
                <h4>Google OR</h4>
              </Box>
              <Box>
                <label>Status</label>
                <CustomSwitch 
                label={'Enable Tiwilo'} 
                checked={this.state.is_disable_google}
                value={this.state.is_disable_google}
                onChange={ (e) => {   
                  this.setState({
                    is_disable_google: e
                  },() => {
                    this.callUpdateApi('google');
                  });                 
                }
                }/>                  
              </Box>          
            </Box>
          </Grid>
        </Grid>
      </Card>
    </Box>;
  }
}

export default routeOptimization;
